export type EncodedFiles = {
  filename: string;
  content: string;
  content_type: string;
};

export enum CampaignObjectives {
  // If you update the values or add new ones, don't forget to update the review.json translation file too.
  BRANDING = "BRANDING",
  TRAFFIC = "TRAFFIC",
  LEAD = "LEAD",
}

export enum CreativeEnabled {
  YES = "yes",
  NO = "no",
}

export enum WordingsEnabled {
  YES = "yes",
  NO = "no",
}

export enum ResumeRequired {
  YES = "yes",
  NO = "no",
}

export enum HasAutomation {
  YES = "yes",
  NO = "no",
}

export type SourcingCampaignCreationAutomations = {
  new: boolean;
  "did not answer": boolean;
  refused: boolean;
};

export type SourcingCampaignCreation = {
  requester_email: string;
  requester_given_name: string;
  requester_last_name: string;
  target_organization: string;
  campaign_name: string;
  campaign_objective: CampaignObjectives;
  campaign_goal?: number;
  start_at: string;
  campaign_duration: string;
  launch_asap: boolean;
  budget: string;
  additional_information?: string;
  stakeholders: string[];
  content_to_promote: string;
  creatives: string[];
  wordings: string;
  geolocation: string;
  expected_qualification: string;
  resume_required: boolean;
  has_automation: boolean;
  automations: SourcingCampaignCreationAutomations;
  has_custom_automation: boolean;
};
