import { useRouter } from "next/router";

import { StatusChip } from "components/common";
import { useTranslation } from "next-i18next";

import { ChevronRight as ChevronRightIcon } from "@mui/icons-material";
import { Box, Button, Typography } from "@mui/material";

import { loadTranslations } from "@lib";

interface Props {
  status: string;
  statusApplicationsCount: number;
}
export const MyApplicationsCard = ({ status, statusApplicationsCount }: Props) => {
  const { t } = useTranslation(["common"]);
  loadTranslations("common");

  const router = useRouter();

  return (
    <Box
      flexBasis="25%"
      display="flex"
      flexDirection="column"
      sx={(theme) => ({
        border: `1px solid ${theme.palette.color.pastelPurple}`,
        borderRadius: theme.radius[2],
        padding: theme.spacings[16],
      })}
    >
      <Box display="flex" flexDirection="row" alignItems="center" gap={(theme) => theme.spacings[8]}>
        <Typography variant="heading2XL">{statusApplicationsCount}</Typography>
        <StatusChip status={status} />
      </Box>

      <Box>
        <Button
          variant="text"
          color="deepPurple"
          onClick={() => router.push(`/my-applications`)}
          disabled={statusApplicationsCount === 0}
          sx={{
            display: "flex",
            flexDirection: "row",
          }}
        >
          <Typography variant="buttonText">{t("view")}</Typography>
          <ChevronRightIcon sx={(theme) => ({ color: theme.palette.color.deepPurple })} />
        </Button>
      </Box>
    </Box>
  );
};
