import { Component, ReactNode } from "react";

import { AxiosError } from "axios";
import { Trans, WithTranslation, withTranslation } from "next-i18next";

import { Container } from "@mui/material";

import { loadTranslations } from "@lib";

interface Props extends WithTranslation {
  children?: ReactNode;
}

interface State {
  hasError: boolean;
  cause?: string;
}

class ErrorBoundary extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { hasError: false };
    loadTranslations("error_page");
  }

  static getDerivedStateFromError(error: Error | AxiosError) {
    // Update state so the next render will show the fallback UI.
    // check if the error is caused by a failed axios request
    if ("isAxiosError" in error) {
      return { hasError: true, cause: error.response?.status };
    }

    return { hasError: true, cause: error.cause };
  }

  render() {
    const { t } = this.props;
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <Container sx={{ textAlign: "center" }}>
          <div>
            <h1 data-testid="code">
              <Trans t={t} i18nKey="error_code_description" values={{ error_code: this.state.cause || "" }} />
            </h1>
            <h2 data-testid="title">
              <Trans t={t} i18nKey="error_code_title" />
            </h2>
          </div>
          <div>
            <p data-testid="message">
              <Trans
                t={t}
                i18nKey="error_code_message"
                // eslint-disable-next-line react/jsx-key
                components={[<strong></strong>]}
              />
            </p>
            <p data-testid="refresh">
              <Trans
                t={t}
                i18nKey="error_code_refresh_link_1"
                components={[
                  // eslint-disable-next-line react/jsx-key
                  <a
                    style={{
                      textDecoration: "underline",
                      color: "blue",
                    }}
                    href=""
                    onClick={() => location.reload()}
                  ></a>,
                ]}
              />
            </p>
            <p data-testid="signature">
              <Trans
                t={t}
                i18nKey="error_code_signature"
                // eslint-disable-next-line react/jsx-key
                components={[<i></i>]}
              />
            </p>

            <Trans
              t={t}
              i18nKey="error_code_refresh_link_2"
              components={[
                // eslint-disable-next-line react/jsx-key, @next/next/no-html-link-for-pages
                <a
                  style={{
                    textDecoration: "underline",
                    color: "blue",
                  }}
                  href="/"
                ></a>,
              ]}
            />
          </div>
        </Container>
      );
    }
    return this.props.children;
  }
}

export default withTranslation("error_page")(ErrorBoundary);
