import { ChangeEvent, FC, ReactNode, useCallback, useEffect, useMemo, useState } from "react";

import { useTranslation } from "next-i18next";

import {
  CheckBox as CheckBoxIcon,
  CheckBoxOutlineBlank as CheckBoxOutlineBlankIcon,
  Delete as DeleteIcon,
  ExpandMore as ExpandMoreIcon,
  FilterList as FilterListIcon,
} from "@mui/icons-material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  FormControlLabel,
  Grid,
  Checkbox as MuiCheckbox,
  TextField,
  ThemeProvider,
  Typography,
  useTheme,
} from "@mui/material";

import { InfoBox, Modal } from "@work4Labs/design-system";

import { loadTranslations } from "@lib";

import { MultiSelectFilterValue } from "./filters";

interface RenderCategoryProps extends Omit<AdvancedFilterCategory, "onApply"> {
  pendingValues: AdvancedFilterValue[];
  setPendingValues: (selected: AdvancedFilterValue[]) => void;
  canSelectMore: boolean;
}

const RenderCategory: FC<RenderCategoryProps> = ({
  columns = 1,
  defaultExpanded,
  filterFn,
  title,
  values,
  pendingValues,
  setPendingValues,
  canSelectMore,
  id,
}) => {
  const { t } = useTranslation(["table"]);
  loadTranslations("table");

  const [filter, setFilter] = useState("");

  const filteredValues = useMemo(
    () => (filterFn ? values.filter((value) => filterFn(value, filter)) : values),
    [values, filter, filterFn],
  );

  return (
    <Accordion defaultExpanded={defaultExpanded}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls={id && `${id}-content`} id={id && `${id}-header`}>
        <Typography variant="bodyStrong" color={(theme) => theme.palette.color.BASE[800]}>
          {title}
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        {filterFn ? (
          <TextField
            size="small"
            autoFocus
            placeholder={t("advancedFilters.category.search")}
            sx={(theme) => ({
              width: "100%",
              backgroundColor: theme.palette.color.BASE[100],
            })}
            value={filter}
            onChange={(e) => setFilter(e.target.value)}
          />
        ) : null}
        <Grid container spacing={2}>
          {filteredValues.map((value) => {
            const checked = pendingValues.some((selectedValue) => selectedValue.value === value.value);
            const control = (
              <MuiCheckbox
                icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                checkedIcon={<CheckBoxIcon fontSize="small" />}
                style={{ marginRight: 8 }}
                checked={checked}
                onChange={(event: ChangeEvent<HTMLInputElement>) =>
                  setPendingValues(
                    event.target.checked
                      ? [...pendingValues, value]
                      : pendingValues.filter((pendingValue) => pendingValue.value !== value.value),
                  )
                }
              />
            );

            return (
              <Grid item xs={12 / columns} key={value.value}>
                <FormControlLabel
                  // Can't select new elements, but still able to unselect previously selected elements.
                  disabled={!canSelectMore && !checked}
                  control={control}
                  label={value.displayValue ?? value.value}
                  componentsProps={{
                    typography: {
                      variant: "body",
                    },
                  }}
                />
              </Grid>
            );
          })}
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
};

export interface AdvancedFilterValue {
  /**
   * The value of the current selector. This value is used for later filtering of the data.
   */
  value: string;
  /**
   * Alternative value to display in the UI. Defaults to the value.
   */
  displayValue?: ReactNode;
}

export interface AdvancedFilterCategory {
  columns?: 1 | 2;
  defaultExpanded?: boolean;
  filterFn?: (value: AdvancedFilterValue, filter: string) => boolean;
  /**
   * The title shown in the input field.
   */
  title: string;
  /**
   * The full list of allowed values.
   */
  values: MultiSelectFilterValue[];
  /**
   * A list of values that are currently applied.
   */
  selected: MultiSelectFilterValue[];
  /**
   *
   * A list of values that are applied initially. This is what the modal is reverted to when the user clicks on the
   * reset filters button.
   */
  initialSelected?: MultiSelectFilterValue[];
  /**
   * Update selected value when new ones are applied.
   */
  onApply: (selected: AdvancedFilterValue[]) => void;
  id?: string;
}

export interface AdvancedFiltersProps {
  categories: AdvancedFilterCategory[];
  maxFilters?: number;
}

const ModalButtonStyle = {
  fontSize: "1rem",
  fontWeight: "600",
  textTransform: "none",
  borderRadius: "8px",
};

export const AdvancedFilters: FC<AdvancedFiltersProps> = ({ categories }) => {
  const { t } = useTranslation(["table"]);
  loadTranslations("table");

  const theme = useTheme();

  // Values that are selected but not yet applied. If the advanced filters modal is closed without validation, those
  // values are discarded.
  // Selected values are sorted per category. Each category is displayed in its own block within the advanced filters
  // modal.
  const [pendingValuesPerCategory, setPendingValuesPerCategory] = useState<{ [key: string]: AdvancedFilterValue[] }>(
    categories.reduce((acc, category) => {
      acc[category.title] = category.selected;
      return acc;
    }, {}),
  );

  // Update pending values when parent state changes.
  useEffect(() => {
    setPendingValuesPerCategory(
      categories.reduce((acc, category) => {
        acc[category.title] = category.selected;
        return acc;
      }, {}),
    );
  }, [categories]);

  // Update the pending values of a specified category.
  const setCategoryPendingValues = useCallback((selected: AdvancedFilterValue[], title: string) => {
    setPendingValuesPerCategory((prev) => {
      const newPendingValues = { ...prev };
      newPendingValues[title] = selected;
      return newPendingValues;
    });
  }, []);

  // Toggle the advanced filters modal opened.
  const [open, setOpen] = useState(false);

  // What happens when the user clicks on the advanced filters button.
  const handleClick = useCallback(() => {
    setOpen((prev) => !prev);
  }, []);

  // What happens when the user clicks on the apply filters button.
  const onConfirm = useCallback(() => {
    // Toggle the selected values of each category.
    categories.forEach((category) => {
      category.onApply(pendingValuesPerCategory[category.title]);
    });
    // Close the advanced filters modal.
    setOpen(false);
  }, [categories, pendingValuesPerCategory]);

  // What happens when the user clicks on the cancel button.
  const onCancel = useCallback(() => {
    // Reset the selected values of each category to their initial values.
    setPendingValuesPerCategory(
      categories.reduce((acc, category) => ({ ...acc, [category.title]: category.selected }), {}),
    );
    // Close the advanced filters modal.
    setOpen(false);
  }, [categories]);

  const onReset = useCallback(() => {
    setPendingValuesPerCategory(
      categories.reduce((acc, category) => ({ ...acc, [category.title]: category.initialSelected ?? [] }), {}),
    );
  }, [categories]);

  const customActions = useCallback(
    (onConfirm: (() => void) | undefined, onClose: (() => void) | undefined) => {
      return (
        <>
          <Button
            color="secondary"
            onClick={onClose}
            sx={{
              ...ModalButtonStyle,
              color: theme.palette.primary.main,
              backgroundColor: "white",
              border: `1px solid ${theme.palette.primary.main}`,
            }}
          >
            {t("advancedFilters.modal.cancel")}
          </Button>
          <Button
            color="secondary"
            onClick={onReset}
            sx={{
              ...ModalButtonStyle,
              color: theme.palette.color.DARK_RED[400],
              backgroundColor: "white",
              border: `1px solid ${theme.palette.color.DARK_RED[400]}`,
            }}
            startIcon={<DeleteIcon />}
          >
            {t("advancedFilters.modal.reset")}
          </Button>
          <Button
            onClick={onConfirm}
            sx={{
              ...ModalButtonStyle,
              color: "white",
              backgroundColor: theme.palette.color.deepPurple,
              "&:hover": {
                backgroundColor: theme.palette.color.deepPurple,
              },
            }}
          >
            {t("advancedFilters.modal.apply")}
          </Button>
        </>
      );
    },
    [onReset, t, theme.palette.color.DARK_RED, theme.palette.color.deepPurple, theme.palette.primary.main],
  );

  return (
    <>
      <Button
        id="advanced_filters_button"
        aria-controls={open ? "advanced_filters_action-button" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
        variant="contained"
        color="deepPurple"
        sx={{ border: 0 }}
      >
        {t("advancedFilters.title")}
      </Button>

      <Modal
        isOpen={open}
        onConfirm={onConfirm}
        onClose={onCancel}
        modalIcon={<FilterListIcon />}
        modalTitle={t("advancedFilters.modal.modalTitle")}
        title={t("advancedFilters.modal.title")}
        customActions={customActions}
      >
        <ThemeProvider theme={theme}>
          <Box>
            <InfoBox level={"info"}>
              <Typography variant="body" color={(theme) => theme.palette.color.BASE[800]}>
                {t("advancedFilters.modal.info")}
              </Typography>
            </InfoBox>

            {categories.map(({ onApply: _1, ...category }) => (
              <RenderCategory
                key={category.title}
                pendingValues={pendingValuesPerCategory[category.title]}
                setPendingValues={(selected) => setCategoryPendingValues(selected, category.title)}
                canSelectMore={true}
                {...category}
              />
            ))}
          </Box>
        </ThemeProvider>
      </Modal>
    </>
  );
};
