import { useEffect } from "react";

import { signIn, signOut, useSession } from "next-auth/react";

import { Spinner } from "@components";

export const AuthProtect = ({ children }) => {
  const { data: session, status } = useSession();
  const isUser = !!session?.user;

  // We check if the user exist and that we didn't fail to refresh the token/.
  // The callbackUrl option help us to redirect the user to the last visited
  // page before failing to refresh the token.
  useEffect(() => {
    if (status === "loading") {
      return;
    }

    if (!isUser) {
      signIn("keycloak", { redirect: false });
    }

    // if the call to refresh the token failed with a server error, we retry.
    if (session?.error === "RefreshAccessTokenError") {
      signIn("keycloak", { redirect: false });
    } else if (session?.error === "RefreshTokenExpired") {
      // if the refresh token is expired, the user needs to log-in again
      const idToken = session?.idToken;
      signOut({ callbackUrl: `/api/auth/logout?id_token=${idToken}` });
    }

    if (session?.user) {
      const _mtm: any = (window as any)._mtm || [];
      _mtm.push({
        seiza_user_id: session?.user?.id,
        seiza_user_role: session?.user?.roles,
        seiza_organization_name: session?.user?.groups?.[0] || "",
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isUser, status, session]);

  if (isUser) {
    return children;
  }

  // Session is being fetched, or no user.
  // If no user, useEffect() will redirect.
  return <Spinner />;
};
