import { FC } from "react";

import humanizeDuration from "humanize-duration";
import moment from "moment";
import { Trans, useTranslation } from "next-i18next";

import { Phone as PhoneIcon } from "@mui/icons-material";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineItem from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import { Box, Card } from "@mui/material";

import { AuthProxyAPI } from "@api";
import { QUERY_KEYS } from "@constants";
import { loadTranslations } from "@lib";
import { context } from "@opentelemetry/api";
import { useQuery } from "@tanstack/react-query";
import { AriaProps, CallHistory, CallHistoryStatusEnum } from "@typings";

import { CommentListSubInfo, TimelinePerson } from "./style";

interface CallHistoryDetailProps extends AriaProps {
  callHistory: CallHistory;
  candidateId: string;
}

export const CallHistoryDetail: FC<CallHistoryDetailProps> = ({ callHistory, candidateId, role }) => {
  const {
    t,
    i18n: { language },
  } = useTranslation(["call-history-details", "dates"]);
  loadTranslations("call-history-details");
  loadTranslations("dates");

  const { data: user, isLoading: isLoadingUser } = useQuery({
    queryKey: [QUERY_KEYS.USERS, callHistory.caller_id],
    queryFn: () => AuthProxyAPI.getUser(context.active(), callHistory.caller_id),
  });

  const { data: candidate, isLoading: isLoadingCandidate } = useQuery({
    queryKey: [QUERY_KEYS.CANDIDATES, candidateId],
    queryFn: () => AuthProxyAPI.getCandidate(context.active(), candidateId),
  });

  return (
    <TimelineItem role={role}>
      <TimelineSeparator>
        <TimelinePerson userName={user ? `${user.first_name} ${user.last_name}` : undefined} userEmail={user?.email} />
        <TimelineConnector sx={(theme) => ({ color: theme.palette.grey[400] })} />
      </TimelineSeparator>
      <TimelineContent sx={{ position: "relative", top: "-18px !important" }}>
        {user && candidate && !isLoadingUser && !isLoadingCandidate && (
          <Box>
            <Box
              sx={(theme) => ({
                color: theme.palette.grey[600],
                fontSize: "1rem",
              })}
            >
              <Trans
                t={t}
                i18nKey="call_launched"
                // eslint-disable-next-line react/jsx-key
                components={[<strong />]}
                values={{ name: user.first_name + " " + user.last_name }}
              />
            </Box>
            <Card sx={{ width: "100%", padding: "1rem", border: "1px solid #00115933", borderRadius: "0.5rem" }}>
              <Box display="flex" flexDirection="row" gap="1rem">
                <Box display="flex" alignItems="center">
                  <Box
                    bgcolor="#020626"
                    color="white"
                    borderRadius="50%"
                    width="2rem"
                    height="2rem"
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                  >
                    <PhoneIcon sx={{ height: "1rem", width: "1rem" }} />
                  </Box>
                </Box>

                <Box
                  display="flex"
                  alignItems="baseline"
                  flexDirection="column"
                  justifyContent="center"
                  fontSize="14px"
                >
                  <Trans
                    t={t}
                    i18nKey={callHistory.status === CallHistoryStatusEnum.HANGUP ? "call_ended" : "call_in_progress"}
                    // eslint-disable-next-line react/jsx-key
                    components={[<strong />]}
                    values={{ name: candidate.first_name + " " + candidate.last_name }}
                  />
                  {callHistory.status === CallHistoryStatusEnum.ANSWERED && callHistory.duration_range.lower && (
                    <Trans
                      t={t}
                      i18nKey={"call_started_at"}
                      // eslint-disable-next-line react/jsx-key
                      values={{ time: moment(callHistory.duration_range.lower).format("LT") }}
                    />
                  )}
                  {callHistory.status === CallHistoryStatusEnum.HANGUP && callHistory.duration !== null && (
                    <Box>
                      {humanizeDuration(callHistory.duration * 1000, {
                        units: ["h", "m", "s"],
                        round: true,
                        language: language.split("-")[0],
                        fallbacks: ["en"],
                      })}
                    </Box>
                  )}
                  {callHistory.status === CallHistoryStatusEnum.HANGUP && callHistory.duration === null && (
                    <Trans t={t} i18nKey={"call_hangup_by_recruiter"} />
                  )}
                </Box>
              </Box>
            </Card>
            <CommentListSubInfo created_at={callHistory.created_at.toString()} />
          </Box>
        )}
      </TimelineContent>
    </TimelineItem>
  );
};
