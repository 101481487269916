import { useSession } from "next-auth/react";
import { useTranslation } from "next-i18next";

import { Typography } from "@mui/material";
import { Box } from "@mui/system";

import { loadTranslations } from "@lib";

export const Greetings = () => {
  const { t } = useTranslation(["greetings"]);
  loadTranslations("greetings");

  const { data: session } = useSession();

  return (
    <Box>
      <Typography variant="headingXL">{t("greetings", { name: session?.user.given_name })}</Typography>
    </Box>
  );
};
