import { FC, ReactNode } from "react";

import { SxProps, Theme, Typography } from "@mui/material";

import { TEXT_COLOR } from "@constants";

type PageTitleProps = {
  children?: ReactNode;
  style?: SxProps<Theme>;
};

export const PageTitle: FC<PageTitleProps> = ({ children, style }) => {
  return (
    <Typography variant="headingXL" color={TEXT_COLOR.mainInfo} fontWeight="bold" sx={style}>
      {children}
    </Typography>
  );
};
