import { FC, useMemo } from "react";

import { useTranslation } from "next-i18next";

import { Error as ErrorIcon, Star as StarIcon } from "@mui/icons-material";
import { Box, Chip, SxProps, Theme, Tooltip, Typography, useTheme } from "@mui/material";

import { LoadingAnimation } from "@components";
import { loadTranslations } from "@lib";

import { useScoringQuery } from "@hooks/queries";

// return the label (for translation) matching the score range
export const ApplicationScoreGetterToLabel = (score: number | null | undefined) => {
  if (score == null) {
    return "no_score";
  }

  if (score >= 90) {
    return "top_candidate";
  } else if (score >= 70) {
    return "high";
  } else if (score >= 40) {
    return "medium";
  } else {
    return "low";
  }
};

// Returns a JSX component that displays the "being computed" tooltip
// It's useful because it's a component that we want to display at multiple places.
// It's not a FC function because there is no state here, it's pretty static.
export const ScoreBeingComputedChip = (t, sx?: SxProps<Theme>) => (
  <Box sx={{ ...sx }}>
    <LoadingAnimation />

    <Tooltip
      componentsProps={{
        tooltip: {
          sx: (theme) => ({
            color: "white",
            bgcolor: theme.palette.background.darker,
          }),
        },
      }}
      title={<Typography sx={{ fontSize: "12px" }}>{t("computing", { ns: "application-scoring" })}</Typography>}
      placement="top-end"
    >
      <ErrorIcon
        sx={(theme) => ({
          // small placement to make it closer to the the loading
          position: "relative",
          bottom: "14px",
          right: "4px",

          width: "18px",
          height: "18px",

          color: theme.palette.orange?.main,
        })}
      />
    </Tooltip>
  </Box>
);

type ApplicationScoringChipProps = {
  applicationID: string;
  campaignID: string;
  renderChipForLowPercentages: boolean;
  renderChip?: boolean;
  chipSx?: SxProps;
};

// Display a chip with the label corresponding to the application score.
// renderChipForLowPercentages is here to not display the chip for low percentages, but the text only.
export const ApplicationScoringChip: FC<ApplicationScoringChipProps> = ({
  applicationID,
  campaignID,
  renderChipForLowPercentages,
  chipSx,
  renderChip = true,
}) => {
  const { t } = useTranslation(["application-scoring"]);
  loadTranslations("application-scoring");

  const theme = useTheme();

  const applicationScoreQuery = useScoringQuery(campaignID);
  const score = applicationScoreQuery.data?.[applicationID];

  const scoringText = useMemo(() => ApplicationScoreGetterToLabel(score), [score]);
  const translatedText = useMemo(() => t(scoringText, { ns: "application-scoring" }), [scoringText, t]);

  // compute the sx for the Chip, based on the props and the qualification label (based on the score).
  // We're doing it here and not using the (theme) => SX function of the sx props because typescript doesn't like it.
  const _sx = useMemo(
    () => ({
      ...(chipSx || {}),
      // extract the style based on the score
      ...{
        top_candidate: {
          backgroundColor: theme.palette.color.TOP_GREEN[100] || "",
          color: theme.palette.color.BASE[800],
        },
        high: {
          backgroundColor: theme.palette.green?.light || "",
          color: "white",
        },
        medium: {
          backgroundColor: theme.palette.orange?.main || "",
          color: "white",
        },
        low: {
          backgroundColor: theme.palette.red?.main || "",
          color: "white",
        },
      }[scoringText],
    }),
    [
      chipSx,
      scoringText,
      theme.palette.color.BASE,
      theme.palette.color.TOP_GREEN,
      theme.palette.green?.light,
      theme.palette.orange?.main,
      theme.palette.red?.main,
    ],
  );

  // shows the loading element
  if (applicationScoreQuery.isLoading) {
    return <LoadingAnimation />;
  }

  // shows the computing element
  if (score === undefined) {
    return ScoreBeingComputedChip(t);
  }

  return (
    // renderChipForLowPercentages is here to differentiate between the two designs in the data-grid
    // and the focus drawer. (we don't want to display a chip in the data-grid for low percentages)
    <Box aria-label="application score" role="region">
      {renderChip && (renderChipForLowPercentages || score >= 70) ? (
        <Chip
          label={
            score >= 90 ? (
              <>
                <StarIcon sx={{ width: "1rem", height: "1rem", position: "relative", top: "3px" }} /> {translatedText}
              </>
            ) : (
              translatedText
            )
          }
          sx={_sx}
        />
      ) : (
        <>
          {score >= 90 ? (
            <>
              <StarIcon sx={{ width: "1rem", height: "1rem", position: "relative", top: "3px" }} /> {translatedText}
            </>
          ) : (
            translatedText
          )}
        </>
      )}
    </Box>
  );
};
