import React, { FC, ReactElement, useCallback, useState } from "react";

import { AddDocumentsModal } from "@components";

interface Props {
  applicationID: string;
  render: ({ onClick }: { onClick: () => void }) => ReactElement;
}

export const AddDocumentsAction: FC<Props> = ({ applicationID, render }) => {
  const [addDocumentsModalOpen, setAddDocumentsModalOpen] = useState(false);

  const handleClick = () => {
    setAddDocumentsModalOpen(true);
  };
  const closeModal = useCallback(() => setAddDocumentsModalOpen(false), [setAddDocumentsModalOpen]);

  return (
    <>
      {render({
        onClick: handleClick,
      })}

      <AddDocumentsModal open={addDocumentsModalOpen} closeModal={closeModal} applicationID={applicationID} />
    </>
  );
};
