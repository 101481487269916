import { ScoringAPI } from "@api";
import { QUERY_KEYS } from "@constants";
import { context } from "@opentelemetry/api";
import { useQueries, useQuery } from "@tanstack/react-query";
import { combineQueries, combineQueriesObjects } from "@utils";

export const useScoringEnabledQueries = (...campaignIDs: string[]) =>
  useQueries({
    queries: campaignIDs.map((campaignID) => ({
      queryKey: [QUERY_KEYS.CAMPAIGN_HAS_SCORING, campaignID, "batch"],
      queryFn: async () => ({
        campaignID,
        result: await ScoringAPI.hasEngine(context.active(), campaignID),
      }),
      enabled: !!campaignID,
    })),
    combine: combineQueries<{ campaignID: string; result: boolean }, Record<string, boolean>>({}, (acc, res) => {
      acc[res.campaignID] = res.result;
      return acc;
    }),
  });

export const useScoringQuery = (campaignID: string | undefined) =>
  useQuery({
    queryKey: [QUERY_KEYS.APPLICATION_ALL_SCORES, campaignID],
    queryFn: () => ScoringAPI.getAllScores(context.active(), campaignID ?? ""),
    enabled: !!campaignID,
  });

export const useScoringQueries = (...campaignIDs: string[]) =>
  useQueries({
    queries: campaignIDs.map((campaignID) => ({
      queryKey: [QUERY_KEYS.APPLICATION_ALL_SCORES, campaignID],
      queryFn: () => ScoringAPI.getAllScores(context.active(), campaignID),
      enabled: !!campaignID,
    })),
    combine: combineQueriesObjects(),
  });
