import { JobsApi } from "@api";
import { QUERY_KEYS } from "@constants";
import { context } from "@opentelemetry/api";
import { useQueries } from "@tanstack/react-query";

interface JobAssignees {
  assignees: string[];
  jobID: string;
}
export const useJobsAssignees = (jobIDs: string[]) => {
  return useQueries({
    queries: jobIDs.map((jobID) => ({
      queryKey: [QUERY_KEYS.JOB_ASSIGNEES, jobID, "with-job-id"],
      queryFn: () => JobsApi.listJobAssignees(context.active(), jobID),
      // workaround for the fact that we don't have the jobID in the response.
      select: (assignees) =>
        ({
          assignees: assignees,
          jobID,
        }) as JobAssignees,
    })),
    combine: (results) => {
      const assignees = results.reduce(
        (acc, result) => {
          if (result.isError) {
            return acc;
          }

          if (result.data) {
            acc[result.data.jobID] = result.data.assignees;
          }

          return acc;
        },
        {} as Record<string, string[]>,
      );

      return { assignees, pending: results.some((result) => result.isPending) };
    },
  });
};
