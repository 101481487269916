import React, { useCallback, useRef, useState } from "react";

import { useTranslation } from "next-i18next";

import {
  ArrowDropDown as ArrowDropDownIcon,
  CheckBox as CheckBoxIcon,
  CheckBoxOutlineBlank as CheckBoxOutlineBlankIcon,
} from "@mui/icons-material";
import LoadingButton from "@mui/lab/LoadingButton";
import { Box, Button, Checkbox, TextField, Typography } from "@mui/material";
import Autocomplete, { AutocompleteCloseReason, autocompleteClasses } from "@mui/material/Autocomplete";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Popper from "@mui/material/Popper";
import { styled } from "@mui/material/styles";

import { loadTranslations } from "@lib";
import { ILabelValueItem } from "@typings";

interface PopperComponentProps {
  anchorEl?: unknown;
  disablePortal?: boolean;
  open: boolean;
}

const StyledAutocompletePopper = styled("div")(({ theme }) => ({
  [`& .${autocompleteClasses.popper}`]: {
    width: "100%",
  },
  [`& .${autocompleteClasses.paper}`]: {
    boxShadow: "none",
    margin: 0,
    color: "inherit",
  },
  [`& .${autocompleteClasses.listbox}`]: {
    padding: 0,
    [`& .${autocompleteClasses.option}`]: {
      minHeight: "auto",
      alignItems: "flex-start",
      '&[aria-selected="true"]': {
        backgroundColor: "transparent",
      },
      [`&.${autocompleteClasses.focused}, &.${autocompleteClasses.focused}[aria-selected="true"]`]: {
        backgroundColor: theme.palette.action.hover,
      },
    },
  },
  [`&.${autocompleteClasses.popperDisablePortal}`]: {
    position: "relative",
  },
}));

function PopperComponent(props: PopperComponentProps) {
  const { disablePortal: _1, anchorEl: _2, open: _3, ...other } = props;
  return <StyledAutocompletePopper {...other} />;
}

const StyledPopper = styled(Popper)(({ theme }) => ({
  border: `1px solid ${theme.palette.mode === "light" ? "#e1e4e8" : "#30363d"}`,
  boxShadow: `0 8px 24px ${theme.palette.mode === "light" ? "rgba(149, 157, 165, 0.2)" : "rgb(1, 4, 9)"}`,
  borderRadius: 6,
  zIndex: theme.zIndex.modal,
  fontSize: 13,
  color: theme.palette.mode === "light" ? "#24292e" : "#c9d1d9",
  backgroundColor: theme.palette.mode === "light" ? "#fff" : "#1c2128",
}));

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

interface Props {
  id: string;
  title: string;
  value: ILabelValueItem[];
  onApply: (selected: ILabelValueItem[]) => void;
  options: ILabelValueItem[];
  loading?: boolean;
  fullWidth?: boolean;
  getOptionDisabled?: (option: ILabelValueItem) => boolean;
}

export const AutocompleteSelect = ({
  id,
  title,
  options,
  value,
  onApply,
  loading,
  fullWidth,
  getOptionDisabled,
}: Props) => {
  const { t } = useTranslation(["autocomplete-select"]);
  loadTranslations("autocomplete-select");

  const buttonRef = useRef<HTMLButtonElement | null>(null);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [pendingValue, setPendingValue] = useState<ILabelValueItem[]>([]);

  const open = Boolean(anchorEl);

  const handleClose = useCallback(() => {
    setPendingValue([]);
    if (anchorEl) {
      anchorEl.focus();
    }
    setAnchorEl(null);
  }, [anchorEl]);

  const handleClick = useCallback(
    (event: React.MouseEvent<HTMLElement>) => {
      if (open) {
        handleClose();
        return;
      }
      setPendingValue(value);
      setAnchorEl(event.currentTarget);
    },
    [handleClose, open, value],
  );

  const handleApply = useCallback(() => {
    onApply(pendingValue);
    if (anchorEl) {
      anchorEl.focus();
    }
    setAnchorEl(null);
  }, [anchorEl, onApply, pendingValue]);

  return (
    <React.Fragment>
      <LoadingButton
        ref={buttonRef}
        disableRipple
        fullWidth={fullWidth}
        aria-describedby={id}
        onClick={handleClick}
        variant="outlined"
        size="large"
        sx={(theme) => ({
          display: "flex",
          justifyContent: "space-between",
          backgroundColor: theme.palette.color.BASE[100],
          paddingY: theme.size[24],
        })}
        endIcon={<ArrowDropDownIcon />}
        loading={loading}
        loadingPosition="end"
      >
        <Typography variant="body" color={(theme) => theme.palette.color.BASE[800]}>
          {title}
        </Typography>
      </LoadingButton>
      <StyledPopper
        id={id}
        open={open}
        anchorEl={anchorEl}
        placement="bottom-start"
        sx={{
          width: buttonRef.current?.clientWidth,
        }}
      >
        <ClickAwayListener onClickAway={handleClose}>
          <Box p={(theme) => theme.spacings[8]}>
            <Autocomplete
              getOptionDisabled={getOptionDisabled}
              loading={loading}
              open
              multiple
              onClose={(_event, reason: AutocompleteCloseReason) => {
                if (reason === "escape") {
                  handleClose();
                }
              }}
              value={pendingValue}
              onChange={(event, newValue, reason) => {
                if (
                  event.type === "keydown" &&
                  ((event as React.KeyboardEvent).key === "Backspace" ||
                    (event as React.KeyboardEvent).key === "Delete") &&
                  reason === "removeOption"
                ) {
                  return;
                }
                setPendingValue(newValue);
              }}
              disableCloseOnSelect
              disablePortal
              PopperComponent={PopperComponent}
              renderTags={() => null}
              renderOption={(props, option, { selected }) => (
                <li {...props}>
                  <Box display="inline-flex" alignItems="center">
                    <Checkbox icon={icon} checkedIcon={checkedIcon} style={{ marginRight: 8 }} checked={selected} />
                    <span>{option.label}</span>
                  </Box>
                </li>
              )}
              isOptionEqualToValue={(option, value) => option.value === value.value}
              options={options}
              getOptionKey={(option) => option.value}
              getOptionLabel={(option) => option.label}
              renderInput={(params) => (
                <TextField
                  fullWidth
                  size="small"
                  ref={params.InputProps.ref}
                  inputProps={params.inputProps}
                  autoFocus
                  placeholder={t("quick_search")}
                />
              )}
            />
            <Box
              display="flex"
              justifyContent="end"
              mt={(theme) => theme.spacings[8]}
              gap={(theme) => theme.spacings[8]}
            >
              <Button onClick={handleClose} variant="outlined">
                {t("actions.cancel")}
              </Button>
              <Button onClick={handleApply} variant="contained" color="deepPurple">
                {t("actions.apply")}
              </Button>
            </Box>
          </Box>
        </ClickAwayListener>
      </StyledPopper>
    </React.Fragment>
  );
};
