export enum ExportHistoryStatus {
  Done = "Done",
  Running = "Running",
  Error = "Error",
  Unavailable = "Unavailable",
  Started = "Started", // only used on the platform
}

export interface ExportHistory {
  id: string;
  status: ExportHistoryStatus;
  user_id: string;
  created_at: Date;
  duration: {
    duration_start: string;
    duration_end: string;
  };
}
