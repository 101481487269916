import { i18n } from "next-i18next";

const LANGS = ["en-US", "fr"];
export const loadTranslations = (name: string): void => {
  LANGS.forEach((lang) => {
    try {
      i18n?.addResourceBundle(lang, name, require(`../public/locales/${lang}/${name}.json`));
    } catch (err) {
      // pass
    }
  });
};
