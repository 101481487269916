import { Theme } from "@mui/material";
import { styled } from "@mui/material/styles";

export const SpinnerStyle = styled("div")(
  ({ width, height, theme }: { width: string; height: string; theme: Theme }) => `
  &.lds-ring {
    position: absolute;
    width: ${width || "80px"};
    height: ${height || "80px"};
    top: 50%;
    left: 50%;
  }
  &.lds-ring div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: ${width || "64px"};
    height: ${height || "64px"};
    margin: 8px;
    border: 8px solid ${theme.palette.primary.main};
    border-radius: 50%;
    animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: ${theme.palette.primary.main} transparent transparent transparent;
  }
  @keyframes lds-ring {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`,
);
