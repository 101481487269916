import { Dispatch, FC, SetStateAction, useCallback } from "react";

import { AxiosError } from "axios";
import { useTranslation } from "next-i18next";
import { toast } from "react-toastify";

import { Box, ThemeProvider, Typography, useTheme } from "@mui/material";

import { Modal } from "@work4Labs/design-system";

import { JobsApi } from "@api";
import { QUERY_KEYS } from "@constants";
import { loadTranslations } from "@lib";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { Logger } from "@utils";

import { ListJobItemWithStats } from "../types";

type Props = {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  jobs: ListJobItemWithStats[];
  onSuccess: () => void;
};

export const DeleteJobsModal: FC<Props> = ({ open, setOpen, jobs, onSuccess }) => {
  const { t } = useTranslation(["delete-jobs"]);
  loadTranslations("delete-jobs");

  const theme = useTheme();

  const { mutateAsync: deleteJobMutation } = useMutation<void, AxiosError, string>({
    mutationFn: JobsApi.deleteJob,
  });

  const queryClient = useQueryClient();

  const doOnSubmit = useCallback(async () => {
    const promises: Promise<void>[] = [];

    jobs.forEach((job) => {
      promises.push(deleteJobMutation(job.id));
    });

    const results = await Promise.allSettled(promises);

    const errCount = results.filter((r) => r.status == "rejected").length;

    if (errCount == 0) {
      toast.success(t("delete_jobs_done"));
      queryClient
        .invalidateQueries({
          queryKey: [QUERY_KEYS.JOBS_LIST],
        })
        .catch(Logger.error);
      onSuccess();
    } else {
      toast.error(t("delete_jobs_error", { count: errCount }));
    }

    setOpen(false);
  }, [jobs, setOpen, deleteJobMutation, t, queryClient, onSuccess]);

  const onSubmit = useCallback(() => {
    doOnSubmit().catch(Logger.error);
  }, [doOnSubmit]);

  const onCancel = useCallback(() => {
    setOpen(false);
  }, [setOpen]);

  return (
    <Modal
      isOpen={open}
      aria-labelledby="delete-jobs"
      aria-describedby="A modal for deleting jobs"
      modalTitle={t("title")}
      confirmText={t("submit")}
      cancelText={t("cancel")}
      onConfirm={onSubmit}
      onClose={onCancel}
      options={{
        confirmProps: { variant: "danger" },
      }}
    >
      <ThemeProvider theme={theme}>
        <Box
          display="flex"
          flexDirection="column"
          gap={(theme) => theme.spacings[16]}
          paddingY={(theme) => theme.spacings[16]}
        >
          <Typography variant="headingLG" color={(theme) => theme.palette.text.mainInfo}>
            {t("warning_delete_jobs_title")}
          </Typography>
          <Typography variant="tags" color={(theme) => theme.palette.text.mainInfo}>
            {t("warning_delete_jobs")}
          </Typography>
        </Box>
      </ThemeProvider>
    </Modal>
  );
};
