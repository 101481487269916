import { FC, ReactElement, useState } from "react";

import "react-multi-email/dist/style.css";

import { ShareApplicationModal } from "@components";
import { Application } from "@typings";

interface Props {
  application: Application;
  render: ({ onClick }: { onClick: () => void }) => ReactElement;
}

export const ShareAction: FC<Props> = ({ application, render }) => {
  const [modalOpen, setModalOpen] = useState(false);

  const handleClick = () => {
    setModalOpen(true);
  };

  return (
    <>
      {render({
        onClick: handleClick,
      })}
      <ShareApplicationModal applicationID={application.id} open={modalOpen} setOpen={setModalOpen} />
    </>
  );
};
