import { FC, useCallback, useState } from "react";

import { RecruiterReminderCreationModal } from "components/application/history/recruiter-reminder-modal";
import { useTranslation } from "next-i18next";

import { NotificationsNone as NotificationsNoneIcon } from "@mui/icons-material";
import { Tooltip } from "@mui/material";

import { loadTranslations } from "@lib";

import { ActionIconButton } from "./application-actions";

interface Props {
  applicationID: string;
}

export const ReminderAction: FC<Props> = ({ applicationID }) => {
  const [reminderModalOpened, setReminderModalOpened] = useState(false);

  const { t } = useTranslation(["application-details"]);
  loadTranslations("application-details");

  const closeModal = useCallback(() => {
    setReminderModalOpened(false);
  }, [setReminderModalOpened]);

  const openModal = useCallback(() => {
    setReminderModalOpened(true);
  }, [setReminderModalOpened]);

  const onSuccess = useCallback(() => {
    // nothing to do, but we still want to avoid un-necessary re-renders.
  }, []);

  return (
    <>
      <Tooltip
        title={t("actions.create_task")}
        placement="top"
        componentsProps={{
          tooltip: {
            sx: (theme) => ({
              color: "white",
              bgcolor: theme.palette.background.darker,
              textAlign: "center",
              fontSize: "0.875rem",
            }),
          },
        }}
        arrow
      >
        <ActionIconButton onClick={openModal} id="quick_action_create_recruiter_reminder">
          <NotificationsNoneIcon
            sx={{
              height: 20,
              width: 20,
            }}
          />
        </ActionIconButton>
      </Tooltip>
      <RecruiterReminderCreationModal
        applicationID={applicationID}
        initialComment={""}
        modalOpened={reminderModalOpened}
        closeModal={closeModal}
        onSuccess={onSuccess}
      />
    </>
  );
};
