import { FC, ReactNode } from "react";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Accordion, AccordionDetails, AccordionSummary, Card, CardContent, styled } from "@mui/material";
import Grid from "@mui/material/Grid";

import {
  FormContextType,
  ObjectFieldTemplateProps,
  RJSFSchema,
  StrictRJSFSchema,
  canExpand,
  descriptionId,
  getTemplate,
  getUiOptions,
  titleId,
} from "@rjsf/utils";

/** The `ObjectFieldTemplate` is the template to use to render all the inner properties of an object along with the
 * title and description if available. If the object is expandable, then an `AddButton` is also rendered after all
 * the properties.
 *
 * @param props - The `ObjectFieldTemplateProps` for this component
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function ObjectFieldTemplate<T = any, S extends StrictRJSFSchema = RJSFSchema, F extends FormContextType = any>(
  props: ObjectFieldTemplateProps<T, S, F>,
) {
  const {
    description,
    title,
    properties,
    required,
    disabled,
    readonly,
    uiSchema,
    idSchema,
    schema,
    formData,
    onAddClick,
    registry,
  } = props;
  const uiOptions = getUiOptions<T, S, F>(uiSchema);
  const TitleFieldTemplate = getTemplate<"TitleFieldTemplate", T, S, F>("TitleFieldTemplate", registry, uiOptions);
  const DescriptionFieldTemplate = getTemplate<"DescriptionFieldTemplate", T, S, F>(
    "DescriptionFieldTemplate",
    registry,
    uiOptions,
  );
  // Button templates are not overridden in the uiSchema
  const {
    ButtonTemplates: { AddButton },
  } = registry.templates;
  return (
    <ConditionalAccordion
      id={titleId<T>(idSchema)}
      title={
        <TitleFieldTemplate
          id={titleId<T>(idSchema)}
          title={title}
          required={required}
          schema={schema}
          uiSchema={uiSchema}
          registry={registry}
        />
      }
      condition={uiSchema?.["ui:collapsible"]}
    >
      <ConditionalCard condition={uiSchema?.["ui:inCard"] && !uiSchema?.["ui:collapsible"]}>
        {title && !uiSchema?.["ui:collapsible"] && (
          <TitleFieldTemplate
            id={titleId<T>(idSchema)}
            title={title}
            required={required}
            schema={schema}
            uiSchema={uiSchema}
            registry={registry}
          />
        )}
        {description && (
          <DescriptionFieldTemplate
            id={descriptionId<T>(idSchema)}
            description={description}
            schema={schema}
            uiSchema={uiSchema}
            registry={registry}
          />
        )}
        <Grid container spacing={2} style={{ marginTop: "10px" }}>
          {properties.map((element, index) =>
            // Remove the <Grid> if the inner element is hidden as the <Grid>
            // itself would otherwise still take up space.
            element.hidden ? (
              element.content
            ) : (
              <Grid
                item
                xs={uiSchema?.[element.name]?.["ui:fieldFlexWidthXs"] || 12}
                sm={
                  uiSchema?.[element.name]?.["ui:fieldFlexWidthSm"] ||
                  uiSchema?.[element.name]?.["ui:fieldFlexWidthXs"] ||
                  12
                }
                md={
                  uiSchema?.[element.name]?.["ui:fieldFlexWidthMd"] ||
                  uiSchema?.[element.name]?.["ui:fieldFlexWidthSm"] ||
                  uiSchema?.[element.name]?.["ui:fieldFlexWidthXs"] ||
                  12
                }
                lg={
                  uiSchema?.[element.name]?.["ui:fieldFlexWidthLg"] ||
                  uiSchema?.[element.name]?.["ui:fieldFlexWidthMd"] ||
                  uiSchema?.[element.name]?.["ui:fieldFlexWidthSm"] ||
                  uiSchema?.[element.name]?.["ui:fieldFlexWidthXs"] ||
                  12
                }
                xl={
                  uiSchema?.[element.name]?.["ui:fieldFlexWidthXl"] ||
                  uiSchema?.[element.name]?.["ui:fieldFlexWidthLg"] ||
                  uiSchema?.[element.name]?.["ui:fieldFlexWidthMd"] ||
                  uiSchema?.[element.name]?.["ui:fieldFlexWidthSm"] ||
                  uiSchema?.[element.name]?.["ui:fieldFlexWidthXs"] ||
                  12
                }
                key={index}
                style={{ marginBottom: "10px" }}
              >
                {element.content}
              </Grid>
            ),
          )}
          {canExpand<T, S, F>(schema, uiSchema, formData) && (
            <Grid container justifyContent="flex-end">
              <Grid item>
                <AddButton
                  className="object-property-expand"
                  onClick={onAddClick(schema)}
                  disabled={disabled || readonly}
                  uiSchema={uiSchema}
                  registry={registry}
                />
              </Grid>
            </Grid>
          )}
        </Grid>
      </ConditionalCard>
    </ConditionalAccordion>
  );
}

const ConditionalCard = ({ condition, children }) =>
  condition ? (
    <Card>
      <CardContent>{children}</CardContent>
    </Card>
  ) : (
    children
  );

interface ConditionalAccordionProps {
  id: string;
  title: ReactNode;
  condition: boolean;
  children: ReactNode;
}
const ConditionalAccordion: FC<ConditionalAccordionProps> = ({ id, title, condition, children }) =>
  condition ? (
    <Accordion>
      <CustomAccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls={`${id}-content`} id={`${id}-header`}>
        {title}
      </CustomAccordionSummary>
      <AccordionDetails>{children}</AccordionDetails>
    </Accordion>
  ) : (
    <>{children}</>
  );

const CustomAccordionSummary = styled(AccordionSummary)(() => {
  return {
    "& .MuiAccordionSummary-content": { flexDirection: "column" },
  };
});
