import { useRouter } from "next/router";

import { useMemo, useState } from "react";

import { MRT_TableInstance } from "material-react-table";
import { useTranslation } from "next-i18next";

import { useTheme } from "@mui/material";

import {
  BulkApplicationStatusUpdateModal,
  DeleteApplicationUpdateModal,
  GroupAction,
  RecomputeScoreModal,
} from "@components";
import { UserPermissions } from "@constants";
import { loadTranslations } from "@lib";
import { useApplicationsStore } from "@stores";
import { ApplicationListItem } from "@typings";
import { Logger } from "@utils";

import { useHasPermissions } from "@hooks";

interface LeadGroupActionsProps {
  campaignID?: string;
  jobID?: string;
}

export const useLeadsGroupActions = (
  { campaignID, jobID }: LeadGroupActionsProps,
  table: MRT_TableInstance<ApplicationListItem>,
) => {
  const { t } = useTranslation(["applications-grouped-actions"]);
  loadTranslations("applications-grouped-actions");

  const theme = useTheme();

  const setCurrentApplicationID = useApplicationsStore((state) => state.setCurrentApplicationID);
  const setApplicationsID = useApplicationsStore((state) => state.setApplicationsID);
  const setCurrentCampaignID = useApplicationsStore((state) => state.setCurrentCampaignID);
  const setCurrentJobID = useApplicationsStore((state) => state.setCurrentJobID);

  const [isBulkUpdateModalOpen, setIsBulkUpdateModalOpen] = useState(false);
  const [isDeleteApplicationModalOpen, setIsDeleteApplicationModalOpen] = useState(false);
  const [isBulkRecomputeScoreModalOpen, setIsBulkRecomputeScoreModalOpen] = useState(false);

  const canDeleteApplication = useHasPermissions([UserPermissions.ApplicationPermissions.Delete]);
  const canCreateScore = useHasPermissions([UserPermissions.ScoringPermissions.Write]);

  const { push: routerPush, pathname } = useRouter();

  const { getSelectedRowModel, resetRowSelection } = table;

  const groupActions = useMemo<GroupAction<ApplicationListItem>[]>(
    () => [
      {
        display: (selected) => t("open_applications", { count: selected.length, ns: "applications-grouped-actions" }),
        onClick: (selected) => {
          setCurrentApplicationID(selected[0].id);
          setApplicationsID(selected.map((application) => application.id));
          setCurrentCampaignID(campaignID ?? null);
          setCurrentJobID(jobID ?? null);
          routerPush({ pathname: `${pathname}/view`, query: { id: campaignID ?? jobID } }, undefined, {
            shallow: false,
          }).catch(Logger.error);
        },
      },
      {
        display: (selected) => t("change_status", { count: selected.length, ns: "applications-grouped-actions" }),
        onClick: () => setIsBulkUpdateModalOpen(true),
      },
      {
        display: (selected) => t("recompute_score", { count: selected.length, ns: "applications-grouped-actions" }),
        onClick: () => setIsBulkRecomputeScoreModalOpen(true),
        enabled: canCreateScore,
      },
      {
        display: (selected) => (
          <span style={{ fontSize: "inherit", color: theme.palette.color.ALERT_RED[400] }}>
            {t("delete_applications", { count: selected.length, ns: "applications-grouped-actions" })}
          </span>
        ),
        onClick: () => setIsDeleteApplicationModalOpen(true),
        enabled: canDeleteApplication,
      },
    ],
    [
      campaignID,
      canCreateScore,
      canDeleteApplication,
      jobID,
      pathname,
      routerPush,
      setApplicationsID,
      setCurrentApplicationID,
      setCurrentCampaignID,
      setCurrentJobID,
      t,
      theme.palette.color.ALERT_RED,
    ],
  );

  const selectedApplications = getSelectedRowModel().rows.map((row) => row.original);

  const modals = (
    <>
      {isBulkUpdateModalOpen ? (
        <BulkApplicationStatusUpdateModal
          applications={selectedApplications}
          open={isBulkUpdateModalOpen}
          setOpen={setIsBulkUpdateModalOpen}
          onSuccess={resetRowSelection}
        />
      ) : null}

      {isDeleteApplicationModalOpen ? (
        <DeleteApplicationUpdateModal
          applications={selectedApplications}
          open={isDeleteApplicationModalOpen}
          setOpen={setIsDeleteApplicationModalOpen}
          onSuccess={resetRowSelection}
        />
      ) : null}

      {isBulkRecomputeScoreModalOpen ? (
        <RecomputeScoreModal
          applicationIDs={selectedApplications.map((application) => application.id)}
          open={isBulkRecomputeScoreModalOpen}
          setOpen={setIsBulkRecomputeScoreModalOpen}
          onSuccess={resetRowSelection}
        />
      ) : null}
    </>
  );

  return { groupActions, groupActionsModals: modals };
};
