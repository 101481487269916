import { OrganizationRequestFormLinkAPI } from "@api";
import { QUERY_KEYS } from "@constants";
import { context } from "@opentelemetry/api";
import { QueryObserverResult, useQuery } from "@tanstack/react-query";
import { OrganizationRequestFormLink } from "@typings";

export interface OrganizationRequestFormLinkHook {
  data: OrganizationRequestFormLink | undefined;
  isLoading: boolean;
  refetch: () => Promise<QueryObserverResult<OrganizationRequestFormLink | undefined, Error>>;
}

export const useOrganizationRequestFormLink = (
  groupID?: string,
  disabled?: boolean,
): OrganizationRequestFormLinkHook => {
  return useQuery({
    queryKey: [QUERY_KEYS.ORGANIZATIONS, "group", groupID],
    queryFn: () => OrganizationRequestFormLinkAPI.get(context.active(), { organization_id: groupID ?? "" }),
    enabled: !!groupID && !disabled,
  });
};
