import { useCallback, useEffect, useMemo } from "react";

import { signIn, useSession } from "next-auth/react";
import { useTranslation } from "next-i18next";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import * as yup from "yup";

import { Typography } from "@mui/material";

import { ControlledTextField, Form } from "@work4Labs/design-system";

import { UpdateUserPersonalInformationBody, userApi } from "@api";
import { SettingsSaveButton } from "@components";
import { loadTranslations } from "@lib";
import { context } from "@opentelemetry/api";
import { useMutation } from "@tanstack/react-query";
import { Logger } from "@utils";

import { yupResolver } from "@hookform/resolvers/yup";

const usePersonalInformationValidationSchema = () => {
  const { t } = useTranslation(["profile-validation"]);
  loadTranslations("profile-validation");

  return useMemo(
    () =>
      yup.object().shape({
        first_name: yup.string().required(t("profile-validation:firstName.required")),
        last_name: yup.string().required(t("profile-validation:lastName.required")),
        email: yup
          .string()
          .email(t("profile-validation:email.invalid"))
          .required(t("profile-validation:email.required")),
      }),
    [t],
  );
};

interface PersonalInformationFormData {
  first_name: string;
  last_name: string;
  email: string;
}

export const PersonalInformationForm = () => {
  const { t } = useTranslation(["profile-settings"]);
  loadTranslations("profile-settings");

  const { data: session } = useSession();

  const validationSchema = usePersonalInformationValidationSchema();

  const personalInformationForm = useForm<PersonalInformationFormData>({
    resolver: yupResolver(validationSchema),
    shouldUnregister: false,
    mode: "onChange",
    defaultValues: {
      first_name: session?.user.given_name,
      last_name: session?.user.family_name,
      email: session?.user.email,
    },
  });

  // Update default values when session loads or is updated.
  useEffect(() => {
    if (session) {
      personalInformationForm.reset({
        first_name: session.user.given_name,
        last_name: session.user.family_name,
        email: session.user.email,
      });
    }
  }, [personalInformationForm, session]);

  const updateUserPersonalInformationMutation = useMutation({
    mutationFn: (data: Partial<UpdateUserPersonalInformationBody>) =>
      userApi.updateUserPersonalInfo(context.active(), data),
    onSuccess: async () => {
      // This sign-in enables to refresh token information.
      // https://github.com/nextauthjs/next-auth/issues/2269
      await signIn("keycloak", { callbackUrl: "/personal-settings" }).catch(Logger.error);
      toast.success(t("profile-settings:update.success"));
    },
    onError: (err) => {
      Logger.error(err);
      toast.error(t("profile-settings:update.error"));
    },
  });

  const updateUserPersonalInformation = useCallback(
    (formData: PersonalInformationFormData) => {
      // Check which data needs to be updated.
      const dataToUpdate: Partial<PersonalInformationFormData> = {};

      if (formData.first_name !== session?.user.given_name) dataToUpdate.first_name = formData.first_name;
      if (formData.last_name !== session?.user.family_name) dataToUpdate.last_name = formData.last_name;
      if (formData.email !== session?.user.email) dataToUpdate.email = formData.email;

      const shouldUpdateProfile = Object.keys(dataToUpdate).length > 0;

      if (shouldUpdateProfile) {
        updateUserPersonalInformationMutation.mutate(dataToUpdate);
      }
    },
    [session?.user.given_name, session?.user.family_name, session?.user.email, updateUserPersonalInformationMutation],
  );

  const watchFirstName = personalInformationForm.watch("first_name");
  const watchLastName = personalInformationForm.watch("last_name");
  const watchEmail = personalInformationForm.watch("email");

  const canSave =
    session &&
    personalInformationForm.formState.isValid &&
    !updateUserPersonalInformationMutation.isPending &&
    (watchFirstName !== session?.user.given_name ||
      watchLastName !== session?.user.family_name ||
      watchEmail !== session?.user.email);

  return (
    <Form
      style={{ display: "flex", flexDirection: "column", gap: "var(--size-24)", alignItems: "stretch" }}
      submitHandler={updateUserPersonalInformation}
      methods={personalInformationForm}
    >
      <ControlledTextField
        error={personalInformationForm.formState.errors.first_name?.message}
        label={t("profile-settings:account.personalInformation.firstName")}
        name="first_name"
        disabled={session == null}
        fullWidth
      />
      <ControlledTextField
        error={personalInformationForm.formState.errors.last_name?.message}
        label={t("profile-settings:account.personalInformation.lastName")}
        name="last_name"
        disabled={session == null}
        fullWidth
      />
      <ControlledTextField
        error={personalInformationForm.formState.errors.email?.message}
        disabled={session == null}
        label={t("profile-settings:account.personalInformation.email")}
        name="email"
        fullWidth
      />
      {personalInformationForm.formState.errors.root ? (
        <Typography color="var(--color-palette-alert-red-400)">
          {personalInformationForm.formState.errors.root.message}
        </Typography>
      ) : null}
      <SettingsSaveButton disabled={!canSave}>
        {t("profile-settings:account.personalInformation.save")}
      </SettingsSaveButton>
    </Form>
  );
};
