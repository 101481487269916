import { useEffect } from "react";

import { Trans, useTranslation } from "next-i18next";
import { toast } from "react-toastify";

import { Error as ErrorIcon } from "@mui/icons-material";
import { useTheme } from "@mui/material";

import { loadTranslations } from "@lib";
import { useNotification } from "@telnyx/react-client";

export const CallInProgressAlert = () => {
  const { t } = useTranslation("click-to-call");
  loadTranslations("click-to-call");

  const theme = useTheme();

  const notification = useNotification();

  useEffect(() => {
    if (["new", "requesting", "trying", "early", "active"].includes(notification?.call?.state as string)) {
      toast.error(<Trans i18nKey="call_in_progress" t={t} />, {
        toastId: notification?.call?.id,
        position: "bottom-center",
        autoClose: false,
        hideProgressBar: true,
        closeOnClick: false,
        pauseOnHover: false,
        draggable: false,
        icon: <ErrorIcon sx={{ color: theme.palette.color.ALERT_RED[400] }} />,
        style: {
          color: theme.palette.color.BASE[800],
          backgroundColor: theme.palette.color.ALERT_RED[100],
          fontSize: "1rem",
        },
      });
    } else if (notification?.call?.id) {
      toast.dismiss(notification.call.id as string);
    }
  }, [t, theme.palette.color.ALERT_RED, theme.palette.color.BASE, notification]);

  return <></>;
};
