import { useState } from "react";

import { useSession } from "next-auth/react";
import { useTranslation } from "next-i18next";

import { Stack, Tab, Tabs, Typography } from "@mui/material";

import { Roles } from "@constants";
import { loadTranslations } from "@lib";
import { hasRole } from "@utils";

import { OrganizationTab, SettingsTab, UsersTab } from "./tabs";

const DEFAULT_TAB = "organization";

export const OrganizationSettingsPage = () => {
  const { t } = useTranslation(["organization-settings"]);
  loadTranslations("organization-settings");

  const { data: session } = useSession();

  const [settingsTab, setSettingsTab] = useState<"organization" | "users" | "settings">(DEFAULT_TAB);

  return (
    <Stack padding="var(--size-48)" gap="var(--size-48)" flexDirection="column">
      <Stack gap="var(--size-24)" flexDirection="column">
        <Typography
          color="var(--color-palette-base-800)"
          fontWeight={800}
          fontSize="var(--font-size-200)"
          lineHeight="120%"
        >
          {t("organization-settings:title")}
        </Typography>
        <Tabs
          value={settingsTab}
          onChange={(_, newValue: typeof settingsTab) => setSettingsTab(newValue)}
          sx={{
            borderStyle: "solid",
            borderWidth: "0 0 1px 0",
            borderColor: "var(--color-palette-pastel-purple)",
            ".MuiTabs-indicator": {
              backgroundColor: "var(--color-palette-deep-purple)",
            },
            ".MuiTab-root": {
              fontWeight: 400,
              fontSize: "var(--font-size-85)",
              color: "var(--color-palette-base-800)",
              padding: "var(--space-8)",
              ".MuiTouchRipple-root": {
                display: "none",
              },
              "&.Mui-selected": {
                color: "var(--color-palette-deep-purple)",
                fontWeight: 700,
              },
            },
          }}
        >
          <Tab label={t("organization-settings:tabs.organization")} value="organization" />
          <Tab label={t("organization-settings:tabs.users")} value="users" />
          {hasRole(session, Roles.SUPER_ADMIN) ? (
            <Tab label={t("organization-settings:tabs.settings")} value="settings" />
          ) : null}
        </Tabs>
      </Stack>

      {settingsTab === "organization" ? <OrganizationTab /> : null}
      {settingsTab === "users" ? <UsersTab /> : null}
      {settingsTab === "settings" && hasRole(session, Roles.SUPER_ADMIN) ? <SettingsTab /> : null}
    </Stack>
  );
};
