import { ChangeEvent, ChangeEventHandler, Dispatch, FC, SetStateAction, useCallback, useEffect, useMemo } from "react";

import { Trans, useTranslation } from "next-i18next";

import { Grid, InputLabel, MenuItem, SelectChangeEvent, Typography } from "@mui/material";

import { Checkbox, Select, TextField } from "@work4Labs/design-system";

import {
  STATUSES_REASONS_WITH_MANDATORY_COMMENT,
  STATUSES_REASONS_WITH_SOURCE,
  STATUS_REASON_SOURCES,
  STATUS_VALIDATION_REASONS_PER_LABEL,
} from "@constants";
import { loadTranslations } from "@lib";
import {
  ApplicationStatusReasonSource,
  ApplicationStatusReasonWhyForm,
  AtsIntegration,
  MessagingTrigger,
  Status,
} from "@typings";
import { ensureArray } from "@utils";

export const RenderTriggers: FC<{ triggers?: MessagingTrigger[]; status: Status }> = ({ triggers, status }) => {
  const { t } = useTranslation("status-selector-field");
  loadTranslations("status-selector-field");

  if (!ensureArray(triggers).find((trigger) => trigger.status_id === status.id)) return null;

  return (
    <Typography
      color={(theme) => theme.palette.primary.main}
      sx={{ fontSize: "0.8rem" }}
      variant="caption2"
      marginLeft="1rem"
    >
      <span>&#x2022;</span> {t("automatic_sms", { ns: "status-selector-field" })}
    </Typography>
  );
};

export const RenderATSIntegration: FC<{ atsIntegrations?: AtsIntegration[]; status: Status }> = ({
  atsIntegrations,
  status,
}) => {
  const { t } = useTranslation("status-selector-field");
  loadTranslations("status-selector-field");

  const statusHasATSIntegration = useMemo(
    () => !!atsIntegrations?.some((atsIntegration) => atsIntegration.status_ids.includes(status.id)),
    [atsIntegrations, status.id],
  );

  if (!statusHasATSIntegration) return null;

  return (
    <Typography
      color={(theme) => theme.palette.primary.main}
      sx={{ fontSize: "0.8rem" }}
      variant="caption2"
      marginLeft="1rem"
    >
      <span>&#x2022;</span> {t("ats_export", { ns: "status-selector-field" })}
    </Typography>
  );
};

export const SendSMSModalForm: FC<{ pendingStatus: string }> = ({ pendingStatus }) => {
  const { t } = useTranslation(["application-status"]);
  loadTranslations("application-status");

  return (
    <Grid item xs={12} sx={{ display: "flex", flexDirection: "column", gap: "1rem" }}>
      <Typography>
        <Trans t={t} i18nKey={`send sms.modal.content.${pendingStatus}`} />
      </Typography>
    </Grid>
  );
};

export const UpdateReasonWhyForm: FC<{
  pendingStatus: string;
  reasonWhy: ApplicationStatusReasonWhyForm | undefined;
  setReasonWhy: Dispatch<SetStateAction<ApplicationStatusReasonWhyForm | undefined>>;
  setFormValid: Dispatch<SetStateAction<boolean>>;
  sendSMS?: boolean;
  setSendSMS?: Dispatch<SetStateAction<boolean>>;
}> = ({ pendingStatus, reasonWhy, setReasonWhy, setFormValid, sendSMS, setSendSMS }) => {
  const { t } = useTranslation(["application-status"]);
  loadTranslations("application-status");

  const hasSource = STATUSES_REASONS_WITH_SOURCE.includes(pendingStatus);

  const updateSource = useCallback(
    (event: SelectChangeEvent<"recruiter" | "candidate">) => {
      event.preventDefault();
      setReasonWhy((prevState) => ({
        source: event.target.value as ApplicationStatusReasonSource,
        reason: prevState?.reason ?? "",
        comment: prevState?.comment,
      }));
    },
    [setReasonWhy],
  );

  const updateReason = useCallback(
    (event: SelectChangeEvent) => {
      event.preventDefault();
      setReasonWhy((prevState) => ({
        source: prevState?.source ?? "recruiter",
        reason: event.target.value,
        comment: prevState?.comment,
      }));
    },
    [setReasonWhy],
  );

  const updateComment = useCallback<ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>>(
    (event) => {
      setReasonWhy((prevState) => ({
        source: prevState?.source ?? "recruiter",
        reason: prevState?.reason ?? "",
        comment: event.target.value,
      }));
    },
    [setReasonWhy],
  );

  useEffect(() => {
    setFormValid(
      ((reasonWhy?.reason ?? "") !== "" &&
        !STATUSES_REASONS_WITH_MANDATORY_COMMENT.includes(reasonWhy?.reason ?? "")) ||
        ((reasonWhy?.comment ?? "").trim() !== "" && (reasonWhy?.reason ?? "") !== ""),
    );
  }, [reasonWhy?.comment, reasonWhy?.reason, setFormValid]);

  return (
    <Grid item xs={12} sx={{ display: "flex", flexDirection: "column", gap: "1rem" }}>
      {hasSource ? (
        <Grid item xs={12}>
          <Select
            renderValue={(selected) => t(`reasons.${pendingStatus}.modal.source.${selected}`)}
            value={reasonWhy?.source ?? "recruiter"}
            onChange={updateSource}
            required
            label={t(`reasons.${pendingStatus}.modal.source.label`)}
          >
            {STATUS_REASON_SOURCES.map((source) => (
              <MenuItem key={source} value={source}>
                {t(`reasons.${pendingStatus}.modal.source.${source}`)}
              </MenuItem>
            ))}
          </Select>
        </Grid>
      ) : null}
      <Grid item xs={12}>
        <Select
          renderValue={(selected) => (selected ? t(`reasons.${pendingStatus}.modal.reason.${selected}`) : "")}
          value={reasonWhy?.reason ?? ""}
          onChange={updateReason}
          required
          label={t("reasons.modal.reason.label")}
          placeholder={t("reasons.modal.reason.placeholder")}
        >
          {STATUS_VALIDATION_REASONS_PER_LABEL[pendingStatus].map((reason) => (
            <MenuItem key={reason} value={reason}>
              {t(`reasons.${pendingStatus}.modal.reason.${reason}`)}
            </MenuItem>
          ))}
        </Select>
      </Grid>
      <Grid item xs={12}>
        <TextField
          value={reasonWhy?.comment}
          onChange={updateComment}
          required={STATUSES_REASONS_WITH_MANDATORY_COMMENT.includes(reasonWhy?.reason ?? "")}
          label={t(
            STATUSES_REASONS_WITH_MANDATORY_COMMENT.includes(reasonWhy?.reason ?? "")
              ? "reasons.modal.comment.label.mandatory"
              : "reasons.modal.comment.label.optional",
          )}
          placeholder={t(`reasons.${pendingStatus}.modal.comment.placeholder`)}
          textFieldProps={{ multiline: true, rows: 2 }}
        />
      </Grid>
      {setSendSMS ? (
        <Grid item xs={12}>
          <Checkbox
            label={t("send sms.checkbox")}
            onChange={(event: ChangeEvent<HTMLInputElement>) => setSendSMS(event.target.checked)}
            checked={sendSMS ?? true}
          />
        </Grid>
      ) : null}
    </Grid>
  );
};

export const StatusSelectorLabel: FC<{ customLabel?: string }> = ({ customLabel }) => {
  const { t } = useTranslation("status-selector-field");
  loadTranslations("status-selector-field");

  return (
    <InputLabel
      id="status-selector-label"
      variant="outlined"
      sx={(theme) => ({
        height: "1.5rem",
        color: theme.palette.grey[600],
        display: "list-item",
        fontWeight: "normal",
        left: "1rem",
        top: "-10px",
        position: "absolute",
        background: "#FFFFFF",
        zIndex: 1,
      })}
    >
      {customLabel ?? t("status-selector-field:status")}
    </InputLabel>
  );
};
