import { Context } from "@opentelemetry/api";
import { AtsIntegration, AtsIntegrationUpsertion, SourcingCampaign, SourcingCampaignCreation } from "@typings";
import { withSpan, withTraceParent } from "@utils";

import { sourcingCampaignsAPI } from "./api";

const create = async (ctx: Context, body: SourcingCampaignCreation): Promise<void> =>
  withSpan(ctx, "CreateSourcingCampaign", async () => {
    if (body.launch_asap) {
      // make sure we don't set the start_date if we need to launch asap, as we don't
      // want to create confusion. We've used to do this in the form directly, but it's
      // not possible to have an empty string value for a date with MUI anymore.
      body.start_at = "";
    }

    const { data } = await sourcingCampaignsAPI.post("", body, withTraceParent());
    return data;
  });

const list = async (ctx: Context): Promise<Array<SourcingCampaign>> =>
  withSpan(ctx, "ListSourcingCampaigns", async () => {
    const { data } = await sourcingCampaignsAPI.get("", withTraceParent());
    return data;
  });

const get = async (ctx: Context, campaign_id: string): Promise<SourcingCampaign> =>
  withSpan(ctx, "GetSourcingCampaign", async () => {
    const { data } = await sourcingCampaignsAPI.get(`/${campaign_id}`, withTraceParent());
    return data;
  });

const upsertAtsIntegration = async (
  ctx: Context,
  campaign_id: string,
  payload: AtsIntegrationUpsertion,
): Promise<void> =>
  withSpan(ctx, "UpsertATSIntegration", async () => {
    const { data } = await sourcingCampaignsAPI.post(`/${campaign_id}/ats-integrations`, payload, withTraceParent());
    return data;
  });

const getAtsIntegration = async (ctx: Context, campaign_id: string): Promise<AtsIntegration> =>
  withSpan(ctx, "GetATSIntegration", async () => {
    const { data } = await sourcingCampaignsAPI.get(`/${campaign_id}/ats-integrations`, withTraceParent());
    return data;
  });

const deleteAtsIntegration = async (ctx: Context, campaign_id: string): Promise<void> =>
  withSpan(ctx, "DeleteATSIntegration", async () => {
    await sourcingCampaignsAPI.delete(`/${campaign_id}/ats-integrations`, withTraceParent());
  });

export const SourcingCampaignApi = {
  create,
  list,
  get,
  upsertAtsIntegration,
  getAtsIntegration,
  deleteAtsIntegration,
};
