import { useRouter } from "next/router";

import { FC, MouseEvent, useCallback, useMemo, useState } from "react";

import { MRT_Row } from "material-react-table";
import { useTranslation } from "next-i18next";

import { ArrowDropDown as ArrowDropDownIcon } from "@mui/icons-material";
import { Button, Menu, MenuItem, Typography } from "@mui/material";

import { ApplicationStatusUpdateModal, DeleteApplicationUpdateModal, ShareApplicationModal } from "@components";
import { loadTranslations } from "@lib";
import { ApplicationListItem } from "@typings";
import { Logger } from "@utils";

type Props = {
  row: MRT_Row<ApplicationListItem>;
  canDeleteApplication: boolean;
  disabled?: boolean;
};

export const RowActions: FC<Props> = ({ row, canDeleteApplication, disabled }) => {
  const { t } = useTranslation(["application-row-actions"]);
  loadTranslations("application-row-actions");

  const router = useRouter();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = useCallback((event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  }, []);
  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const [isChangeStatusModalOpen, setIsChangeStatusModalOpen] = useState(false);
  const [isDeleteApplicationModalOpen, setIsDeleteApplicationModalOpen] = useState(false);
  const [isShareApplicationModalOpen, setIsShareApplicationModalOpen] = useState(false);

  const handleStatusChange = useCallback(() => {
    setIsChangeStatusModalOpen(true);
    handleClose();
  }, [handleClose]);

  const handleDelete = useCallback(() => {
    setIsDeleteApplicationModalOpen(true);
    handleClose();
  }, [handleClose]);

  const handleShareApplication = useCallback(() => {
    setIsShareApplicationModalOpen(true);
    handleClose();
  }, [handleClose]);

  const handleOpenApplication = useCallback(() => {
    router.push(`/applications/${row.original.id}`).catch(Logger.error);
    handleClose();
  }, [handleClose, router, row.original.id]);

  const applications = useMemo(() => [row.original], [row.original]);

  return (
    <>
      <div>
        <Button
          id="actions-button"
          aria-controls={open ? "actions-button" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
          onClick={handleClick}
          endIcon={<ArrowDropDownIcon />}
          variant="contained"
          color="deepPurple"
          disabled={disabled}
          sx={{
            border: 0,
          }}
        >
          {t("label")}
        </Button>
        <Menu
          id="actions-button"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            "aria-labelledby": "actions-button",
          }}
        >
          <MenuItem onClick={handleOpenApplication}>
            <Typography variant="body" color={(theme) => theme.palette.text.mainInfo}>
              {t("open_application")}
            </Typography>
          </MenuItem>
          <MenuItem onClick={handleShareApplication}>
            <Typography variant="body" color={(theme) => theme.palette.text.mainInfo}>
              {t("email_share")}
            </Typography>
          </MenuItem>
          <MenuItem onClick={handleStatusChange}>
            <Typography variant="body" color={(theme) => theme.palette.text.mainInfo}>
              {t("change_status")}
            </Typography>
          </MenuItem>
          {canDeleteApplication && (
            <MenuItem onClick={handleDelete}>
              <Typography variant="body" color={(theme) => theme.palette.color.ALERT_RED[400]}>
                {t("delete_application")}
              </Typography>
            </MenuItem>
          )}
        </Menu>
      </div>
      {isShareApplicationModalOpen && (
        <ShareApplicationModal
          applicationID={row.original.id}
          open={isShareApplicationModalOpen}
          setOpen={setIsShareApplicationModalOpen}
        />
      )}
      {isChangeStatusModalOpen && (
        <ApplicationStatusUpdateModal
          open={isChangeStatusModalOpen}
          applicationID={row.original.id}
          campaignID={row.original.campaign_id}
          setOpen={setIsChangeStatusModalOpen}
          onSuccess={() => setIsChangeStatusModalOpen(false)}
        />
      )}

      {isDeleteApplicationModalOpen && (
        <DeleteApplicationUpdateModal
          applications={applications}
          open={isDeleteApplicationModalOpen}
          setOpen={setIsDeleteApplicationModalOpen}
          onSuccess={() => setIsDeleteApplicationModalOpen(false)}
        />
      )}
    </>
  );
};
