import moment from "moment-business-days";
import { TFunction } from "next-i18next";
import * as yup from "yup";

import { CreativeEnabled, HasAutomation } from "@typings";

export const getStepperValidationSchema = (t: TFunction) => [
  yup.object().shape({
    campaign_name: yup.string().required(t("general-information-validation:campaign_name_required")),

    // start_at is mixed because if launch_asap is checked, then the value should be null
    start_at: yup.mixed().when("launch_asap", {
      is: (launch_asap) => launch_asap === false,
      then: yup
        .date()
        // TypeError is required because it's possible that the value has been set to null before.
        .typeError(t("general-information-validation:start_at_required"))
        .required(t("general-information-validation:start_at_required"))
        .min(moment().businessAdd(4, "days"), t("general-information-validation:start_at_min")),
    }),
    campaign_duration: yup.string().required(t("general-information-validation:duration_required")),
    launch_asap: yup.bool(),
    budget_value: yup
      .number()
      // typeError is required for when the user is deleting the value (before replacing it).
      .typeError(t("general-information-validation:budget_greater"))
      .min(500, t("general-information-validation:budget_greater")),
    budget: yup.string(),
    currency: yup.string(),
    stakeholders: yup.array().of(yup.string().email()),
  }),
  yup.object().shape({
    campaign_objective: yup.string().required(t("general-information-validation:campaign_objective_required")),
  }),
  yup.object().shape({
    content_to_promote: yup.string().required(t("advertising-content-validation:content_to_promote_required")),
    creatives_check: yup.string().required(t("generic-validation:required")),
    creatives: yup.array().when("creatives_check", {
      is: (creatives_check) => creatives_check === CreativeEnabled.YES,
      then: yup.array().min(1, t("advertising-content-validation:creatives_required")),
    }),
    wordings: yup.string(),
  }),
  yup.object().shape({
    geolocation: yup.string().required(t("generic-validation:required")),
    expected_qualification: yup.string(),
    resume_required: yup.string().required(t("generic-validation:required")),
    additional_information: yup.string(),
  }),
  yup.object().shape({
    has_automation: yup
      .string()
      .oneOf(Object.values(HasAutomation), t("generic-validation:required"))
      .required(t("generic-validation:required")),
    automations: yup.object().when("has_automation", {
      is: (has_automation) => has_automation === HasAutomation.YES,
      then: yup
        .object()
        .test(
          "hasAtLeastOneSelected",
          t("automation-definition-validation:automations_at_least_one"),
          (obj) => Object.values(obj).reduce((a, item) => a + (item === true ? 1 : 0), 0) > 0,
        ),
    }),
    automation_customization: yup.bool(),
  }),
];
