import { FC } from "react";

import { MRT_TableInstance } from "material-react-table";
import { useTranslation } from "next-i18next";

import { NoResults } from "@components";
import { loadTranslations } from "@lib";

interface Props {
  onReset: () => void;
}

export const EmptyRowsFallback: FC<Props> = ({ onReset }) => {
  const { t } = useTranslation(["table"]);
  loadTranslations("table");

  return <NoResults label={t("no_results.label")} buttonLabel={t("no_results.button")} onClick={onReset} />;
};

export const renderEmptyRowsFallback = ({ table }: { table: MRT_TableInstance<any> }) => (
  <EmptyRowsFallback
    onReset={() => {
      table.resetColumnFilters();
      table.resetGlobalFilter();
    }}
  />
);
