import { styled } from "@mui/material/styles";

export const FileLineContainer = styled("div")`
  display: flex;
  align-items: center;
`;

export const IconContainer = styled("i")`
  cursor: pointer;
  font-size: 1.3em;
  padding-left: 1em;
`;
