import { FC, ReactNode } from "react";

import { useTranslation } from "next-i18next";
import { Controller, useFormContext } from "react-hook-form";

import {
  Box,
  Checkbox,
  Chip,
  CircularProgress,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  SelectProps,
} from "@mui/material";

import { loadTranslations } from "@lib";
import { ILabelValueItem } from "@typings";

export type FormSelectProps = SelectProps & {
  name: string;
  helperText?: ReactNode;
  options: ILabelValueItem[];
  size?: "small" | "medium";
  isLoading?: boolean;
  // when used the values in the array could not be selected or unselected (if selected by default)
  disabledValues?: Array<string | number>;
};

export const FormSelect: FC<FormSelectProps> = (props) => {
  const {
    control,
    formState: { errors },
  } = useFormContext();

  const { t } = useTranslation("common");
  loadTranslations("common");

  const {
    label,
    name,
    options,
    required,
    helperText,
    fullWidth,
    multiple,
    isLoading,
    disabledValues,
    placeholder,
    ...rest
  } = props;

  const errorMessage = errors[`${name}`]?.message;

  const isError = !!errorMessage;

  const renderValueFnForMultipleSelection = (selected) => {
    if ((selected || []).length === 0) {
      return <Box sx={(theme) => ({ color: theme.palette.grey[400] })}>{placeholder}</Box>;
    }

    const selectedLabels = options.reduce((result: ReactNode[], option) => {
      if ((selected || []).includes(option.value)) {
        result.push(option.label);
      }
      return result;
    }, []);

    return (
      <Box display="flex" flexWrap="wrap" gap={0.5}>
        {selectedLabels.map((label) => (
          <Chip key={label?.toString()} label={label} />
        ))}
      </Box>
    );
  };

  const renderValueFnForSimpleSelection = (selected) => {
    const option = options.find((item) => item.value === selected);

    if (option === undefined) {
      return <Box sx={(theme) => ({ color: theme.palette.grey[400] })}>{placeholder}</Box>;
    }
    return option.label;
  };

  return (
    <Controller
      control={control}
      name={name}
      render={({ field }) => (
        <FormControl fullWidth={fullWidth} error={isError}>
          <InputLabel sx={{ display: "list-item" }} shrink error={isError} htmlFor={name}>
            {label || ""}
            {label && !required && (
              <FormHelperText sx={{ display: "inline-block", marginLeft: "5px" }}>
                {`(${t("optional")})`}
              </FormHelperText>
            )}
            {helperText && <FormHelperText>{helperText}</FormHelperText>}
          </InputLabel>

          {isLoading ? (
            <CircularProgress />
          ) : (
            <Select
              id={name}
              {...field}
              {...rest}
              onChange={(e, child) => {
                field.onChange(e);
                props.onChange?.(e, child);
              }}
              renderValue={multiple ? renderValueFnForMultipleSelection : renderValueFnForSimpleSelection}
              displayEmpty
              required={required}
              multiple={multiple}
              error={isError}
              label=""
            >
              {options.map((item) => (
                <MenuItem key={item.value} value={item.value} disabled={disabledValues?.includes(item.value)}>
                  {multiple && <Checkbox readOnly checked={field.value.includes(item.value)} />}
                  {item.label}
                </MenuItem>
              ))}
            </Select>
          )}

          {isError && <FormHelperText error={isError}>{errorMessage as string}</FormHelperText>}
        </FormControl>
      )}
    />
  );
};
