import { FC } from "react";

import moment from "moment";
import { useTranslation } from "next-i18next";

import { MessageOutlined as MessageIcon } from "@mui/icons-material";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineDot from "@mui/lab/TimelineDot";
import TimelineItem from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import { Box } from "@mui/material";

import { TEXT_COLOR } from "@constants";
import { loadTranslations } from "@lib";
import { AriaProps, MessagingHistoryReminder } from "@typings";

import { CommentListSubInfo } from "./style";

interface Props extends AriaProps {
  messageHistory: MessagingHistoryReminder;
}

export const MessagesHistoryDetail: FC<Props> = ({ messageHistory, role }) => {
  const { t } = useTranslation(["messages-history-details", "dates"]);
  loadTranslations("messages-history-details");
  loadTranslations("dates");

  const plannedList: string[] | undefined = messageHistory.plannedList
    ?.sort((a, b) => (moment(a).isAfter(moment(b)) ? 1 : -1))
    .map((i) => moment(i).format("LLLL"));

  return (
    <TimelineItem role={role}>
      <TimelineSeparator>
        <TimelineDot
          sx={(theme) => ({
            width: "36px",
            height: "36px",
            backgroundColor: theme.palette.grey[400],
          })}
        >
          <MessageIcon
            sx={(theme) => ({
              width: "1.5rem",
              height: "1.5rem",
              backgroundColor: theme.palette.grey[400],
            })}
          />
        </TimelineDot>
        <TimelineConnector sx={(theme) => ({ color: theme.palette.grey[400] })} />
      </TimelineSeparator>
      <TimelineContent sx={{ position: "relative", top: "-18px !important" }}>
        <Box
          sx={{
            color: TEXT_COLOR.mainInfo,
            fontSize: "14px",
          }}
        >
          {messageHistory.userId && t("message_sent")}
          {plannedList &&
            (plannedList.length === 1
              ? t("reminder_prepared", { date: plannedList[0] })
              : t("reminders_prepared", { dates: plannedList }))}
        </Box>
        <CommentListSubInfo created_at={messageHistory.created_at} />
      </TimelineContent>
    </TimelineItem>
  );
};
