import { FC, ReactNode } from "react";

import { useTranslation } from "next-i18next";

import { Box, Chip, FormControl, FormHelperText, InputLabel, MenuItem, Select, Typography } from "@mui/material";

import { loadTranslations } from "@lib";
import { Status } from "@typings";
import { ApplicationCategoryToStyle, ensureArray } from "@utils";

interface StatusOptionProps {
  value: string;
  statuses: Status[];
}

const StatusOption: FC<StatusOptionProps> = ({ value, statuses }) => {
  const { t } = useTranslation(["application-status", "status-selector-field"]);
  loadTranslations("application-status");
  loadTranslations("status-selector-field");
  const status = statuses.filter((status) => status.id === value).pop();

  if (status) {
    return (
      <Chip
        label={t(`application-status:${status.label}`, { nsSeparator: ":" })}
        sx={{ ...ApplicationCategoryToStyle[status.category].sx, fontWeight: "bold" }}
      />
    );
  }

  return <></>;
};

type MultipleStatusesSelectorProps = {
  statuses: Status[];
  onSelectedStatusesChange: (newStatus) => void;
  value: string | string[];
  multiple?: boolean;
  label: string | ReactNode;
  description?: string | ReactNode;
  shrink?: boolean;
  notched?: boolean;
  error?: string;
};

/**
 * Creates a multiple statuses selector component.
 * @param MultipleStatusesSelectorProps
 * @returns
 */
export const MultipleStatusesSelector: FC<MultipleStatusesSelectorProps> = ({
  value,
  onSelectedStatusesChange,
  statuses,
  multiple,
  label,
  shrink,
  notched,
  description,
  error,
}) => {
  const { t } = useTranslation(["application-status", "status-selector-field"]);
  loadTranslations("application-status");
  loadTranslations("status-selector-field");

  return (
    <>
      {statuses && (
        <FormControl fullWidth>
          <InputLabel id="status-selector-label" variant="outlined" shrink={shrink}>
            {label}
          </InputLabel>
          <Select
            labelId="status-selector-label"
            id="status-selector"
            notched={notched}
            label={label}
            value={value}
            onChange={(event) => onSelectedStatusesChange(event.target.value)}
            error={!!error}
            multiple={multiple}
            {...(multiple && {
              renderValue: (selected) => (
                <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                  {(selected as string[]).map((value) => (
                    <StatusOption value={value} statuses={statuses} key={value} />
                  ))}
                </Box>
              ),
            })}
          >
            {ensureArray(statuses).map((status: Status) => (
              <MenuItem key={status.id} value={status.id}>
                <Chip
                  label={t(`application-status:${status.label}`, { nsSeparator: ":" })}
                  sx={{ ...ApplicationCategoryToStyle[status.category].sx, fontWeight: "bold" }}
                />
              </MenuItem>
            ))}
          </Select>
          {description && (
            <Typography variant="caption" color={(theme) => theme.palette.text.secondary}>
              {description}
            </Typography>
          )}
          {!!error && <FormHelperText error={!!error}>{error}</FormHelperText>}
        </FormControl>
      )}
    </>
  );
};
