import { FormRadioGroup } from "components/controls";

import { styled } from "@mui/material";

export const CardRadioGroup = styled(FormRadioGroup)(({ theme }) => ({
  flexWrap: "wrap",
  justifyContent: "center",
  gap: "2rem",
  ".MuiRadio-root": {
    display: "none",
  },
  ".MuiFormControlLabel-root": {
    borderRadius: 8,
    padding: "24px 16px",
    border: "0.5px solid",
    color: theme.palette.primary[900],
    textAlign: "center",
    display: "block",
    flex: "1 1 0px",
    margin: 0,
    "&:hover, &.checked": {
      color: theme.palette.primary[800],
    },
    "&.checked": {
      backgroundColor: theme.palette.primary[100],
    },
  },
}));
