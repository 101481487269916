import Head from "next/head";

import { FC, ReactNode } from "react";

import { Box } from "@mui/material";

import { CallInProgressAlert, TelnyxWrapper } from "@components";

import { CallBox } from "../application/application-interview-modals/call-box";

export const CampaignPageWrapper: FC<{ children: ReactNode; title: string }> = ({ children, title }) => {
  return (
    <Box padding="var(--space-24) 2rem 2rem 2rem">
      <Head>
        <title>{title}</title>
      </Head>
      {/* Display back to campaigns button */}
      <TelnyxWrapper>
        <CallInProgressAlert />
        <CallBox />
        {children}
      </TelnyxWrapper>
    </Box>
  );
};
