import { FC } from "react";

import moment from "moment";
import { useTranslation } from "next-i18next";

import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineItem from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import { Box, Grid } from "@mui/material";

import { AuthProxyAPI } from "@api";
import { QUERY_KEYS, TEXT_COLOR } from "@constants";
import { loadTranslations } from "@lib";
import { context } from "@opentelemetry/api";
import { useQuery } from "@tanstack/react-query";
import { AriaProps, RecruiterReminder } from "@typings";

import { CommentListSubInfo, CommentTextBox, TimelinePerson } from "./style";

interface RecruiterReminderProps extends AriaProps {
  reminder: RecruiterReminder;
}

export const RecruiterReminderDetail: FC<RecruiterReminderProps> = ({ reminder, role }) => {
  const { t } = useTranslation(["recruiter-reminder-detail", "dates"]);
  loadTranslations("recruiter-reminder-detail");
  loadTranslations("dates");

  const { data: user, isLoading } = useQuery({
    queryKey: [QUERY_KEYS.USERS, reminder.recruiter_id],
    queryFn: () => AuthProxyAPI.getUser(context.active(), reminder.recruiter_id),
  });

  return (
    <TimelineItem role={role}>
      <TimelineSeparator>
        <TimelinePerson userName={user ? `${user.first_name} ${user.last_name}` : undefined} userEmail={user?.email} />
        <TimelineConnector sx={(theme) => ({ color: theme.palette.grey[400] })} />
      </TimelineSeparator>
      <TimelineContent sx={{ position: "relative", top: "-18px !important" }}>
        {user && !isLoading && (
          <Box>
            <Box sx={{ color: TEXT_COLOR.mainInfo, fontSize: "14px" }}>
              <b>
                {user.first_name} {user.last_name}
              </b>{" "}
              {t("created_reminder")}
            </Box>
            <CommentTextBox
              context={
                <Grid container>
                  <Grid item xs={10}>
                    <Box sx={{ color: TEXT_COLOR.mainInfo, fontSize: "14px" }}>
                      <b>
                        {reminder.date.format(moment.locale().includes("en") ? "ddd D MMM YYYY" : "ddd DD MMM YYYY")}
                      </b>
                    </Box>
                  </Grid>
                  <Grid item xs={2} sx={{ alignItem: "end" }}>
                    <Box sx={{ color: TEXT_COLOR.mainInfo, fontSize: "14px" }}>
                      <b>{reminder.time.format("LT")}</b>
                    </Box>
                  </Grid>
                </Grid>
              }
            >
              {reminder.comment}
            </CommentTextBox>
            <Box sx={{ paddingTop: "0.25rem" }}>
              <CommentListSubInfo created_at={reminder.created_at} kind={t("kind")} />
            </Box>
          </Box>
        )}
      </TimelineContent>
    </TimelineItem>
  );
};
