/**
 * Checks if the current user has the correct permissions,
 * returns `true` if the array is empty
 * @param {string[]} userPermissions - The user permissions you which to check
 * @param {string[]} permissions - The permissions to check
 * @returns {boolean} `true` if the user has the permissions
 */
export const checkPermissions = (userPermissions: string[] | undefined, permissions: string[]): boolean => {
  if (!userPermissions || userPermissions.length === 0) {
    return false;
  }

  if (permissions.length > 0 && userPermissions) {
    return userPermissions.some((e) => permissions.includes(e));
  }

  return true;
};
