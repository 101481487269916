import { Dispatch, FC, SetStateAction } from "react";

import moment from "moment";
import { useTranslation } from "next-i18next";
import "react-multi-email/dist/style.css";

import { PersonAdd as PersonAddIcon } from "@mui/icons-material";
import { Divider, Grid, ThemeProvider, Typography, useTheme } from "@mui/material";

import { Modal } from "@work4Labs/design-system";

import { loadTranslations } from "@lib";
import { Interview } from "@typings";

type InterviewDetailsModalProps = {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;

  interview: Interview;
};

export const InterviewDetailsModal: FC<InterviewDetailsModalProps> = ({
  open,
  setOpen,

  interview,
}) => {
  const { t } = useTranslation(["details-interview-modal"]);
  loadTranslations("details-interview-modal");

  const theme = useTheme();

  const onCancel = () => {
    setOpen(false);
  };

  return (
    <Modal
      isOpen={open}
      aria-label="application-interview-modal-details"
      aria-describedby="application-interview-modal-details"
      scroll="body"
      modalTitle={t("detail_modal_title")}
      title={t("detail_title")}
      cancelText={t("close")}
      onClose={onCancel}
      modalIcon={<PersonAddIcon />}
    >
      <ThemeProvider theme={theme}>
        <>
          <Grid
            container
            sx={(theme) => ({
              padding: "1.5rem 2rem",
              backgroundColor: theme.palette.color.BASE[100],
            })}
          >
            <Grid item xs={12}>
              <Typography
                sx={(theme) => ({
                  color: theme.palette.primary.main,
                  fontSize: "24px",
                  fontWeight: 600,
                })}
              >
                {moment(interview.date).format("ll")}
                {" - "}
                {moment(interview.time_start, "HH:mm").format("LT")}
                {" - "}
                {moment(interview.time_end, "HH:mm").format("LT")}
              </Typography>
            </Grid>
            <Grid item xs={12} sx={{ paddingTop: "0.5rem" }}>
              {interview.location}
            </Grid>
            <Grid item xs={12} sx={{ paddingTop: "0.5rem" }}>
              {t("additional_information_summary", { info: interview.additional_information })}
            </Grid>

            <Divider sx={{ width: "100%", paddingTop: "0.5rem" }} />

            <Grid item xs={12} sx={{ paddingTop: "0.5rem" }}>
              <b>{t("stakeholders_summary", { stakeholders: interview.stakeholders.join(",") })}</b>
            </Grid>
          </Grid>
        </>
      </ThemeProvider>
    </Modal>
  );
};
