/**
 * Takes a string as a monetary value in the format "valuecurrency"
 * and returns the localized value
 * PS: if no locale is specified will use the browser locale
 */
export const BudgetFormatter = (v: string, locale?: string): string => {
  const [, value, currency] = String(v).split(/(\d+)/);
  try {
    return Number(value).toLocaleString(locale, {
      style: "currency",
      currencyDisplay: "code",
      currency: currency,
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  } catch (err) {
    return v;
  }
};

/**
 * Takes a string as a value and returns the localized value
 * PS: if no locale is specified will use the browser locale
 */
export const NumberFormatter = (v: string | number, locale?: string): string => {
  return Number(v).toLocaleString(locale, {
    maximumFractionDigits: 0,
  });
};
