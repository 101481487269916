export interface Organization {
  id: string;
  group_id: string;
  phone: string;
}

export interface ToggleFeatureParams {
  groupID: string;
  name: string;
}

export interface Feature {
  name: string;
}

export const ORGANIZATIONS_FEATURES: Record<string, string> = {
  CLICK_TO_CALL: "click-to-call",
  APPLICATION_STATUS_REASON: "application-status-reason",
  APPLICATIONS_DOCUMENTS: "applications-documents",
};
