import { cloneDeep } from "lodash";

import { Dict, FiltererItem } from "@typings";

// DataFilterer is a function that filter data matching a bunch of some filters.
// It also returns the number of available data for each filter value, depending on the selected filters.
// inputs:
//   - allData is the complete list of data that we have (don't pass a previously filtered list)
//   - allFilters are all the possible filters (and all possible values) that are available to the user.
//     more precisely, we expect the first level keys to match data fields (like status or job_title for an application)
//     for instance. And the second level keys to be the possible values of the filter, (like open or closed for a status)
//     and the value of each key to be 0.
//   - activeFilters are the current active filters. It works the same way as `allFilters`, i.e first level are data
//     fields and second level are values. To filter, the second key value has to be set to true. Contrary to allFilters
//     you don't need to pass all the possible values to this, just the one that are true.
//
// output: is an object of two keys
//   - matchingData: which are the data matching the `activeFilters`.
//     More precisely, to match, all data fields being filtered have to have a value equal to a possible value in the filter.
//     i.e for this filter `{ status: {open: true, closed: true}, title: { mechanic: true } }`, all data with
//     a status `open` OR `closed` will match AND a title `mechanic` will match.
//   - filterWithNewCounts: is a copy of `allFilters`, but with updated values; set to the number of matching data matching
//     the other filters. `other filters` is important here.
//     So if you have`{ status: {open: true, closed: true}, title: { mechanic: true } }` as your active filters, we'll
//     increment the count of `open` or `closed` (or any other status) data ONLY IF they have the `mechanic` title.
//     respectively, we'll increment the count of mechanic (or any other title) data
//     ONLY IF they have an `open` or `closed` status. It's quite specific, but it's the behavior that the products wants.
//
// total complexity is nb data * nb filters (no matter how much values a filter has.).
// in practice, the less matching values we have, the faster it gets.
export function DataFilterer<T>(
  allData: Array<T>,
  allFilters: Dict<Dict<FiltererItem>>,
  activeFilters: Dict<Dict<boolean>>,
): { matchingData: Array<T>; filterWithNewCounts: Dict<Dict<FiltererItem>> } {
  const matchingData: Array<T> = [];
  const filterWithNewCounts = cloneDeep(allFilters);

  if (allData === undefined) {
    return {
      matchingData,
      filterWithNewCounts,
    };
  }

  // first compute data matching all the selected filters.
  // for each filter, the data value has to be in the selected filter.
  // a data just has to be in the possible value for one filter. However, it has to be the case for all filters.
  for (const data of allData) {
    // match is there to optimize loops and continue as early as possible
    let match = true;

    for (const filterKey in activeFilters) {
      // test if the data[filterKey] is in the filters
      const fieldValue = data[filterKey];
      if (activeFilters[filterKey][fieldValue] !== true) {
        match = false;
        break;
      }
    }

    if (match === true) {
      matchingData.push(data);
    }
  }

  // compute the filter counts, based on the other filter.
  // so for each filter, we take all the data that match the other filter, and count that.
  for (const filterKey in filterWithNewCounts) {
    for (const data of allData) {
      let match = true;

      // check if the data match the other filters
      for (const other_filterKey in activeFilters) {
        if (filterKey === other_filterKey) {
          continue;
        }

        const otherFieldValue = data[other_filterKey];
        if (activeFilters[other_filterKey][otherFieldValue] !== true) {
          match = false;
          break;
        }
      }

      if (match === true) {
        const fieldValue = data[filterKey];
        filterWithNewCounts[filterKey][fieldValue]["count"] += 1;
      }
    }
  }

  return { matchingData, filterWithNewCounts };
}
