export const MRT_Localization_FR = {
  actions: "Actions",
  and: "et",
  cancel: "Annuler",
  changeFilterMode: "Changer le mode de filtrage",
  changeSearchMode: "Changer le mode de recherche",
  clearFilter: "Effacer le filtre",
  clearSearch: "Effacer la recherche",
  clearSort: "Retirer le tri",
  clickToCopy: "Cliquer pour copier",
  columnActions: "Actions de la colonne",
  copiedToClipboard: "Copié dans le presse-papier",
  dropToGroupBy: "Drop to group by {column}",
  edit: "Éditer",
  expand: "Développer",
  expandAll: "Tout développer",
  filterArrIncludes: "Inclut",
  filterArrIncludesAll: "Tout inclure",
  filterArrIncludesSome: "Inclut",
  filterBetween: "Entre",
  filterBetweenInclusive: "Between Inclusive",
  filterByColumn: "Filtrer par {column}",
  filterContains: "Contient",
  filterEmpty: "Vide",
  filterEndsWith: "Finit par",
  filterEquals: "Égal à",
  filterEqualsString: "Égal à",
  filterFuzzy: "Fuzzy",
  filterGreaterThan: "Supérieur à",
  filterGreaterThanOrEqualTo: "Supérieur ou égal à",
  filterInNumberRange: "Entre",
  filterIncludesString: "Contient",
  filterIncludesStringSensitive: "Contient",
  filterLessThan: "Inférieur à",
  filterLessThanOrEqualTo: "Inférieur ou égal à",
  filterMode: "Mode de filtrage: {filterType}",
  filterNotEmpty: "Non vide",
  filterNotEquals: "Différent de",
  filterStartsWith: "Commence par",
  filterWeakEquals: "Égal à",
  filteringByColumn: "Filtrer par {column} - {filterType} {filterValue}",
  goToFirstPage: "Aller à la première page",
  goToLastPage: "Aller à la dernière page",
  goToNextPage: "Aller à la page suivante",
  goToPreviousPage: "Aller à la page précédente",
  grab: "Agripper",
  groupByColumn: "Grouper par {column}",
  groupedBy: "Groupé par",
  hideAll: "Tout Masquer",
  hideColumn: "Masquer la colonne {column}",
  max: "Max",
  min: "Min",
  move: "Déplacer",
  noRecordsToDisplay: "Aucun résultat à afficher",
  noResultsFound: "Aucun résultat trouvé",
  of: "de",
  or: "ou",
  pinToLeft: "Épingler à gauche",
  pinToRight: "Épingler à droite",
  resetColumnSize: "Réinitialiser la taille des colomnes",
  resetOrder: "Réinitialiser l'ordre",
  rowActions: "Actions sur les lignes",
  rowNumber: "#",
  rowNumbers: "Numéros de ligne",
  rowsPerPage: "Lignes par page",
  save: "Enregistrer",
  search: "Rechercher",
  selectedCountOfRowCountRowsSelected: "{selectedCount} ligne(s) sélectionnée(s) sur {rowCount}",
  select: "Sélectionner",
  showAll: "Tout afficher",
  showAllColumns: "Afficher toutes les colonnes",
  showHideColumns: "Afficher/masquer les colonnes",
  showHideFilters: "Afficher/masquer les filtres",
  showHideSearch: "Afficher/masquer la recherche",
  sortByColumnAsc: "Trier par {column} croissant",
  sortByColumnDesc: "Trier par {column} décroissant",
  sortedByColumnAsc: "Trié par {column} croissant",
  sortedByColumnDesc: "Trié par {column} décroissant",
  thenBy: ", puis par ",
  toggleDensity: "Changer la densité",
  toggleFullScreen: "Activer le plein écran",
  toggleSelectAll: "Tout sélectionner",
  toggleSelectRow: "Sélectionner la ligne",
  toggleVisibility: "Changer la visibilité",
  ungroupByColumn: "Dissocier par {column}",
  unpin: "Détacher",
  unpinAll: "Tout détacher",
  unsorted: "Non trié",
};
