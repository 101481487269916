import { AxiosRequestConfig } from "axios";

import { Context, Span, context, propagation, trace } from "@opentelemetry/api";
import { ZoneContextManager } from "@opentelemetry/context-zone";
import { OTLPTraceExporter } from "@opentelemetry/exporter-trace-otlp-proto";
import { Resource } from "@opentelemetry/resources";
import { BatchSpanProcessor, WebTracerProvider } from "@opentelemetry/sdk-trace-web";
import { ATTR_SERVICE_NAME, ATTR_SERVICE_VERSION } from "@opentelemetry/semantic-conventions";
import { Logger } from "@utils";

export const InitOTEL = () => {
  const resource = Resource.default().merge(
    new Resource({
      [ATTR_SERVICE_NAME]: process.env.NEXT_PUBLIC_OTEL_EXPORTER_SERVICE_NAME as string,
      [ATTR_SERVICE_VERSION]: "0.1.0",
    }),
  );

  const collectorOptions = {
    url: process.env.NEXT_PUBLIC_OTEL_EXPORTER_TRACER_URL as string, // an optional url to send the spans to
    headers: {
      authorization: process.env.NEXT_PUBLIC_OTEL_EXPORTER_AUTHORIZATION as string,
      "stream-name": process.env.NEXT_PUBLIC_OTEL_EXPORTER_STREAM_NAME as string,
    }, // an optional object containing custom headers to be sent with each request
  };

  const PROVIDER = new WebTracerProvider({
    resource: resource,
  });

  const EXPORTER = new OTLPTraceExporter(collectorOptions);
  PROVIDER.addSpanProcessor(
    new BatchSpanProcessor(EXPORTER, {
      // The maximum queue size. After the size is reached spans are dropped.
      maxQueueSize: 100,
      // The maximum batch size of every export. It must be smaller or equal to maxQueueSize.
      maxExportBatchSize: 10,
      // The interval between two consecutive exports
      scheduledDelayMillis: 500,
      // How long the export can run before it is cancelled
      exportTimeoutMillis: 30000,
    }),
  );

  const contextManager = new ZoneContextManager();
  contextManager.enable();
  PROVIDER.register({ contextManager });
};

/**
 *
 * ---------------------------------------- TODO ------------------------
 *
 */

interface Carrier {
  traceparent?: string;
  tracestate?: string;
}

export interface SpanAndContext {
  ctx: Context;
  span: Span;
}

// Start a context that will be shared within the childs (new span created after)
export const startSpan = (ctx: Context, name: string): SpanAndContext => {
  const tracer = trace.getTracer("platform-ui", "0.1.0");
  const span = tracer.startSpan(name);
  ctx = trace.setSpan(ctx, span);

  return { ctx, span };
};

// Get the trace parent (span id) from the context (a span that was started before this one)
export const getTraceParent = () => {
  const output: Carrier = {};
  propagation.inject(context.active(), output);
  const { traceparent } = output;
  return traceparent;
};

export const withTraceParent = (config: AxiosRequestConfig = {}) => ({
  ...config,
  headers: {
    ...config.headers,
    traceparent: getTraceParent(),
  },
});

// withSpan run the fn function wrapped with a span.
// It will leverage the parent on it's own. When the function is over, the span will end too and will be sent to OO
// with the name you've specified
export const withSpan = async (ctx: Context, name: string, fn: () => Promise<any>) => {
  return await context.with(ctx, async () => {
    const tracer = trace.getTracer("platform-ui", "0.1.0");

    return await tracer.startActiveSpan(name, async (span: Span) => {
      const res = await fn();

      try {
        span.end();
      } catch (e) {
        Logger.error(e);
      }

      return res;
    });
  });
};
