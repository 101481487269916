import { FC } from "react";

import { Trans, useTranslation } from "next-i18next";

import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineItem from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import { Box } from "@mui/material";

import { InterviewCard } from "@components";
import { COLOR_PALETTE, TEXT_COLOR } from "@constants";
import { loadTranslations } from "@lib";
import { AriaProps, InterviewWasDone } from "@typings";

import { CommentListSubInfo, TimelinePerson } from "./style";

interface InterviewDoneDetailProps extends AriaProps {
  interviewDone: InterviewWasDone;
}

export const InterviewDoneDetail: FC<InterviewDoneDetailProps> = ({ interviewDone, role }) => {
  const { t } = useTranslation(["interview-done-details", "dates"]);
  loadTranslations("interview-done-details");
  loadTranslations("dates");

  const interview = interviewDone.interview;

  return (
    <TimelineItem role={role}>
      <TimelineSeparator>
        <TimelinePerson />
        <TimelineConnector sx={{ color: COLOR_PALETTE.BASE[400] }} />
      </TimelineSeparator>
      <TimelineContent sx={{ position: "relative", top: "-18px !important" }}>
        <Box>
          <Box
            sx={{
              color: TEXT_COLOR.mainInfo,
              fontSize: "14px",
            }}
          >
            <Trans
              t={t}
              i18nKey="interview_is_done"
              // eslint-disable-next-line react/jsx-key
              components={[<strong />]}
            />
          </Box>
          <InterviewCard interview={interview} />
          <CommentListSubInfo created_at={interview.getInterviewEndDate()} />
        </Box>
      </TimelineContent>
    </TimelineItem>
  );
};
