import { useSession } from "next-auth/react";

import { Box } from "@mui/material";

import { Greetings, MyApplications, MyJobs, Spinner } from "@components";

import { useAssignedJobs } from "@hooks/queries";

export const Homepage = () => {
  const { data: session } = useSession();

  const assignedJobsQuery = useAssignedJobs(session?.user.id);

  if (assignedJobsQuery.isLoading) {
    return <Spinner style={{ position: "relative" }} />;
  }

  if (assignedJobsQuery.isError || !assignedJobsQuery.isSuccess) {
    throw assignedJobsQuery.error;
  }

  return (
    <Box
      display="flex"
      padding={(theme) => theme.spacings[48]}
      gap={(theme) => theme.spacings[48]}
      flexDirection="column"
    >
      <Greetings />
      <MyApplications assignedJobs={assignedJobsQuery.data} />
      <MyJobs assignedJobs={assignedJobsQuery.data} />
    </Box>
  );
};
