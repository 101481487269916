import { ComponentProps, FC, useCallback } from "react";

import { useTranslation } from "next-i18next";
import { toast } from "react-toastify";

import { CopyAll as CopyAllIcon } from "@mui/icons-material";
import { Box, IconButton, Theme } from "@mui/material";
import { SystemStyleObject } from "@mui/system";

import { WithMergeableTheme, withThemes } from "@work4Labs/design-system";

import { loadTranslations } from "@lib";

const EMAIL_SX: SystemStyleObject<Theme> = {
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  gap: "4px",
};

export const EmailBox: FC<
  WithMergeableTheme<Omit<ComponentProps<typeof Box>, "children"> & { children: string | null }>
> = ({ children, sx, ...props }) => {
  const { t } = useTranslation(["common"]);
  loadTranslations("common");

  const copyToClipboard = useCallback(() => {
    if (!children) return;

    navigator.clipboard
      .writeText(children)
      .then(() => {
        toast.success(t("common:copy.email.success"));
      })
      .catch(() => {
        toast.error(t("common:copy.email.error"));
      });
  }, [children, t]);

  return (
    <Box sx={withThemes(EMAIL_SX, sx)} {...props}>
      {children}
      <IconButton
        sx={{ fontSize: "inherit", padding: "4px" }}
        onClick={copyToClipboard}
        disabled={children == null}
        aria-label="copy-email"
      >
        <CopyAllIcon fontSize="inherit" sx={{ height: "1.2em", width: "1.2em" }} />
      </IconButton>
    </Box>
  );
};
