import { ILabelValueItem } from "@typings";

export enum Currency {
  EUR = "EUR",
  USD = "USD",
}

export const CURRENCY_OPTIONS: ILabelValueItem<string, Currency>[] = [
  {
    label: Currency.EUR,
    value: Currency.EUR,
  },
  {
    label: Currency.USD,
    value: Currency.USD,
  },
];
