import React, { FC, ReactElement, useCallback, useState } from "react";

import { RequestDocumentModal } from "@components";

interface Props {
  applicationID: string;
  render: ({ onClick }: { onClick: () => void }) => ReactElement;
}

export const RequestDocumentAction: FC<Props> = ({ applicationID, render }) => {
  const [requestDocumentsModalOpen, setRequestDocumentsModalOpen] = useState(false);

  const handleClick = () => {
    setRequestDocumentsModalOpen(true);
  };
  const closeModal = useCallback(() => setRequestDocumentsModalOpen(false), [setRequestDocumentsModalOpen]);

  return (
    <>
      {render({
        onClick: handleClick,
      })}

      <RequestDocumentModal open={requestDocumentsModalOpen} closeModal={closeModal} applicationID={applicationID} />
    </>
  );
};
