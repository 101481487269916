import { FC } from "react";

import { Trans, useTranslation } from "next-i18next";
import "react-multi-email/dist/style.css";

import { Divider, Grid, Typography } from "@mui/material";

import { InfoBox } from "@work4Labs/design-system";

import { InterviewCreation } from "@api";
import { loadTranslations } from "@lib";

type ConfirmApplicationInterviewProps = {
  formValues: InterviewCreation;
  nbApplicationWithInterview: number;
};

export const ConfirmApplicationInterview: FC<ConfirmApplicationInterviewProps> = ({
  formValues,
  nbApplicationWithInterview,
}) => {
  const { t } = useTranslation(["create-interview-modal", "details-interview-modal"]);
  loadTranslations("create-interview-modal");
  loadTranslations("details-interview-modal");

  return (
    <>
      <Typography
        sx={(theme) => ({
          color: theme.palette.grey[900],
          lineHeight: "1.5rem",
          fontSize: "1rem",
          fontWeight: 550,
        })}
      >
        {t("sub_title_confirmation")}
      </Typography>

      {nbApplicationWithInterview > 0 && (
        <div style={{ marginBottom: "1rem" }}>
          <InfoBox title={""} level={"warning"}>
            <Trans
              t={t}
              i18nKey="applications_with_interview_warning_box"
              values={{ count: nbApplicationWithInterview }}
            />
          </InfoBox>
        </div>
      )}

      <Grid
        container
        sx={(theme) => ({
          marginTop: "1rem",
          padding: "1.5rem 2rem",
          backgroundColor: theme.palette.color.BASE[100],
        })}
      >
        <Grid item xs={12}>
          <Typography
            sx={(theme) => ({
              color: theme.palette.primary.main,
              fontSize: "24px",
              fontWeight: 600,
            })}
          >
            {formValues.date.format("ll")}
            {" - "}
            {formValues.time_start.format("LT")}
            {" - "}
            {formValues.time_end.format("LT")}
          </Typography>
        </Grid>

        <Grid item xs={12} sx={{ paddingTop: "0.5rem" }}>
          {formValues.location}
        </Grid>

        {formValues.additional_information && (
          <Grid item xs={12} sx={{ paddingTop: "0.5rem" }}>
            {t("additional_information_summary", {
              info: formValues.additional_information,
              ns: "details-interview-modal",
            })}
          </Grid>
        )}

        {formValues.stakeholders?.length > 0 && (
          <>
            <Divider sx={{ width: "100%", paddingTop: "0.5rem" }} />

            <Grid item xs={12} sx={{ paddingTop: "0.5rem" }}>
              <b>
                {t("stakeholders_summary", {
                  stakeholders: formValues.stakeholders.join(","),
                  ns: "details-interview-modal",
                })}
              </b>
            </Grid>
          </>
        )}
      </Grid>

      <Grid container>
        <Grid item xs={12} sx={{ paddingTop: "1.5rem" }}>
          {t("reminders_help_text")}
        </Grid>
      </Grid>
    </>
  );
};
