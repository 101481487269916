import { FC, ReactNode, useState } from "react";

import { useTranslation } from "next-i18next";

import { Box, DialogContent, DialogContentText, ThemeProvider, useTheme } from "@mui/material";

import { Modal } from "@work4Labs/design-system";

import { loadTranslations } from "@lib";

type UseConfirmParams = {
  title?: string;
  message?: string;
  modalTitle?: string;
};

export const useConfirm = ({
  title,
  message,
  modalTitle,
}: UseConfirmParams): [FC<{ children?: ReactNode }>, () => Promise<boolean>] => {
  const { t } = useTranslation(["use-confirm"]);
  loadTranslations("use-confirm");

  const theme = useTheme();

  const [promise, setPromise] = useState<{ resolve: (value: boolean) => void } | undefined>(undefined);

  const confirm = () =>
    new Promise<boolean>((resolve) => {
      setPromise({ resolve });
    });

  const handleClose = () => {
    setPromise(undefined);
  };

  const handleConfirm = () => {
    promise?.resolve(true);
    handleClose();
  };

  const handleCancel = () => {
    promise?.resolve(false);
    handleClose();
  };

  const ConfirmationDialog: FC<{ children?: ReactNode }> = ({ children }) => {
    return (
      <Modal
        isOpen={promise !== undefined}
        aria-label="interview-modal-configuration"
        aria-describedby="interview-modal-configuration"
        scroll="body"
        title={title}
        modalTitle={modalTitle}
        confirmText={t("confirm")}
        cancelText={t("cancel")}
        onConfirm={handleConfirm}
        onClose={handleCancel}
      >
        <ThemeProvider theme={theme}>
          <Box p="1.5rem">
            {children ?? (
              <>
                <DialogContent>
                  <DialogContentText>{message}</DialogContentText>
                </DialogContent>
              </>
            )}
          </Box>
        </ThemeProvider>
      </Modal>
    );
  };

  return [ConfirmationDialog, confirm];
};
