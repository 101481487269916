import { useCallback, useEffect, useMemo } from "react";

import { orderBy } from "lodash";
import { MRT_TableInstance } from "material-react-table";
import { useTranslation } from "next-i18next";

import { ManagedMaterialReactTableCategory } from "@components";
import { APPLICATION_STATUSES } from "@constants";
import { loadTranslations } from "@lib";
import { ApplicationListItem } from "@typings";

import { useApplicationStatuses } from "@hooks/queries";

import { APPLICATION_STATUSES_FILTER } from "../constants";

export const useLeadsCategories = (
  applications: ApplicationListItem[],
  table: MRT_TableInstance<ApplicationListItem>,
) => {
  const { t } = useTranslation(["application-status"]);
  loadTranslations("application-status");

  const { getColumn, setColumnFilters } = table;

  const { data: statuses } = useApplicationStatuses({ select: (result) => orderBy(result, ["rank"]) });

  const column = getColumn("status");
  const currentStatusFilter = column.getFilterValue();
  const statusFacetedValues = column.getFacetedUniqueValues();

  useEffect(() => {
    if (statuses == null) {
      return;
    }

    setColumnFilters((prevState) => {
      const statusFilter = prevState?.find((filter) => filter.id === "status");
      if (statusFilter) {
        return [...prevState];
      }

      return [...(prevState ?? []), { id: "status", value: APPLICATION_STATUSES.NEW }];
    });
  }, [setColumnFilters, statuses]);

  const getStatusCount = useCallback(
    (status) => {
      if (status === "all") {
        return Array.from(statusFacetedValues.values()).reduce((acc, value) => acc + value, 0);
      }
      return statusFacetedValues.get(status) || 0;
    },
    [statusFacetedValues],
  );

  const categories = useMemo<ManagedMaterialReactTableCategory[]>(
    () => [
      {
        value: "all",
        id: "status_card_all",
        selected: Array.isArray(currentStatusFilter) && currentStatusFilter.includes("all"),
        icon: APPLICATION_STATUSES_FILTER["all"].icon,
        displayValue: t(APPLICATION_STATUSES_FILTER["all"].label, { ns: "application-status" }),
        count: getStatusCount("all"),
      },
      ...(statuses?.map((status) => ({
        value: status.label,
        id: `status_card_${status.label}`,
        selected: Array.isArray(currentStatusFilter) && currentStatusFilter.includes(status.label),
        icon: APPLICATION_STATUSES_FILTER[status.label].icon,
        displayValue: t(APPLICATION_STATUSES_FILTER[status.label].label, { ns: "application-status" }),
        count: getStatusCount(status.label),
      })) ?? []),
    ],
    [currentStatusFilter, getStatusCount, statuses, t],
  );

  const onCategoryChange = useCallback(
    (category: string) => {
      setColumnFilters((prevState) => {
        const newFilters = (prevState ?? []).filter((filter) => filter.id !== "status");
        newFilters.push({ id: "status", value: category });
        return newFilters;
      });
    },
    [setColumnFilters],
  );

  return { categories, onCategoryChange };
};
