import { useTranslation } from "next-i18next";

import { Stack, Typography } from "@mui/material";

import { loadTranslations } from "@lib";

import { SettingsPageForm } from "../components";
import { AccountParametersForm, PasswordForm, PersonalInformationForm, PhoneForm } from "./forms";

const AccountSettingsTab = () => {
  const { t } = useTranslation(["profile-settings"]);
  loadTranslations("profile-settings");

  return (
    <Stack direction="column" gap="var(--size-48)" maxWidth="100%" alignItems="stretch">
      {/* Personal information */}
      <SettingsPageForm title={t("profile-settings:account.personalInformation.title")}>
        <PersonalInformationForm />
      </SettingsPageForm>

      {/* Phone number */}
      <SettingsPageForm title={t("profile-settings:account.phoneNumber.title")}>
        <PhoneForm />
      </SettingsPageForm>

      {/* Account parameters */}
      <SettingsPageForm title={t("profile-settings:account.accountParameters.title")}>
        <AccountParametersForm />
      </SettingsPageForm>

      {/* Password */}
      <SettingsPageForm title={t("profile-settings:account.password.title")}>
        <PasswordForm />
      </SettingsPageForm>
    </Stack>
  );
};

export const PersonalSettingsPage = () => {
  const { t } = useTranslation(["profile-settings"]);
  loadTranslations("profile-settings");

  return (
    <Stack padding="var(--space-48)" gap="var(--size-48)" direction="column">
      <Typography
        color="var(--color-palette-base-800)"
        fontWeight={800}
        fontSize="var(--font-size-200)"
        lineHeight="120%"
      >
        {t("profile-settings:title")}
      </Typography>

      <AccountSettingsTab />
    </Stack>
  );
};
