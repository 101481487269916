import { Dispatch, FC, SetStateAction, useCallback, useState } from "react";

import { AxiosError } from "axios";
import { useTranslation } from "next-i18next";
import { ReactMultiEmail } from "react-multi-email";
import "react-multi-email/dist/style.css";
import { toast } from "react-toastify";

import { MailOutline } from "@mui/icons-material";
import { Box, Button, ThemeProvider, Typography, useTheme } from "@mui/material";

import { Modal } from "@work4Labs/design-system";

import { ApplicationApi, ShareApplicationParams } from "@api";
import { loadTranslations } from "@lib";
import { context } from "@opentelemetry/api";
import { useMutation } from "@tanstack/react-query";

interface Props {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  applicationID: string;
}

const modalLabel = (email: string, index: number, removeEmail: (index: number) => void) => (
  <div data-tag={true} key={index}>
    {email}
    <button data-span={true} data-tag-handle={true} onClick={() => removeEmail(index)}>
      ×
    </button>
  </div>
);

export const ShareApplicationModal: FC<Props> = ({ applicationID, open, setOpen }) => {
  const { t } = useTranslation(["application-details"]);
  loadTranslations("application-details");

  const theme = useTheme();

  const [values, setValues] = useState<Array<string>>([]);

  const shareApplicationMutation = useMutation<void, AxiosError, ShareApplicationParams>({
    mutationFn: (params) => ApplicationApi.shareApplication(context.active(), params),

    onSuccess: () => {
      toast.success(t("share_success"));
      setValues([]);
    },

    onError: () => {
      toast.error(t("share_error"));
    },
  });

  const handleConfirm = useCallback(() => {
    setOpen(false);

    const payload: ShareApplicationParams = {
      application_id: applicationID,
      body: {
        emails: values,
      },
    };

    shareApplicationMutation.mutate(payload);
  }, [applicationID, setOpen, shareApplicationMutation, values]);

  const customActions = (onConfirm: (() => void) | undefined, onClose: (() => void) | undefined) => {
    const buttonStyle = {
      fontSize: "1rem",
      fontWeight: "600",
      textTransform: "none",
      borderRadius: "8px",
    };
    return (
      <>
        <Button
          color="secondary"
          onClick={() => {
            onClose?.();
          }}
          sx={{
            ...buttonStyle,
            color: theme.palette.primary.main,
            backgroundColor: "white",
            border: `1px solid ${theme.palette.primary.main}`,
          }}
        >
          {t("cancel")}
        </Button>
        <Button
          onClick={() => {
            onConfirm?.();
          }}
          sx={{
            ...buttonStyle,
            color: "white",
            backgroundColor: theme.palette.color.deepPurple,
            "&:hover": {
              backgroundColor: theme.palette.color.deepPurple,
            },
          }}
        >
          {t("send")}
        </Button>
      </>
    );
  };

  return (
    <Modal
      isOpen={open}
      modalTitle={t("share_profile")}
      modalIcon={<MailOutline />}
      onClose={() => {
        setOpen(false);
      }}
      onConfirm={handleConfirm}
      options={{ confirmProps: { disabled: values.length === 0 } }}
      customActions={customActions}
    >
      <ThemeProvider theme={theme}>
        <Box>
          <Typography
            sx={{
              fontSize: 14,
              color: "var(--text-color-low-info)",
            }}
          >
            {t("share_info")}
          </Typography>
          <ReactMultiEmail
            style={{
              paddingBottom: "8px",
            }}
            placeholder={t("email_example")}
            emails={values}
            onChange={(emails: string[]) => {
              setValues(emails);
            }}
            getLabel={modalLabel}
          />
        </Box>
      </ThemeProvider>
    </Modal>
  );
};
