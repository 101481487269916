import { useCallback, useMemo } from "react";

import { cloneDeep, uniq } from "lodash";

import { ApplicationApi, AuthProxyAPI, JobsApi, SearchJobsParams } from "@api";
import { QUERY_KEYS } from "@constants";
import { Context, context } from "@opentelemetry/api";
import { QueryClient, UseQueryResult, useQuery } from "@tanstack/react-query";
import { Application, ApplicationListItem, Candidate, CandidatesObject } from "@typings";
import { Logger } from "@utils";

const useParseApplicationsList = (
  applicationsQuery: UseQueryResult<Application[], unknown>,
  searchJobsParams: SearchJobsParams,
) => {
  // Filter unique candidates IDs.
  const candidateIDs = useMemo<string[]>(
    () => uniq(applicationsQuery.data?.map((application) => application.candidate_id)),
    [applicationsQuery.data],
  );

  const candidatesSelect = useCallback(
    (candidates: Candidate[]): CandidatesObject =>
      candidates.reduce((acc, candidate) => {
        if (candidate.id) {
          acc[candidate.id] = candidate;
        }
        return acc;
      }, {} as CandidatesObject),
    [],
  );

  const candidatesQuery = useQuery({
    queryKey: [QUERY_KEYS.APPLICATIONS_CANDIDATES, candidateIDs],
    queryFn: () => AuthProxyAPI.searchCandidates(context.active(), candidateIDs),
    enabled: candidateIDs.length > 0,
    select: candidatesSelect,
  });

  const jobsQuery = useQuery({
    queryKey: [QUERY_KEYS.JOBS, searchJobsParams],
    queryFn: () => JobsApi.search(context.active(), searchJobsParams),
    select: (jobs) => jobs.reduce((res, job) => {
      res[job.id] = job;
      return res;
    }, {}),
    enabled: applicationsQuery.isSuccess && (applicationsQuery.data?.length || 0) > 0,
  });

  const applications = useMemo((): ApplicationListItem[] => {
    return (applicationsQuery.data ?? []).map((application) => ({
      id: application.id,
      organization_name: application.organization_name,
      campaign_id: application.campaign_id,
      status: application.status,
      status_reason: application.status_reason,
      last_interaction_date: application.last_interaction_date,
      created_at: application.created_at,
      last_opened_at: application.last_opened_at,
      candidate: candidatesQuery.data?.[application.candidate_id],
      job: jobsQuery.data?.[application.job_id],
      answers: application.answers,
    }));
  }, [applicationsQuery.data, candidatesQuery.data, jobsQuery.data]);

  return {
    applications,
    isPending: applicationsQuery.isPending || candidatesQuery.isPending || jobsQuery.isPending,
    isLoading: applicationsQuery.isLoading || candidatesQuery.isLoading || jobsQuery.isLoading,
    isSuccess: applicationsQuery.isSuccess && candidatesQuery.isSuccess,
  };
};

export const useFetchCampaignApplicationsList = (
  ctx: Context,
  organizationName: string,
  campaignID: string | undefined,
) => {
  const applicationsQuery = useQuery<Application[], unknown>({
    // eslint-disable-next-line @tanstack/query/exhaustive-deps
    queryKey: [QUERY_KEYS.APPLICATIONS_LIST, { campaignID }],
    queryFn: () => ApplicationApi.list(ctx, { campaign_id: campaignID }),
    enabled: !!campaignID,
  });

  return useParseApplicationsList(applicationsQuery, {
    organization_name: organizationName,
    campaign_id: [campaignID ?? ""],
  });
};

export const useFetchJobApplicationsList = (ctx: Context, organizationName: string, jobID: string | undefined) => {
  const applicationsQuery = useQuery<Application[], unknown>({
    // eslint-disable-next-line @tanstack/query/exhaustive-deps
    queryKey: [QUERY_KEYS.APPLICATIONS_LIST, { jobID }],
    queryFn: () => ApplicationApi.list(ctx, { job_id: jobID, campaign_id: "" }),
    enabled: !!jobID,
  });

  return useParseApplicationsList(applicationsQuery, { organization_name: organizationName, job_id: [jobID ?? ""] });
};

export type PartialApplication = Partial<Omit<Application, "id">> & {
  id: string;
};

export const updateApplicationsCache = (
  queryClient: QueryClient,
  updater: PartialApplication | string | (PartialApplication | string)[],
) => {
  const cachedApplications = queryClient.getQueriesData<Application[]>({
    predicate: (query) => query.queryKey[0] === QUERY_KEYS.APPLICATIONS_LIST,
  });

  cachedApplications.forEach(([queryKey, applications]) => {
    if (!applications) return;

    const parsedNewApplications = Array.isArray(updater) ? updater : [updater];

    // Check if new applications belong to this query.
    parsedNewApplications.forEach((newApplication) => {
      if (typeof newApplication === "string") {
        queryClient.removeQueries({
          queryKey: [QUERY_KEYS.APPLICATIONS, newApplication],
        });
        const newApplications = applications.filter((application) => application.id !== newApplication);
        queryClient.setQueryData(queryKey, newApplications);
        return;
      }

      queryClient
        .invalidateQueries({
          queryKey: [QUERY_KEYS.APPLICATIONS, newApplication.id],
        })
        .catch(Logger.error);

      const matchingApplicationIndex = applications.findIndex((application) => application.id === newApplication.id);
      if (matchingApplicationIndex === -1) return;

      const newApplications = [...applications];
      newApplications[matchingApplicationIndex] = Object.assign(
        cloneDeep(newApplications[matchingApplicationIndex]),
        newApplication,
      );
      queryClient.setQueryData(queryKey, newApplications);
    });
  });
};
