import { UseQueryResult } from "@tanstack/react-query";

import { Logger } from "./log";

/**
 * Return whether 2 compared elements should be considered duplicates.
 */
export type DuplicateFN<E> = (a: E, b: E) => boolean;

export type MergeFN<QR, R> = (acc: R, res: QR) => R;

/**
 * Combine the results of multiple queries. Auto handles catching errors.
 */
export const combineQueries =
  <QR, R = QR>(initial: R, merge: MergeFN<QR, R>) =>
  (results: UseQueryResult<QR, Error>[]): R =>
    results.reduce((acc, queryResult) => {
      if (queryResult.isError) {
        Logger.error(queryResult.error);
        return acc;
      }

      if (queryResult.data) {
        acc = merge(acc, queryResult.data);
      }

      return acc;
    }, initial);

/**
 * Combine the arrays of multiple queries into a single, flat array.
 *
 * The optional duplicate args can be used to detect, and eliminate duplicate values.
 */
export const combineQueriesArrays = <E, T>(uniqProp?: (element: E) => T) =>
  combineQueries<E[]>([], (acc, res) => {
    if (uniqProp) {
      const seen = new Set(acc.map(uniqProp));
      res.forEach(item => {
        if (!seen.has(uniqProp(item))) {
          acc.push(item);
          seen.add(uniqProp(item));
        }
      });
    } else {
      acc.push(...res);
    }
    return acc;
  });

export const combineQueriesObjects = <E>() =>
  combineQueries<Record<string, E>>({}, (acc, res) => {
    return Object.assign(acc, res);
  });
