import { Session } from "next-auth";

/**
 * Check if the currently logged user has any of the asked roles. It returns false if the user is not logged in.
 */
export const hasRole = (session: Session | null, ...roles: string[]) => {
  if (!session?.user?.roles) {
    return false;
  }

  return roles.some((role) => session.user.roles.includes(role));
};
