import { useEffect, useState } from "react";

import jwt_decode from "jwt-decode";
import { useSession } from "next-auth/react";

import { checkPermissions, Logger } from "@utils";

export const useUserPermissions = (accessToken: string | undefined): string[] => {
  const [userPermissions, setUserPermissions] = useState<string[]>([]);

  const url = `${process.env.NEXT_PUBLIC_KEYCLOAK_BASE_URL || ""}/token`;

  const details = {
    grant_type: "urn:ietf:params:oauth:grant-type:uma-ticket",
    audience: process.env.NEXT_PUBLIC_KEYCLOAK_CLIENT_ID as string,
  };
  const formData: string = Object.entries(details)
    .map(([key, value]: [string, string]) => {
      return encodeURIComponent(key) + "=" + encodeURIComponent(value);
    })
    .join("&");

  type DecodedTokenPermission = {
    authorization: {
      permissions: {
        scopes: string[];
      }[];
    };
  };

  useEffect(() => {
    const fetchUserPermissions = async () => {
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
          Authorization: `Bearer ${accessToken || ""}`,
        },
        body: formData,
      });
      if (!response.ok)
        throw new Error("Can't get user permissions from Keycloak: " + JSON.stringify(response.statusText));
      const json: { access_token: string } = await response.json();
      const decodedToken: DecodedTokenPermission = jwt_decode(json.access_token);
      const tokenPermissions = decodedToken.authorization.permissions;
      setUserPermissions(
        tokenPermissions
          .filter((e) => e.scopes !== undefined)
          .map((e) => e.scopes)
          .reduce((a, b) => a.concat(b), []),
      );
    };
    if (accessToken) {
      fetchUserPermissions().catch(Logger.error);
    }
  }, [accessToken, formData, url]);

  return userPermissions;
};

export const useHasPermissions = (permissions: string[]) => {
  const { data: session } = useSession();
  const userPermissions = useUserPermissions(session?.accessToken);
  return checkPermissions(userPermissions, permissions);
};
