import { InterviewApi } from "@api";
import { QUERY_KEYS } from "@constants";
import { context } from "@opentelemetry/api";
import { useQueries, useQuery } from "@tanstack/react-query";
import { Interview, InterviewsConfiguration } from "@typings";
import { combineQueries } from "@utils";

export const useInterviewConfigurationByCampaignID = (campaignID?: string) =>
  useQuery({
    queryKey: [QUERY_KEYS.INTERVIEW_CONFIGURATION, campaignID],
    queryFn: () => InterviewApi.getConfiguration(context.active(), campaignID ?? ""),
    enabled: !!campaignID,
  });

export const useInterviewConfigurationsByCampaignIDs = (...campaignIDs: string[]) =>
  useQueries({
    queries: campaignIDs.map((campaignID) => ({
      // Use a different query key because the shape of the output changes. This prevents mixups in the cache resulting
      // in unexpected types.
      queryKey: [QUERY_KEYS.INTERVIEW_CONFIGURATION, campaignID, "batch"],
      queryFn: async () => ({
        campaignID,
        configuration: await InterviewApi.getConfiguration(context.active(), campaignID),
      }),
      enabled: !!campaignID,
    })),
    combine: combineQueries<
      { campaignID: string; configuration: InterviewsConfiguration | undefined },
      Record<string, InterviewsConfiguration | undefined>
    >({}, (acc, res) => {
      acc[res.campaignID] = res.configuration;
      return acc;
    }),
  });

export const useInterview = (applicationID: string | undefined) =>
  useQuery({
    queryKey: [QUERY_KEYS.INTERVIEW, applicationID],
    queryFn: () => InterviewApi.getInterview(context.active(), applicationID ?? ""),
    enabled: !!applicationID,
  });

export const useInterviews = (...applicationIDs: string[]) =>
  useQueries({
    queries: applicationIDs.map((applicationID) => ({
      queryKey: [QUERY_KEYS.INTERVIEW, applicationID],
      queryFn: () => InterviewApi.getInterview(context.active(), applicationID),
      enabled: !!applicationID,
    })),
    combine: combineQueries<Interview[], Record<string, Interview[]>>({}, (acc, res) => {
      if (res.length > 0) {
        const applicationID = res[0].application_id;
        acc[applicationID] = res;
      }
      return acc;
    }),
  });
