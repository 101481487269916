import { FC } from "react";

import { Add as AddIcon } from "@mui/icons-material";
import { ButtonProps } from "@mui/material";

import { WithMergeableTheme } from "@work4Labs/design-system";

import { BaseButton } from "./base-button";

export const AddButton: FC<WithMergeableTheme<ButtonProps>> = ({ children, ...props }) => (
  <BaseButton startIcon={<AddIcon sx={{ fill: "currentColor" }} />} {...props}>
    {children}
  </BaseButton>
);
