import { Dispatch, FC, SetStateAction, useCallback, useEffect, useMemo, useState } from "react";

import { AxiosError } from "axios";
import { useSession } from "next-auth/react";
import { useTranslation } from "next-i18next";
import { toast } from "react-toastify";

import { PersonAdd as PersonAddIcon } from "@mui/icons-material";
import { Box, Button, Chip, ThemeProvider, Typography, useTheme } from "@mui/material";

import { InfoBox, Modal } from "@work4Labs/design-system";

import { JobsApi } from "@api";
import { AutocompleteSelect } from "@components";
import { QUERY_KEYS, Roles, UserPermissions } from "@constants";
import { loadTranslations } from "@lib";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { ILabelValueItem } from "@typings";
import { Logger, hasRole } from "@utils";

import { useHasPermissions, useOTELContext, useUserGroup } from "@hooks";
import { useJobsAssignees, useOrganizationUsers } from "@hooks/queries";

import { ListJobItemWithStats } from "../types";

interface AssignJobMutationParams {
  jobID: string;
  userID: string;
}

interface Props {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  jobs: ListJobItemWithStats[];
  onSuccess: () => void;
}

export const ManageJobOwnersModal: FC<Props> = ({ open, setOpen, jobs, onSuccess }) => {
  const { t } = useTranslation(["manage-job-owners"]);
  loadTranslations("manage-job-owners");

  const { data: session } = useSession();
  const theme = useTheme();
  const ctx = useOTELContext();
  const queryClient = useQueryClient();
  const organizationName = useUserGroup();

  const canEditAssignations =
    useHasPermissions([
      UserPermissions.JobsPermissions.WriteAssignations,
      UserPermissions.JobsPermissions.DeleteAssignations,
    ]) && hasRole(session, Roles.ADMIN);

  const { assignees: jobsAssignees, pending: jobsAssigneesPending } = useJobsAssignees(jobs.map((job) => job.id));

  const organizationUsersQuery = useOrganizationUsers(organizationName);

  const options: ILabelValueItem[] = useMemo(() => {
    return (
      organizationUsersQuery.data?.map((user) => ({
        label: `${user.first_name} ${user.last_name}`,
        value: user.id,
      })) || []
    );
  }, [organizationUsersQuery.data]);

  const [selectedUsers, setSelectedUsers] = useState<ILabelValueItem[]>([]);

  // set default value when the job assignees are loaded.
  useEffect(() => {
    jobs.length === 1 &&
      setSelectedUsers(options.filter((user) => jobsAssignees[jobs[0].id]?.includes(user.value as string)));
  }, [jobs, jobs.length, jobsAssignees, options]);

  const onApply = useCallback((selected: ILabelValueItem[]) => {
    setSelectedUsers(selected);
  }, []);

  const { mutateAsync: assignJobMutation } = useMutation<void, AxiosError, AssignJobMutationParams>({
    mutationFn: (variables) => JobsApi.assignJob(ctx, variables.jobID, variables.userID),
  });

  const { mutateAsync: unassignJobMutation } = useMutation<void, AxiosError, AssignJobMutationParams>({
    mutationFn: (variables) => JobsApi.unassignJob(ctx, variables.jobID, variables.userID),
  });

  const doOnSubmit = useCallback(async () => {
    const promises: Promise<void>[] = [];

    if (jobs.length === 1) {
      const assigneeIDs = selectedUsers.map((user) => user.value);
      const usersToAssign = assigneeIDs.filter((userID: string) => !jobsAssignees[jobs[0].id]?.includes(userID));
      const usersToUnassign =
        jobsAssignees[jobs[0].id]?.filter((userID: string) => !assigneeIDs.includes(userID)) || [];

      usersToAssign.forEach((userID: string) => {
        promises.push(assignJobMutation({ jobID: jobs[0].id, userID }));
      });

      usersToUnassign.forEach((userID) => {
        promises.push(unassignJobMutation({ jobID: jobs[0].id, userID }));
      });
    } else {
      jobs.forEach((job) => {
        selectedUsers.forEach((user) => {
          promises.push(assignJobMutation({ jobID: job.id, userID: user.value as string }));
        });
      });
    }

    const results = await Promise.allSettled(promises);

    const errCount = results.filter((r) => r.status == "rejected").length;

    if (errCount == 0) {
      toast.success(t("assign_jobs_done", { count: jobs.length }));
      onSuccess();
    } else {
      toast.error(t("assign_jobs_error", { count: errCount }));
    }

    // to avoid refetching the assignees for jobs that haven't changed.
    jobs.forEach((job) => {
      queryClient
        .invalidateQueries({
          queryKey: [QUERY_KEYS.JOB_ASSIGNEES, job.id],
        })
        .catch(Logger.error);
    });
    queryClient.invalidateQueries({
      queryKey: [QUERY_KEYS.ASSIGNEE_JOBS, session?.user.id],
    });

    setOpen(false);
  }, [
    jobs,
    queryClient,
    session?.user.id,
    setOpen,
    selectedUsers,
    jobsAssignees,
    assignJobMutation,
    unassignJobMutation,
    t,
    onSuccess,
  ]);

  const onSubmit = useCallback(() => {
    doOnSubmit().catch(Logger.error);
  }, [doOnSubmit]);

  const onCancel = useCallback(() => {
    setSelectedUsers([]);
    setOpen(false);
  }, [setOpen]);

  const customActions = (onConfirm: (() => void) | undefined, onClose: (() => void) | undefined) => {
    const buttonStyle = {
      fontSize: "1rem",
      fontWeight: "600",
      textTransform: "none",
      borderRadius: "8px",
    };
    return (
      <>
        <Button
          color="secondary"
          onClick={() => {
            onClose?.();
          }}
          sx={{
            ...buttonStyle,
            color: theme.palette.primary.main,
            backgroundColor: "white",
            border: `1px solid ${theme.palette.primary.main}`,
          }}
        >
          {t("cancel")}
        </Button>
        <Button
          onClick={() => {
            onConfirm?.();
          }}
          sx={{
            ...buttonStyle,
            color: "white",
            backgroundColor: theme.palette.color.deepPurple,
            "&:hover": {
              backgroundColor: theme.palette.color.deepPurple,
            },
          }}
        >
          {t("submit")}
        </Button>
      </>
    );
  };

  const onDelete = useCallback((user: ILabelValueItem) => {
    setSelectedUsers((prevState) => prevState.filter((u) => u.value !== user.value));
  }, []);

  return (
    <Modal
      isOpen={open}
      aria-labelledby="manage-job-owners"
      aria-describedby="A modal for managing job owners"
      modalTitle={t("modal_title", { count: jobs.length })}
      title={t("title")}
      modalIcon={<PersonAddIcon />}
      onConfirm={onSubmit}
      onClose={onCancel}
      customActions={customActions}
    >
      <ThemeProvider theme={theme}>
        <Box
          display="flex"
          flexDirection="column"
          gap={(theme) => theme.spacings[16]}
          paddingY={(theme) => theme.spacings[16]}
        >
          <InfoBox title={""} level="info">
            {t("info_box_notification")}
          </InfoBox>
          <Box>
            <Typography variant="bodySmall">{t("organization_users")}</Typography>
            <AutocompleteSelect
              id="organization-users"
              options={options}
              value={selectedUsers}
              title={t("select_one_or_more")}
              onApply={onApply}
              loading={organizationUsersQuery.isLoading || jobsAssigneesPending}
              fullWidth
              getOptionDisabled={(option) => !canEditAssignations && option.value !== session?.user.id}
            />
          </Box>

          {selectedUsers.length > 0 && (
            <Box
              display="flex"
              flexDirection="column"
              mt={(theme) => theme.spacings[8]}
              gap={(theme) => theme.spacings[8]}
            >
              <Typography variant="bodyStrong" color={(theme) => theme.palette.color.BASE[800]}>
                {t("selected_users", { count: selectedUsers.length })}
              </Typography>
              <Typography variant="bodySmall" color={(theme) => theme.palette.color.BASE[800]}>
                {t("selected_users_info")}
              </Typography>
              <Box display="flex" flexDirection="row" gap={(theme) => theme.spacings[4]} flexWrap="wrap">
                {selectedUsers.map((user) => (
                  <Chip
                    key={user.value}
                    label={user.label}
                    color="deepPurple"
                    onDelete={() => onDelete(user)}
                    disabled={!canEditAssignations && user.value !== session?.user.id}
                  />
                ))}
              </Box>
            </Box>
          )}
        </Box>
      </ThemeProvider>
    </Modal>
  );
};
