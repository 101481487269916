import { FC, ReactNode } from "react";

import { Controller, useFormContext } from "react-hook-form";

import { Checkbox, CheckboxProps, FormControlLabel, FormHelperText } from "@mui/material";

type Props = CheckboxProps & {
  name: string;
  helperText?: ReactNode;
  label: ReactNode;
  labelPlacement?: "end" | "start" | "top" | "bottom";
};

export const FormCheckBox: FC<Props> = ({ name, label, labelPlacement, ...rest }) => {
  const {
    control,
    formState: { errors },
  } = useFormContext();

  const errorMessage = errors[`${name}`]?.message;
  const isError = !!errorMessage;

  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => (
        <>
          <FormControlLabel
            label={label}
            labelPlacement={labelPlacement}
            control={
              <Checkbox
                {...rest}
                {...field}
                value={field.value}
                checked={!!field.value}
                onChange={(e) => {
                  field.onChange(e.target.checked);
                  rest.onChange?.(e, e.target.checked);
                }}
              />
            }
          />
          {isError && <FormHelperText>{errorMessage as string}</FormHelperText>}
        </>
      )}
    />
  );
};
