import dynamic from "next/dynamic";

import { FC, useCallback, useRef, useState } from "react";

import { AnimationItem } from "lottie-web";
import animationData from "public/assets/lotties/loading-animation_anim-load.json";

import { Box } from "@mui/material";

import type { PlayerEvent } from "@lottiefiles/react-lottie-player";

const sleep = (ms: number) => new Promise((r) => setTimeout(r, ms));

const PlayerWithNoSSR = dynamic(() => import("@lottiefiles/react-lottie-player").then((module) => module.Player), {
  ssr: false,
});

export const LoadingAnimation: FC = () => {
  const [player, setPlayer] = useState<AnimationItem>();

  const count = useRef(0);

  // A function that is going to get called for all events of the animation.
  // We use it to play the animation periodically, in order to simulate the multiple retries
  // that react query would do when querying the data.
  // We don't do it via react-query because it's too complex for what we want right now.
  const onEvent = useCallback(
    (event: PlayerEvent) => {
      // kill the function after a while, because React might not clean it
      // and also because the exponential time will be too high anyway.
      // 3.5 will be 11s
      if (count.current / 2 > 4) {
        return;
      }

      // play it once it's loaded.
      if (event === "load") {
        player?.play();
        count.current += 1;
      }

      // when the animation is done
      if (event === "complete") {
        // we play it twice, just to make sure the user sees it.
        if (count.current % 2 === 1) {
          sleep(50).then(() => {
            player?.play();
            count.current += 1;
          });
        } else {
          // wait for next animation
          const exponentialBackOff = 2 ** (count.current / 2); // specific var for ts and mix usage of ** and *
          sleep(exponentialBackOff * 1000).then(() => {
            player?.play();
            count.current += 1;
          });
        }
      }
    },
    [player],
  );

  return (
    <Box sx={{ display: "inline-block" }}>
      <PlayerWithNoSSR
        lottieRef={setPlayer}
        onEvent={onEvent}
        autoplay={false}
        loop={false}
        controls={false}
        src={JSON.stringify(animationData)}
        style={{ height: "2rem", width: "2rem" }}
      />
    </Box>
  );
};
