export const sortEnum = <Value>(a: Value, b: Value, sorted: Value[]) => {
  const aIndex = sorted.indexOf(a);
  const bIndex = sorted.indexOf(b);

  if (aIndex === bIndex) return 0;

  if (aIndex === -1) return -1;

  if (bIndex === -1) return 1;

  return bIndex - aIndex;
};
