import { CSSProperties, FC } from "react";

import { useTheme } from "@mui/material";

import { SpinnerStyle } from "./styles";

type Props = {
  width?: string;
  height?: string;
  style?: CSSProperties;
};

export const Spinner: FC<Props> = ({ width, height, style }) => {
  const theme = useTheme();

  return (
    <SpinnerStyle width={width || ""} height={height || ""} style={style} className="lds-ring" theme={theme}>
      <div style={{ animationDelay: "-0.45s" }}></div>
      <div style={{ animationDelay: "-0.3s" }}></div>
      <div style={{ animationDelay: "-0.15s" }}></div>
      <div></div>
    </SpinnerStyle>
  );
};
