import { FC, useCallback, useMemo } from "react";

import { AxiosError } from "axios";
import { useTranslation } from "next-i18next";
import { Controller, useForm } from "react-hook-form";
import { toast } from "react-toastify";
import * as yup from "yup";

import { PersonAdd as PersonAddIcon } from "@mui/icons-material";
import { MenuItem, Typography } from "@mui/material";

import { ControlledTextField, Form, Modal, Select } from "@work4Labs/design-system";

import { InviteUserBody, userApi } from "@api";
import { Locale, QUERY_KEYS } from "@constants";
import { loadTranslations } from "@lib";
import { context } from "@opentelemetry/api";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { UserRoles } from "@typings";
import { Logger } from "@utils";

import { yupResolver } from "@hookform/resolvers/yup";
import { useUserOrganization } from "@hooks";

const useUserInformationValidationSchema = () => {
  const { t } = useTranslation(["organization-validation"]);
  loadTranslations("organization-validation");

  return useMemo(
    () =>
      yup.object().shape({
        first_name: yup.string().required(t("organization-validation:firstName.required")),
        last_name: yup.string().required(t("organization-validation:lastName.required")),
        role: yup
          .string()
          .required(t("organization-validation:role.required"))
          .oneOf(["admin", "recruiter"], t("organization-validation:role.invalid")),
        email: yup
          .string()
          .required(t("organization-validation:email.required"))
          .email(t("organization-validation:email.invalid")),
        locale: yup
          .string()
          .required(t("organization-validation:locale.required"))
          .oneOf(["en", "fr"], t("organization-validation:locale.invalid")),
      }),
    [t],
  );
};

interface UserInformationFormData {
  first_name: string;
  last_name: string;
  role: "" | "admin" | "recruiter";
  email: string;
  locale: "" | "en" | "fr";
}

export interface InviteNewUserModalProps {
  open: boolean;
  onClose: () => void;
}

export const InviteNewUserModal: FC<InviteNewUserModalProps> = ({ open, onClose }) => {
  const { t } = useTranslation(["organization-settings"]);
  loadTranslations("organization-settings");

  const queryClient = useQueryClient();

  const validationSchema = useUserInformationValidationSchema();
  const organization = useUserOrganization();

  const userInformationForm = useForm<UserInformationFormData>({
    resolver: yupResolver(validationSchema),
    shouldUnregister: false,
    mode: "onChange",
    defaultValues: {
      first_name: "",
      last_name: "",
      role: "",
      email: "",
      locale: "",
    },
  });

  const closeModal = useCallback(() => {
    userInformationForm.reset();
    onClose();
  }, [onClose, userInformationForm]);

  const createUserInformationMutation = useMutation({
    mutationFn: (data: InviteUserBody) => userApi.sendInvite(context.active(), data),
    onSuccess: () => {
      toast.success(t("organization-settings:users.modals.invite.success"));

      queryClient
        .invalidateQueries({ queryKey: [QUERY_KEYS.USERS] })
        .catch(Logger.error)
        .finally(closeModal);
    },
    onError: (err: AxiosError) => {
      switch (err.response?.status) {
        case 409:
          toast.error(t("organization-settings:users.modals.invite.errors.conflict"));
          break;
        case 401:
          toast.error(t("organization-settings:users.modals.invite.errors.group"));
          break;
        default:
          toast.error(t("organization-settings:users.modals.invite.errors.user"));
      }
    },
  });

  const createUserInformation = useCallback(() => {
    const values = userInformationForm.getValues();
    createUserInformationMutation.mutate({
      first_name: values.first_name,
      last_name: values.last_name,
      role: values.role,
      email: values.email,
      locale: values.locale,
      group_id: organization.data?.group_id ?? "",
    });
  }, [createUserInformationMutation, organization.data?.group_id, userInformationForm]);

  const canSave =
    organization.data?.group_id && userInformationForm.formState.isValid && !createUserInformationMutation.isPending;

  return (
    <Modal
      options={{
        confirmProps: { disabled: !canSave },
      }}
      isOpen={open}
      confirmText={t("organization-settings:users.modals.invite.confirm")}
      cancelText={t("organization-settings:users.modals.invite.cancel")}
      onConfirm={() => {
        userInformationForm
          .trigger()
          .then((isValid) => {
            if (isValid) {
              userInformationForm.handleSubmit(createUserInformation)();
            }
          })
          .catch(() => {});
      }}
      onClose={closeModal}
      modalTitle={t("organization-settings:users.modals.invite.modalTitle")}
      title={t("organization-settings:users.modals.invite.title")}
      modalIcon={<PersonAddIcon />}
    >
      <Form
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "stretch",
          gap: "var(--space-16)",
          width: "100%",
        }}
        methods={userInformationForm}
        submitHandler={createUserInformation}
      >
        <ControlledTextField
          name="first_name"
          label={t("organization-settings:users.modals.invite.firstName.label")}
          placeholder={t("organization-settings:users.modals.invite.firstName.placeholder")}
          error={userInformationForm.formState.errors.first_name?.message}
          required
          fullWidth
          disabled={!organization.data?.group_id}
        />
        <ControlledTextField
          name="last_name"
          label={t("organization-settings:users.modals.invite.lastName.label")}
          placeholder={t("organization-settings:users.modals.invite.lastName.placeholder")}
          error={userInformationForm.formState.errors.last_name?.message}
          required
          fullWidth
          disabled={!organization.data?.group_id}
        />
        <ControlledTextField
          name="email"
          label={t("organization-settings:users.modals.invite.email.label")}
          placeholder={t("organization-settings:users.modals.invite.email.placeholder")}
          error={userInformationForm.formState.errors.email?.message}
          required
          fullWidth
          disabled={!organization.data?.group_id}
        />
        <Controller
          control={userInformationForm.control}
          name="role"
          render={({ field }) => (
            <Select
              required
              error={userInformationForm.formState.errors.role?.message}
              selectProps={{ fullWidth: true }}
              value={field.value}
              onChange={(event) => field.onChange(event.target.value)}
              label={t("organization-settings:users.modals.invite.role.label")}
              placeholder={t("organization-settings:users.modals.invite.role.placeholder")}
              renderValue={(selected: UserInformationFormData["role"]) =>
                t(`organization-settings:users.modals.invite.role.values.${selected}`)
              }
            >
              {Object.values(UserRoles).map((role) => (
                <MenuItem key={role} value={role}>
                  {t(`organization-settings:users.modals.invite.role.values.${role}`)}
                </MenuItem>
              ))}
            </Select>
          )}
        />

        <Controller
          control={userInformationForm.control}
          name="locale"
          render={({ field }) => (
            <Select
              required
              error={userInformationForm.formState.errors.locale?.message}
              selectProps={{ fullWidth: true }}
              value={field.value}
              onChange={(event) => field.onChange(event.target.value)}
              label={t("organization-settings:users.modals.invite.locale.label")}
              placeholder={t("organization-settings:users.modals.invite.locale.placeholder")}
              renderValue={(selected: UserInformationFormData["locale"]) =>
                t(`organization-settings:users.modals.invite.locale.values.${selected}`)
              }
            >
              {Object.values(Locale).map((role) => (
                <MenuItem key={role} value={role}>
                  {t(`organization-settings:users.modals.invite.locale.values.${role}`)}
                </MenuItem>
              ))}
            </Select>
          )}
        />

        {userInformationForm.formState.errors.root ? (
          <Typography color="var(--color-palette-alert-red-400)">
            {userInformationForm.formState.errors.root.message}
          </Typography>
        ) : null}
      </Form>
    </Modal>
  );
};
