export interface AuthProxyUser {
  id: string;
  first_name: string;
  last_name: string;
  email: string;
  phone: string;
}

export interface AuthProxyUsersListItem extends AuthProxyUser {
  email: string;
  active: boolean;
  role: UserRoles;
  last_activity: Date;
}

export enum UserRoles {
  admin = "admin",
  recruiter = "recruiter",
}

export type AuthProxyUsersList = AuthProxyUsersListItem[];
