import { FC, ReactNode } from "react";

import { Button, Stack, Typography } from "@mui/material";

export interface SettingsPageFormProps {
  title: ReactNode;
  children: ReactNode;
  fullWidth?: boolean;
}

export const SettingsPageForm: FC<SettingsPageFormProps> = ({ title, children, fullWidth }) => (
  <Stack
    direction="column"
    gap="var(--size-16)"
    width={fullWidth ? "100%" : "40rem"}
    maxWidth="100%"
    alignItems="stretch"
  >
    <Typography
      color="var(--color-palette-base-800)"
      fontWeight={700}
      fontSize="var(--font-size-125)"
      lineHeight="150%"
    >
      {title}
    </Typography>

    {children}
  </Stack>
);

export interface SettingsSaveButtonProps {
  children: ReactNode;
  disabled?: boolean;
}

export const SettingsSaveButton: FC<SettingsSaveButtonProps> = ({ children, disabled }) => (
  <Button
    type="submit"
    disabled={disabled}
    sx={{
      alignSelf: "end",
      borderRadius: "var(--size-8)",
      borderWidth: "thin",
      padding: "var(--size-8) var(--size-12)",
      borderColor: "var(--color-palette-deep-purple)",
      backgroundColor: "var(--color-palette-deep-purple)",
      color: "var(--color-palette-base-000)",
      display: "flex",
      alignItems: "center",
      direction: "row",
      gap: "var(--size-4)",
      "&.Mui-disabled": {
        borderColor: "var(--color-palette-base-400)",
        color: "var(--color-palette-base-400)",
        backgroundColor: "var(--color-palette-base-100)",
      },
      "&:hover": {
        backgroundColor: "var(--color-palette-deep-purple)",
      },
    }}
  >
    {children}
  </Button>
);
