import { FC } from "react";

import { useTranslation } from "next-i18next";

import { PersonSearch as PersonIcon } from "@mui/icons-material";
import Timeline from "@mui/lab/Timeline";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineDot from "@mui/lab/TimelineDot";
import TimelineItem, { timelineItemClasses } from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import { Box, Grid, Typography } from "@mui/material";

import { COLOR_PALETTE, TEXT_COLOR } from "@constants";
import { loadTranslations } from "@lib";
import { Answer, Application } from "@typings";

type ApplicationQualificationProps = {
  application: Application;
};

export const ApplicationQualification: FC<ApplicationQualificationProps> = ({ application }) => {
  const { t } = useTranslation(["application-details", "application-scoring"]);
  loadTranslations("application-details");
  loadTranslations("application-scoring");

  return (
    <Grid container sx={{ padding: "24px 40px 24px 40px" }} id="application_tab_content_qualifications">
      <Grid item xs={12} sx={{ margin: "auto", display: "flex", flexDirection: "row", flexWrap: "nowrap" }}>
        <PersonIcon sx={{ width: "24px", height: "24px", marginRight: "12px" }} />
        <Typography variant="bodyCopyStrong" color={TEXT_COLOR.mainInfo}>
          {t("prequalification")}
        </Typography>
      </Grid>

      <Grid container>
        <Timeline
          sx={{
            [`& .${timelineItemClasses.root}:before`]: {
              flex: 0,
              padding: 0,
            },
            "& .MuiTimelineDot-root": {
              margin: 0,
              boxShadow: "none",
              color: COLOR_PALETTE.BASE[400],
            },
            "& .MuiTimelineConnector-root": {
              color: COLOR_PALETTE.BASE[400],
            },
            "& .MuiTimelineContent-root": {
              padding: "0px 0px",
              position: "relative",
              top: "-5px",
            },
            padding: "0px",
          }}
        >
          {application?.answers.map((item: Answer, index: number) => (
            <TimelineItem key={index}>
              <TimelineSeparator>
                <TimelineDot />
                {/* not displaying for the last element */}
                {index < application.answers.length - 1 && <TimelineConnector />}
              </TimelineSeparator>
              <TimelineContent>
                <Box
                  key={item.id}
                  sx={{
                    marginBottom: "1rem",
                    paddingLeft: "1rem",
                  }}
                >
                  <Typography variant="tagsStrong" color={TEXT_COLOR.mainInfo} display="block">
                    {item.question_label}
                  </Typography>
                  <Typography variant="tags" color={TEXT_COLOR.mainInfo} display="block">
                    {item.answer}
                  </Typography>
                </Box>
              </TimelineContent>
            </TimelineItem>
          ))}
        </Timeline>
      </Grid>
    </Grid>
  );
};
