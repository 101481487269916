import { FC, ReactNode } from "react";

import { Moment } from "moment";
import { useTranslation } from "next-i18next";
import { Controller, useFormContext } from "react-hook-form";

import { FormControl, FormHelperText, InputLabel } from "@mui/material";
import { DatePicker, DatePickerProps } from "@mui/x-date-pickers-pro";

import { loadTranslations } from "@lib";

type Props = Omit<DatePickerProps<Moment>, "value" | "onChange"> & {
  name: string;
  label?: ReactNode;
  helperText?: ReactNode;
  fullWidth?: boolean;
  required?: boolean;
  defaultValue?: Date;
};

export const FormDatePicker: FC<Props> = ({ name, label, helperText, defaultValue, ...rest }) => {
  const {
    control,
    formState: { errors },
  } = useFormContext();

  const { t } = useTranslation("common");
  loadTranslations("common");

  const errorMessage = errors[`${name}`]?.message;
  const isError = !!errorMessage;

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      render={({ field }) => {
        return (
          <FormControl fullWidth={rest.fullWidth} error={isError}>
            <InputLabel shrink error={isError} htmlFor={name} sx={{ display: "list-item" }}>
              {label || " "}
              {label && !rest.required && (
                <FormHelperText sx={{ display: "inline-block", marginLeft: "5px" }}>{`(${t(
                  "optional",
                )})`}</FormHelperText>
              )}
              {helperText && <FormHelperText>{helperText}</FormHelperText>}
            </InputLabel>

            <DatePicker {...rest} {...field} slotProps={{ textField: { "aria-label": "date-input" } }} />

            {isError && <FormHelperText error={isError}>{errorMessage as string}</FormHelperText>}
          </FormControl>
        );
      }}
    />
  );
};
