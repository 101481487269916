import moment, { Moment } from "moment";
import { TFunction } from "next-i18next";

export const elaspedTimeFromNow = (t: TFunction, date: Moment) => {
  const compareDate = moment();
  const diff = date.diff(compareDate, "days");

  if (diff === 0) {
    return t("today", { ns: "dates" });
  }

  if (diff <= -1 && diff >= -30) {
    return t("days", { count: diff * -1, ns: "dates" });
  }

  if (diff <= -31 && diff >= -61) {
    return t("more_than_1_month", { ns: "dates" });
  }

  if (diff <= -62 && diff >= -92) {
    return t("more_than_2_month", { ns: "dates" });
  }

  if (diff <= -93) {
    return t("more_than_3_month", { ns: "dates" });
  }

  return null;
};
