import { useSession } from "next-auth/react";
import { useTranslation } from "next-i18next";
import { useForm } from "react-hook-form";

import { ControlledTextField, Form } from "@work4Labs/design-system";

import { Roles } from "@constants";
import { loadTranslations } from "@lib";
import { hasRole } from "@utils";

interface AccountParametersFormData {
  role: string;
}

export const AccountParametersForm = () => {
  const { t } = useTranslation(["profile-settings"]);
  loadTranslations("profile-settings");

  const { data: session } = useSession();
  const role = t(
    `profile-settings:account.accountParameters.roles.${hasRole(session, Roles.ADMIN) ? "admin" : "recruiter"}`,
  );

  const accountParametersForm = useForm<AccountParametersFormData>({
    shouldUnregister: false,
    defaultValues: {
      role: role,
    },
  });

  return (
    <Form
      style={{ display: "flex", flexDirection: "column", gap: "var(--size-24)", alignItems: "stretch" }}
      submitHandler={() => {}}
      methods={accountParametersForm}
    >
      <ControlledTextField
        disabled
        label={t("profile-settings:account.accountParameters.role")}
        name="role"
        fullWidth
      />
    </Form>
  );
};
