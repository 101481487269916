import { FC, ReactNode, SyntheticEvent, useState } from "react";

import { Box, SxProps, Tab, Tabs, Theme } from "@mui/material";

import { Dict } from "@typings";

interface TabPanelProps {
  node: ReactNode;
  index: number;
  currentTab: number;
}

// TabPanel is the "wrapper" around the content of a tab (so under the "list" of the tab titles)
// and it's what it changing when we click on a different tab.
const TabPanel: FC<TabPanelProps> = (props) => {
  const { node, currentTab, index, ...other } = props;

  return (
    <div
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      role="tabpanel"
      hidden={currentTab !== index}
      {...other}
    >
      {currentTab === index && node}
    </div>
  );
};

export interface ItemTab {
  node: ReactNode;
  disabled: boolean;
  hidden?: boolean;
  tag?: ReactNode;
}

interface BasicTabProps {
  tabs: Dict<ItemTab>;
  style?: SxProps<Theme>;
}

// A component to display a bunch of tabs.
// It contains the logic to switch between them when the user click on the titles.
// Inspired by https://codesandbox.io/s/54m23c and https://mui.com/material-ui/react-tabs/#main-content
export const BasicTab: FC<BasicTabProps> = ({ tabs, style }) => {
  const [currentTab, setCurrentTab] = useState(0);

  const handleChange = (_: SyntheticEvent, newValue: number) => {
    setCurrentTab(newValue);
  };

  return (
    <Box sx={{ width: "100%", ...style }}>
      <Box sx={{ marginBottom: "2.5rem" }}>
        <Tabs value={currentTab} onChange={handleChange}>
          {Object.entries(tabs).map(([label, tab], index) =>
            !tab.hidden ? (
              <Tab
                id={`simple-tab-${index}`}
                aria-controls={`simple-tabpanel-${index}`}
                key={index}
                label={
                  <Box display="flex" flexDirection="row" gap="0.5rem">
                    <Box display="inline-flex" alignItems="center" justifyContent="center">
                      <span>{label}</span>
                    </Box>
                    {tab.tag}
                  </Box>
                }
                disabled={tab?.disabled}
                sx={(theme) => ({
                  borderBottomStyle: "solid",
                  borderBottomWidth: "2px",
                  color: theme.palette.grey[400],
                  fontWeight: "400",
                  marginRight: "0.5rem",
                })}
              />
            ) : (
              <span key={`tab-${index}`}></span>
            ),
          )}
        </Tabs>
      </Box>

      {Object.values(tabs).map((tabContent: ItemTab, index: number) => (
        <TabPanel key={`panel-${index}`} index={index} currentTab={currentTab} node={tabContent.node} />
      ))}
    </Box>
  );
};
