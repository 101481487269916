import { FC, useMemo } from "react";

import { useSession } from "next-auth/react";

import { Avatar } from "@mui/material";

import { MergeableTheme, withThemes } from "@work4Labs/design-system";

import { stringToColor } from "@utils";

export interface UserAvatarProps {
  size: string;
  sx?: MergeableTheme;
  userName?: string;
}

export const UserAvatar: FC<UserAvatarProps> = ({ size, sx, userName }) => {
  const { data: session } = useSession();
  const userInitial = useMemo(
    () => userName?.trim() || `${session?.user.given_name ?? ""} ${session?.user.family_name ?? ""}`.trim() || "?",
    [session?.user.family_name, session?.user.given_name, userName],
  );

  return (
    <Avatar
      sx={withThemes(
        { bgcolor: stringToColor(userInitial), width: size, height: size, fontSize: `calc(0.5 * ${size})` },
        sx,
      )}
    >
      {userInitial[0].toUpperCase()}
    </Avatar>
  );
};
