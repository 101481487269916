import { Dispatch, FC, SetStateAction } from "react";

import { AxiosError } from "axios";
import { Trans, useTranslation } from "next-i18next";
import { toast } from "react-toastify";

import { Grid, Typography } from "@mui/material";

import { Modal } from "@work4Labs/design-system";

import { ScoringAPI } from "@api";
import { loadTranslations } from "@lib";
import { context } from "@opentelemetry/api";
import { useMutation } from "@tanstack/react-query";

type RecomputeScoreModalProps = {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  applicationIDs: string[];
  onSuccess: () => void;
};

export const RecomputeScoreModal: FC<RecomputeScoreModalProps> = ({ open, setOpen, applicationIDs, onSuccess }) => {
  const { t } = useTranslation(["bulk-recompute-score"]);
  loadTranslations("bulk-recompute-score");

  const { mutateAsync: recomputeScoresMutation } = useMutation<void, AxiosError, string>({
    mutationFn: (applicationID: string) => ScoringAPI.recomputeScore(context.active(), applicationID),
  });

  // On form submit, we create a list of promises to update the statuses and create the comments.
  // We then run all queries in // and show a toast when we're done.
  const onSubmit = () => {
    const promises: Promise<void>[] = [];

    for (const applicationID of applicationIDs) {
      promises.push(recomputeScoresMutation(applicationID));
    }

    // allSettle leaves the opportunity of all queries to be run even if one fails.
    // Ideally we would revert all changes but we would have to create a specific endpoint for that.
    Promise.allSettled(promises).then((results) => {
      let errCount = 0;
      for (const r of results) {
        if (r.status == "rejected") {
          errCount += 1;
        }
      }

      if (errCount == 0) {
        toast.success(t("bulk_action_done"));
        onSuccess();
      } else {
        toast.error(t("bulk_action_error", { count: errCount }));
      }
      setOpen(false);
    });
  };

  const onCancel = () => {
    setOpen(false);
  };

  return (
    <Modal
      isOpen={open}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      confirmText={t("submit")}
      cancelText={t("cancel")}
      onConfirm={onSubmit}
      onClose={onCancel}
      options={{
        centerTitle: true,
      }}
      title={t("title")}
    >
      <Grid item xs={12}>
        <Grid item xs={12} sx={{ padding: "24px 0px 0px 0px", display: "flex", justifyContent: "flex-end" }}>
          <Typography sx={{ textAlign: "center" }}>
            <Trans
              t={t}
              i18nKey="warning_recompute_scores"
              components={[<strong key={0} />]}
              values={{
                count: applicationIDs.length,
              }}
            />
          </Typography>
        </Grid>
      </Grid>
    </Modal>
  );
};
