export enum VerificationMethodEnum {
  SMS = "sms",
  CALL = "call",
}

export interface NumberVerificationResponse {
  status: string;
  phone: string;
}
export interface InitiateNumberVerificationPayload {
  phone_number: string;
  method: VerificationMethodEnum;
}

export interface VerifyNumberPayload {
  phone_number: string;
  verification_code: string;
}

export interface CallHistory {
  id: string;
  caller_id: string;
  status: string;
  start_time: Date | null;
  duration_range: DurationRange;
  duration: number | null;
  created_at: Date;
}

export type DurationRange = {
  lower: Date | null;
  upper: Date | null;
};

export enum CallHistoryStatusEnum {
  INITIATED = "call.initiated",
  BRIDGED = "call.bridged",
  ANSWERED = "call.answered",
  HANGUP = "call.hangup",
}
