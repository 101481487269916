import { useRouter } from "next/router";

import { useCallback, useMemo } from "react";

import { useTranslation } from "next-i18next";

import { Box, Button, Chip, Typography } from "@mui/material";

import { MyApplicationsCard } from "@components";
import { APPLICATION_STATUSES } from "@constants";
import { loadTranslations } from "@lib";

import { useApplicationsCountPerJobs } from "@hooks/queries";

const MY_APPLICATIONS_CARDS = [
  APPLICATION_STATUSES.NEW,
  APPLICATION_STATUSES.DID_NOT_ANSWER,
  APPLICATION_STATUSES.INTERVIEW,
  APPLICATION_STATUSES.VALIDATION_PENDING,
];

interface Props {
  assignedJobs: string[];
}
export const MyApplications = ({ assignedJobs }: Props) => {
  const { t } = useTranslation(["my-applications"]);
  loadTranslations("my-applications");

  const router = useRouter();

  const { total: totalCount, counts: myApplicationsCount } = useApplicationsCount(assignedJobs);

  return (
    <Box display="flex" flexDirection="column" gap={(theme) => theme.spacings[16]}>
      <Box display="flex" flexDirection="row" justifyContent="space-between" alignItems="center">
        <Box display="flex" flexDirection="row" gap={(theme) => theme.spacings[16]} alignItems="center">
          <Typography variant="headingLG">{t("title")}</Typography>
          <Chip label={totalCount} size="small" color="deepPurple" />
        </Box>
        <Box>
          <Button
            variant="outlined"
            color="deepPurple"
            disabled={totalCount === 0}
            onClick={() => router.push("/my-applications")}
          >
            {t("show_all_my_applications")}
          </Button>
        </Box>
      </Box>

      <Box display="flex" flexDirection="row" gap={(theme) => theme.spacings[16]} alignItems="center">
        {MY_APPLICATIONS_CARDS.map((status) => (
          <MyApplicationsCard key={status} status={status} statusApplicationsCount={myApplicationsCount[status]} />
        ))}
      </Box>
    </Box>
  );
};

const useApplicationsCount = (assignedJobs: string[]) => {
  const applicationsCountForJobByStatus = useApplicationsCountPerJobs(...assignedJobs);

  // Combine together the total count of applications per status for all assigned jobs.
  const countApplicationsByStatus = useCallback(
    (status: string) =>
      (assignedJobs ?? []).reduce(
        (acc, jobID) =>
          acc +
          // Use each job report to compute the total of applications for a particular status.
          (applicationsCountForJobByStatus[jobID]?.reports.find((report) => report.label === status)?.value ?? 0),
        0,
      ),
    [applicationsCountForJobByStatus, assignedJobs],
  );

  return useMemo(() => {
    // Keep track of the total count of applications while we compute them by status.
    let total = 0;

    const counts: Record<string, number> = MY_APPLICATIONS_CARDS.reduce((acc, status) => {
      const currentJobCount = countApplicationsByStatus(status);
      acc[status] = currentJobCount;

      total += currentJobCount;

      return acc;
    }, {});

    return { total, counts };
  }, [countApplicationsByStatus]);
};
