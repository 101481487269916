import { FC, ReactNode } from "react";

import { useTranslation } from "next-i18next";
import { useFormContext } from "react-hook-form";

import { FormControl, FormHelperText, InputLabel, SwitchProps } from "@mui/material";

import { loadTranslations } from "@lib";
import { IOptionItem } from "@typings";

import { FormSwitch } from "../switch";

type FormSwitchGroupProps = SwitchProps & {
  name: string;
  label?: ReactNode;
  additionalInformation?: ReactNode;
  helperText?: ReactNode;
  options: IOptionItem | Array<IOptionItem>;
  required?: boolean;
};

export const FormSwitchGroup: FC<FormSwitchGroupProps> = ({
  name,
  label,
  additionalInformation,
  helperText,
  required,
  options,
  ...rest
}) => {
  const {
    formState: { errors },
  } = useFormContext();

  const { t } = useTranslation("common");
  loadTranslations("common");

  const errorMessage = errors[`${name}`]?.message;
  const isError = !!errorMessage;

  return (
    <FormControl component="fieldset" error={isError}>
      <InputLabel shrink error={isError} htmlFor={name} sx={{ display: "list-item" }}>
        {label || ""}
        {label && !required && (
          <FormHelperText sx={{ display: "inline-block", marginLeft: "5px" }}>{`(${t("optional")})`}</FormHelperText>
        )}
        {additionalInformation && (
          <FormHelperText
            sx={(theme) => ({
              fontSize: "14px",
              color: theme.palette.primary[900],
            })}
          >
            {additionalInformation}
          </FormHelperText>
        )}
        {helperText && <FormHelperText>{helperText}</FormHelperText>}
      </InputLabel>
      {Array.isArray(options) ? (
        options.map((option) => (
          <FormSwitch {...rest} key={option.value} name={`${name}.${option.value}`} label={option.label} />
        ))
      ) : (
        <FormSwitch {...rest} key={options.value} name={name} label={options.label} />
      )}
      {}
      {isError && <FormHelperText error={isError}>{errorMessage as string}</FormHelperText>}
    </FormControl>
  );
};
