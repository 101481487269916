import { isValidPhoneNumber } from "libphonenumber-js";
import { TFunction } from "next-i18next";
import { object, string } from "yup";

export const getPhoneValidationSchema = (t: TFunction, name: string) =>
  object().shape({
    [name]: string()
      .required(t("phone-number:required"))
      .test("valid-phone", t("phone-number:not_valid"), (value) => (value == null ? false : isValidPhoneNumber(value))),
  });

export type VerifyStep = "method" | "code";
