import { useSession } from "next-auth/react";

import { useCurrentUserOrganization } from "@hooks/queries";

export const useUserGroup = (): string => {
  const { data: session } = useSession();
  return session && session.user.groups.length > 0 ? session.user.groups[0] : "";
};

export const useUserOrganization = () => {
  const { data: session } = useSession();

  return useCurrentUserOrganization(session?.user?.id);
};
