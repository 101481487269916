import { useTranslation } from "next-i18next";
import { FieldValues } from "react-hook-form";

import { Box, Button } from "@mui/material";

import { loadTranslations } from "@lib";

import { Form, FormProps } from "../form";

type Props<TFieldValues extends FieldValues = FieldValues> = Omit<FormProps<TFieldValues>, "ref"> & {
  isEditing?: boolean;
  isLoading?: boolean;
  isValid?: boolean;
  label: string;
};

/**
 * Wrap a single field in its own form. Whenever isEditing is true, a submit button will automatically be
 * added under the field.
 */
export const SingleInputForm = <TFieldValues extends FieldValues = FieldValues>({
  isEditing = false,
  isLoading = false,
  isValid = false,
  label,
  children,
  ...rest
}: Props<TFieldValues>) => {
  const { t } = useTranslation(["form"]);
  loadTranslations("form");

  return (
    <Form {...rest}>
      {children}
      {isEditing && (
        <Box sx={{ textAlign: "right" }}>
          <Button
            sx={{
              marginTop: "1rem",
            }}
            variant="contained"
            disabled={isLoading || !isValid}
            aria-label={t("submit_arialabel", { label })}
            type="submit"
          >
            {t("submit")}
          </Button>
        </Box>
      )}
    </Form>
  );
};
