import dynamic from "next/dynamic";
import { useRouter } from "next/router";

import { FC, useState } from "react";

import moment from "moment-business-days";
import { useSession } from "next-auth/react";
import { useTranslation } from "next-i18next";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";

import {
  Backdrop,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CircularProgress,
  Grid,
  Step,
  StepLabel,
  Stepper,
} from "@mui/material";
import { Container } from "@mui/system";

import { SourcingCampaignApi } from "@api";
import { Form, PageTitle, Spinner } from "@components";
import { Currency, QUERY_KEYS, STATUSES_WITH_AUTOMATION } from "@constants";
import { useApp } from "@contexts";
import { loadTranslations } from "@lib";
import { context } from "@opentelemetry/api";
import { Error } from "@styledcomponents";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { palette } from "@theme";
import {
  CampaignObjectives,
  HasAutomation,
  ResumeRequired,
  SourcingCampaignCreation,
  SourcingCampaignCreationAutomations,
} from "@typings";

import { DevTool } from "@hookform/devtools";
import { yupResolver } from "@hookform/resolvers/yup";

import { getStepperValidationSchema } from "./validate";

// @TODO: Once we move to react 18 we can replace the loading
//   with suspense true
const GeneralInformations = dynamic(() => import("./forms/general-informations"), {
  loading: () => <Spinner style={{ position: "relative" }} />,
});
const ObjectiveDefinition = dynamic(() => import("./forms/objective-definition"), {
  loading: () => <Spinner style={{ position: "relative" }} />,
});
const AudienceDefinition = dynamic(() => import("./forms/audience-definition"), {
  loading: () => <Spinner style={{ position: "relative" }} />,
});
const AdvertisingContent = dynamic(() => import("./forms/advertising-content"), {
  loading: () => <Spinner style={{ position: "relative" }} />,
});
const AutomationDefinition = dynamic(() => import("./forms/automation-definition"), {
  loading: () => <Spinner style={{ position: "relative" }} />,
});

// the form steps, each displayed one after the other.
const STEPS = [
  "campaign-form:general_informations",
  "campaign-form:objective",
  "campaign-form:content",
  "campaign-form:audience",
  "campaign-form:automation",
];

const getStepContent = (stepIndex: number) => {
  switch (stepIndex) {
    case 0:
      return <GeneralInformations />;
    case 1:
      return <ObjectiveDefinition />;
    case 2:
      return <AdvertisingContent />;
    case 3:
      return <AudienceDefinition />;
    case 4:
      return <AutomationDefinition />;
    default:
      return "Unknown step";
  }
};

// _ in var names are ok here because they have to match the back.
const DEFAULT_VALUES = {
  campaign_name: "",
  campaign_objective: "" as CampaignObjectives,
  start_at: moment().businessAdd(5, "days"),
  campaign_duration: "",
  launch_asap: false,
  budget: "",
  budget_value: 0,
  currency: Currency.EUR,
  stakeholders: [],
  content_to_promote: "",
  creatives_check: "",
  creatives: [],
  wordings_check: "",
  wordings: "",
  geolocation: "",
  expected_qualification: "",
  resume_required: ResumeRequired.NO,
  additional_information: "",
  has_automation: "",
  automations: STATUSES_WITH_AUTOMATION.reduce(
    (a, item) => ({ ...a, [item]: false }),
    {},
  ) as SourcingCampaignCreationAutomations,
  has_custom_automation: false,
};

export const CampaignForm: FC = () => {
  const { t } = useTranslation([
    "campaign-form",
    "general-information-validation",
    "advertising-content-validation",
    "generic-validation",
    "automation-definition-validation",
    "common",
  ]);
  loadTranslations("campaign-form");
  loadTranslations("general-information-validation");
  loadTranslations("advertising-content-validation");
  loadTranslations("generic-validation");
  loadTranslations("automation-definition-validation");
  loadTranslations("common");

  const [activeStep, setActiveStep] = useState(0);

  const { data: session, status: sessionStatus } = useSession();

  const { setCampaignCreated } = useApp();

  const currentStepperValidationSchema = getStepperValidationSchema(t)[activeStep];
  const methods = useForm({
    shouldUnregister: false,
    defaultValues: session?.user ? { ...DEFAULT_VALUES, stakeholders: [session?.user.email] } : DEFAULT_VALUES,
    resolver: yupResolver(currentStepperValidationSchema),
    mode: "onChange",
  });

  const queryClient = useQueryClient();

  const {
    trigger,
    getValues,
    formState: { errors },
  } = methods;

  const router = useRouter();

  const mutation = useMutation({
    mutationFn: (data: SourcingCampaignCreation) => SourcingCampaignApi.create(context.active(), data),
    onSuccess: () => {
      queryClient
        .invalidateQueries({
          queryKey: [QUERY_KEYS.SOURCING_CAMPAIGNS],
        })
        .catch(() => {})
        .finally(() => {
          setCampaignCreated(true);
          router.push("/");
        });
    },
    onError: () => {
      toast.error(t("generic-validation:creation_error"));
    },
  });

  const onSubmit = () => {
    trigger().then((isStepValid) => {
      if (isStepValid) {
        if (sessionStatus === "authenticated") {
          const formData = getValues();
          if (formData.budget_value != null) {
            formData.budget = `${formData.budget_value}${formData.currency as string}`;
          }

          const startAt = moment(formData.start_at);

          const sourcingCampaignData: SourcingCampaignCreation = {
            ...formData,
            requester_email: session?.user.email,
            requester_given_name: session?.user.given_name,
            requester_last_name: session?.user.family_name,
            target_organization: session?.user?.groups?.[0] || "",
            resume_required: formData.resume_required === ResumeRequired.YES,
            has_automation: formData.has_automation === HasAutomation.YES,
            start_at: startAt.isValid() ? startAt.format("yyyy-MM-DD") : "",
          };
          mutation.mutate(sourcingCampaignData);
        }
      }
    });
  };

  const handleNext = (event) => {
    event.preventDefault(); // to prevent form submission
    trigger().then((isStepValid) => {
      isStepValid && setActiveStep((prevActiveStep) => prevActiveStep + 1);
    });
  };

  const handleBack = (event) => {
    event.preventDefault(); // to prevent form submission
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  return (
    <Container>
      <PageTitle style={{ marginBottom: "2.5rem" }}>{t("new_campaign")}</PageTitle>
      <Form methods={methods} submitHandler={() => {}}>
        <Grid container>
          <Grid item xs={4}>
            <Stepper activeStep={activeStep} connector={null} orientation="vertical" sx={{ paddingY: "1.5rem" }}>
              {STEPS.map((step) => (
                <Step
                  sx={{
                    "& .MuiStepLabel-root": { color: palette.grey[400] },
                    "& .MuiStepLabel-root.Mui-active": { color: palette.primary[900] },
                    "& .MuiStepIcon-root": { color: palette.grey[400] },
                    "& .MuiStepIcon-root.Mui-active": { color: palette.primary[900] },
                    "& .MuiStepIcon-text": { color: palette.primary[900] },
                    "& .MuiStepIcon-text.Mui-active": { color: "#fff" },
                  }}
                  key={step}
                >
                  <StepLabel>{t(step)}</StepLabel>
                </Step>
              ))}
            </Stepper>
          </Grid>
          <Grid item xs={8}>
            <Card
              sx={{
                padding: "1.5rem",
                position: "relative",
                borderRadius: "1rem",
                boxShadow:
                  "22px 18px 11px rgba(5, 5, 102, 0.01), 12px 10px 9px rgba(5, 5, 102, 0.02), 5px 4px 7px rgba(5, 5, 102, 0.03), 1px 1px 4px rgba(5, 5, 102, 0.04), 0px 0px 0px rgba(5, 5, 102, 0.04)",
                overflow: "hidden",
              }}
            >
              <Backdrop
                sx={{ zIndex: (theme) => theme.zIndex.drawer + 1, position: "absolute" }}
                open={mutation.isPending}
              >
                <CircularProgress color="inherit" />
              </Backdrop>
              <CardContent>{getStepContent(activeStep)}</CardContent>
              <CardActions>
                <Box width="100%" display="flex" justifyContent="right">
                  <Button
                    sx={{ marginRight: "1rem" }}
                    variant="outlined"
                    disabled={activeStep === 0}
                    onClick={handleBack}
                  >
                    {t("common:back")}
                  </Button>
                  {activeStep === STEPS.length - 1 ? (
                    <>
                      <Button variant="contained" disabled={mutation.isPending} color="primary" onClick={onSubmit}>
                        {t("campaign-form:submit")}
                      </Button>
                      {errors["API_ERROR_FIELD"] && <Error>{errors["API_ERROR_FIELD"]?.message}</Error>}
                    </>
                  ) : (
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={handleNext}
                      disabled={activeStep === STEPS.length - 1}
                    >
                      {t("common:next")}
                    </Button>
                  )}
                </Box>
              </CardActions>
            </Card>
          </Grid>
        </Grid>
        <DevTool control={methods.control} />
      </Form>
    </Container>
  );
};
