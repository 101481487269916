import { OrganizationsAPI } from "@api";
import { QUERY_KEYS } from "@constants";
import { context } from "@opentelemetry/api";
import { QueryObserverResult, useQuery } from "@tanstack/react-query";
import { Organization } from "@typings";

export interface CurrentUserOrganizationHook {
  data: Organization | undefined;
  isLoading: boolean;
  refetch: () => Promise<QueryObserverResult<Organization | undefined, Error>>;
}

export const useCurrentUserOrganization = (userID?: string): CurrentUserOrganizationHook => {
  return useQuery({
    queryKey: [QUERY_KEYS.ORGANIZATIONS, userID],
    queryFn: () => OrganizationsAPI.getMy(context.active()),
    enabled: !!userID,
  });
};
