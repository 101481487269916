import { Dispatch, FC, ReactNode, SetStateAction, createContext, useContext, useMemo, useState } from "react";

interface IAppContext {
  campaignCreated: boolean;
  setCampaignCreated: Dispatch<SetStateAction<boolean>>;
}

const INITIAL_CONTEXT: IAppContext = {
  campaignCreated: false,
  setCampaignCreated: () => {},
};

const AppContext = createContext<IAppContext>(INITIAL_CONTEXT);

const AppProvider: FC<{ children: ReactNode }> = (props) => {
  const [campaignCreated, setCampaignCreated] = useState(INITIAL_CONTEXT.campaignCreated);
  const memoedValue = useMemo(
    () => ({
      campaignCreated,
      setCampaignCreated,
    }),
    [campaignCreated],
  );
  return <AppContext.Provider value={memoedValue} {...props} />;
};

const useApp = (): IAppContext => useContext(AppContext);

export { AppProvider, useApp };
