import { FC, useCallback, useEffect, useMemo, useState } from "react";

import { isValidPhoneNumber } from "libphonenumber-js";
import { signIn, useSession } from "next-auth/react";
import { Trans, useTranslation } from "next-i18next";
import { Controller, UseFormReturn, useForm } from "react-hook-form";
import { toast } from "react-toastify";
import * as yup from "yup";

import { Phone, Verified as VerifiedIcon } from "@mui/icons-material";
import { Link, MenuItem, Typography } from "@mui/material";

import { ControlledPhoneField, ControlledTextField, Form, Modal, Select } from "@work4Labs/design-system";

import { ClickToCallAPI, UpdateUserInfoBody, userApi } from "@api";
import { SettingsSaveButton } from "@components";
import { QUERY_KEYS } from "@constants";
import { loadTranslations } from "@lib";
import { context } from "@opentelemetry/api";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { InitiateNumberVerificationPayload, VerificationMethodEnum, VerifyNumberPayload } from "@typings";
import { Logger } from "@utils";

import { yupResolver } from "@hookform/resolvers/yup";
import { usePhoneNumberVerificationStatus } from "@hooks/queries";

type PhoneNumberVerifyStep = "method" | "code";

const usePhoneNumberValidationSchema = () => {
  const { t } = useTranslation(["profile-validation"]);
  loadTranslations("profile-validation");

  return useMemo(
    () =>
      yup.object().shape({
        phone: yup
          .string()
          .nullable()
          .required(t("profile-validation:phone.required"))
          .test("valid-phone", t("profile-validation:phone.invalid"), (value) =>
            value == null ? false : isValidPhoneNumber(value),
          ),
      }),
    [t],
  );
};

const usePhoneNumberVerificationCodeSchema = () => {
  const { t } = useTranslation(["profile-validation"]);
  loadTranslations("profile-validation");

  return useMemo(
    () =>
      yup.object().shape({
        code: yup.string().required(t("profile-validation:phone.verificationCodeRequired")),
      }),
    [t],
  );
};

interface PhoneFormData {
  phone: string;
}

interface PhoneVerifyMethodFormData {
  method: VerificationMethodEnum;
}

interface PhoneVerifyCodeFormData {
  code: string;
}

interface VerificationMethodFormProps {
  methods: UseFormReturn<PhoneVerifyMethodFormData>;
  submitHandler: (data: PhoneVerifyMethodFormData) => void;
}

const VerificationMethodForm: FC<VerificationMethodFormProps> = ({ methods, submitHandler }) => {
  const { t } = useTranslation(["profile-settings"]);
  loadTranslations("profile-settings");

  return (
    <Form
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "stretch",
        gap: "var(--space-16)",
        width: "100%",
      }}
      methods={methods}
      submitHandler={submitHandler}
    >
      <Typography fontSize="1rem" fontWeight={400}>
        {t("profile-settings:account.phoneNumber.verifyMethod.content")}
      </Typography>

      <Controller
        control={methods.control}
        name="method"
        render={({ field }) => (
          <Select
            required
            error={methods.formState.errors.method?.message}
            selectProps={{ fullWidth: true }}
            value={field.value}
            onChange={(event) => field.onChange(event.target.value)}
            renderValue={(selected: VerificationMethodEnum) =>
              t(`profile-settings:account.phoneNumber.verifyMethod.options.${selected}`)
            }
          >
            {Object.values(VerificationMethodEnum).map((method) => (
              <MenuItem key={method} value={method}>
                {t(`profile-settings:account.phoneNumber.verifyMethod.options.${method}`)}
              </MenuItem>
            ))}
          </Select>
        )}
      />
      {methods.formState.errors.root ? (
        <Typography color="var(--color-palette-alert-red-400)">{methods.formState.errors.root.message}</Typography>
      ) : null}
    </Form>
  );
};

interface VerificationCodeFormProps {
  methods: UseFormReturn<PhoneVerifyCodeFormData>;
  submitHandler: (data: PhoneVerifyCodeFormData) => void;
  setValidationStep: (step: PhoneNumberVerifyStep) => void;
}

const VerificationCodeForm: FC<VerificationCodeFormProps> = ({ methods, submitHandler, setValidationStep }) => {
  const { t } = useTranslation(["profile-settings"]);
  loadTranslations("profile-settings");

  return (
    <Form
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "stretch",
        gap: "var(--space-16)",
        width: "100%",
      }}
      methods={methods}
      submitHandler={submitHandler}
    >
      <Typography fontSize="1rem" fontWeight={400}>
        <Trans
          t={t}
          i18nKey="profile-settings:account.phoneNumber.verifyCode.content"
          components={[
            <Link
              key="back-step"
              sx={{ ":hover": { cursor: "pointer" } }}
              onClick={() => setValidationStep("method")}
            />,
          ]}
        />
      </Typography>
      <ControlledTextField
        error={methods.formState.errors.code?.message}
        required
        name="code"
        placeholder={t("profile-settings:account.phoneNumber.verifyCode.placeholder")}
        fullWidth
      />
      {methods.formState.errors.root ? (
        <Typography color="var(--color-palette-alert-red-400)">{methods.formState.errors.root.message}</Typography>
      ) : null}
    </Form>
  );
};

export const PhoneForm = () => {
  // ===================================================================================================================
  // VARIABLES
  // ===================================================================================================================

  const { t, i18n } = useTranslation(["profile-settings"]);
  loadTranslations("profile-settings");

  const { data: session } = useSession();
  const queryClient = useQueryClient();

  // This is the value of the language to be used on the phone input
  // based on the user language.
  // PS: if we add new languages with custom code (e.g: en-US, en-UK)
  // we need to add their countries here.
  const language = useMemo(() => {
    switch (i18n.language) {
      case "en-US":
        return "en";
      default:
        return i18n.language;
    }
  }, [i18n.language]);

  // ===================================================================================================================
  // STATES
  // ===================================================================================================================

  const [validationModalOpened, setValidationModalOpened] = useState(false);
  const [validationModalStep, setValidationModalStep] = useState<PhoneNumberVerifyStep>("method");

  // ===================================================================================================================
  // VALIDATION SCHEMAS
  // ===================================================================================================================

  const validationSchema = usePhoneNumberValidationSchema();
  const verificationCodeSchema = usePhoneNumberVerificationCodeSchema();

  // ===================================================================================================================
  // FORMS
  // ===================================================================================================================

  const phoneNumberForm = useForm<PhoneFormData>({
    resolver: yupResolver(validationSchema),
    shouldUnregister: false,
    mode: "onChange",
    defaultValues: {
      phone: session?.user.phone ?? "",
    },
  });

  const phoneNumberVerificationMethodForm = useForm<PhoneVerifyMethodFormData>({
    shouldUnregister: false,
    mode: "onChange",
    defaultValues: {
      method: VerificationMethodEnum.SMS,
    },
  });

  const phoneNumberVerificationCodeForm = useForm<PhoneVerifyCodeFormData>({
    resolver: yupResolver(verificationCodeSchema),
    shouldUnregister: false,
    mode: "onChange",
    defaultValues: {
      code: "",
    },
  });

  // Update default values when session loads or is updated.
  useEffect(() => {
    if (session?.user.phone) {
      phoneNumberForm.reset({
        phone: session.user.phone,
      });
    }
  }, [phoneNumberForm, session?.user.phone]);

  const closePhoneValidationModal = useCallback(() => {
    setValidationModalStep("method");
    phoneNumberVerificationMethodForm.reset();
    phoneNumberVerificationCodeForm.reset();
    setValidationModalOpened(false);
  }, [phoneNumberVerificationMethodForm, phoneNumberVerificationCodeForm]);

  // ===================================================================================================================
  // MUTATIONS
  // ===================================================================================================================

  const updatePhoneNumberMutation = useMutation({
    mutationFn: (data: Partial<UpdateUserInfoBody>) => userApi.updateUserInfo(context.active(), data),
    onSuccess: async () => {
      // This sign-in enables to refresh token information:
      // https://github.com/nextauthjs/next-auth/issues/2269
      await signIn("keycloak", { callbackUrl: "/personal-settings" });
      toast.success(t("profile-settings:update.success"));
    },
    onError: () => {
      toast.error(t("profile-settings:update.error"));
    },
  });

  const initiateVerificationMutation = useMutation({
    mutationFn: (data: InitiateNumberVerificationPayload) =>
      ClickToCallAPI.initiateNumberVerification(context.active(), data),
    onSuccess: () => {
      toast.success(t("profile-settings:account.phoneNumber.verifyMethod.success"));
      setValidationModalStep("code");
    },
    onError: () => {
      toast.error(t("profile-settings:account.phoneNumber.verifyMethod.error"));
    },
  });

  const phoneNumberVerificationStatus = usePhoneNumberVerificationStatus(phoneNumberForm.getValues("phone"));

  const verifyNumberMutation = useMutation({
    mutationFn: (data: VerifyNumberPayload) => ClickToCallAPI.verifyNumber(context.active(), data),
    onSuccess: (_, variables) => {
      toast.success(t("profile-settings:account.phoneNumber.verifyCode.success"));

      queryClient
        .invalidateQueries({
          queryKey: [QUERY_KEYS.CLICK_TO_CALL_NUMBER_VERIFICATION_STATUS, session?.user.phone],
        })
        .catch(Logger.error);

      updatePhoneNumberMutation.mutate({ phone: variables.phone_number });

      closePhoneValidationModal();
    },
    onError: () => {
      toast.error(t("profile-settings:account.phoneNumber.verifyCode.error"));
    },
  });

  // ===================================================================================================================
  // FORM SUBMIT HANDLERS
  // ===================================================================================================================

  const onUpdatePhoneNumber = useCallback(
    (formData: PhoneFormData) => {
      if (!phoneNumberVerificationStatus.verified) {
        setValidationModalOpened(true);
        return;
      }

      updatePhoneNumberMutation.mutate({ phone: formData.phone });
      return;
    },
    [phoneNumberVerificationStatus.verified, updatePhoneNumberMutation],
  );

  const onSelectVerificationMethod = useCallback(
    (formData: PhoneVerifyMethodFormData) => {
      initiateVerificationMutation.mutate({
        method: formData.method,
        phone_number: phoneNumberForm.getValues("phone"),
      });
    },
    [phoneNumberForm, initiateVerificationMutation],
  );

  const onSaveVerificationCode = useCallback(
    (formData: PhoneVerifyCodeFormData) => {
      verifyNumberMutation.mutate({
        verification_code: formData.code,
        phone_number: phoneNumberForm.getValues("phone"),
      });
    },
    [phoneNumberForm, verifyNumberMutation],
  );

  // ===================================================================================================================
  // CAN SAVE FORMS
  // ===================================================================================================================

  const phoneFormValue = phoneNumberForm.watch("phone");

  const canSave =
    session &&
    phoneNumberForm.formState.isValid &&
    !updatePhoneNumberMutation.isPending &&
    phoneFormValue !== session?.user.phone &&
    !validationModalOpened;

  const canSaveVerificationMethodForm = !initiateVerificationMutation.isPending && validationModalOpened;

  const canSaveVerificationCodeForm =
    !updatePhoneNumberMutation.isPending && phoneNumberVerificationCodeForm.formState.isValid && validationModalOpened;

  const canSaveForm = validationModalStep === "method" ? canSaveVerificationMethodForm : canSaveVerificationCodeForm;

  // ===================================================================================================================
  // RETURN
  // ===================================================================================================================

  return (
    <>
      <Form
        style={{ display: "flex", flexDirection: "column", gap: "var(--size-24)", alignItems: "stretch" }}
        submitHandler={onUpdatePhoneNumber}
        methods={phoneNumberForm}
      >
        <ControlledPhoneField
          error={phoneNumberForm.formState.errors.phone?.message}
          disabled={session == null}
          label={t("profile-settings:account.phoneNumber.professionalPhoneNumber")}
          name="phone"
          InputProps={{
            endAdornment: phoneNumberVerificationStatus.verified ? (
              <VerifiedIcon sx={{ color: "var(--color-palette-top-green-400)" }} />
            ) : null,
          }}
          lang={language}
          fullWidth
        />
        <SettingsSaveButton disabled={!canSave}>
          {phoneNumberVerificationStatus.verified ? null : <VerifiedIcon />}
          <span>
            {t(`profile-settings:account.phoneNumber.${phoneNumberVerificationStatus.verified ? "save" : "verify"}`)}
          </span>
        </SettingsSaveButton>
      </Form>

      <Modal
        options={{
          confirmProps: { disabled: !canSaveForm },
        }}
        cancelText={
          validationModalStep === "code" ? t("profile-settings:account.phoneNumber.verifyCode.cancel") : undefined
        }
        confirmText={
          validationModalStep === "code"
            ? t("profile-settings:account.phoneNumber.verifyCode.confirm")
            : t("profile-settings:account.phoneNumber.verifyMethod.confirm")
        }
        onConfirm={() => {
          switch (validationModalStep) {
            case "code":
              phoneNumberVerificationCodeForm
                .trigger()
                .then(() => phoneNumberVerificationCodeForm.handleSubmit(onSaveVerificationCode)())
                .catch(() => {});
              break;
            case "method":
              phoneNumberVerificationMethodForm
                .trigger()
                .then(() => phoneNumberVerificationMethodForm.handleSubmit(onSelectVerificationMethod)())
                .catch(() => {});
              break;
          }
        }}
        modalTitle={
          validationModalStep === "code"
            ? t("profile-settings:account.phoneNumber.verifyCode.modalTitle")
            : t("profile-settings:account.phoneNumber.verifyMethod.modalTitle")
        }
        title={
          validationModalStep === "code"
            ? t("profile-settings:account.phoneNumber.verifyCode.title")
            : t("profile-settings:account.phoneNumber.verifyMethod.title")
        }
        onClose={closePhoneValidationModal}
        modalIcon={<Phone />}
        isOpen={validationModalOpened}
      >
        {validationModalStep === "code" ? (
          <VerificationCodeForm
            methods={phoneNumberVerificationCodeForm}
            submitHandler={onSaveVerificationCode}
            setValidationStep={setValidationModalStep}
          />
        ) : (
          <VerificationMethodForm
            methods={phoneNumberVerificationMethodForm}
            submitHandler={onSelectVerificationMethod}
          />
        )}
      </Modal>
    </>
  );
};
