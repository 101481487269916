import moment, { Moment } from "moment";

export interface InterviewsConfiguration {
  id: string;

  duration: number; // minutes
  location: string;
  additional_information: string;
  messages: string[];
}

export class Interview {
  id: string;

  application_id: string;
  campaign_id: string;
  recruiter_user_id: string;

  date: Moment;
  time_start: Moment;
  time_end: Moment;

  location: string;
  stakeholders: string[];
  additional_information: string;

  created_at: string;

  deleted: boolean;

  constructor(
    id: string,
    application_id: string,
    campaign_id: string,
    recruiter_user_id: string,
    date: Moment,
    time_start: Moment,
    time_end: Moment,
    location: string,
    stakeholders: string[],
    additional_information: string,
    created_at: string,
    deleted: boolean,
  ) {
    this.id = id;
    this.application_id = application_id;
    this.campaign_id = campaign_id;
    this.recruiter_user_id = recruiter_user_id;
    this.date = date;
    this.time_start = time_start;
    this.time_end = time_end;
    this.location = location;
    this.stakeholders = stakeholders;
    this.additional_information = additional_information;
    this.created_at = created_at;
    this.deleted = deleted;
  }

  // add the time_end to the date of the interview to get the end date of the interview.
  getInterviewEndDate(): Moment {
    return moment(this.date.format("YYYY-MM-DD") + " " + this.time_end.format("HH:mm"));
  }
}

export interface InterviewWasDone {
  interview: Interview;

  created_at: Moment;
}

export enum InterviewMessageType {
  EMAIL_CONFIRMATION_CANDIDATES = "email_confirmation_candidates",
  EMAIL_CONFIRMATION_RECRUITERS = "email_confirmation_recruiters",
  EMAIL_REMINDER_48H_CANDIDATES = "email_reminder_48h_candidates",
  SMS_CONFIRMATION_CANDIDATES = "sms_confirmation_candidates",
  SMS_REMINDER_72H_CANDIDATES = "sms_reminder_72h_candidates",
  SMS_REMINDER_24H_CANDIDATES = "sms_reminder_24h_candidates",
  SMS_REMINDER_DDAY_CANDIDATES = "sms_reminder_d-day_candidates",
}

export interface UpsertInterviewsConfigurationParams {
  campaign_id: string;
  payload: {
    location: string;
    duration: number;
    additional_information: string;
    messages: string[];
  };
}
