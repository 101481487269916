import { JobsApi } from "@api";
import { QUERY_KEYS } from "@constants";
import { context } from "@opentelemetry/api";
import { useQuery } from "@tanstack/react-query";

export const useAssignedJobs = (userID: string | undefined) => {
  return useQuery({
    queryKey: [QUERY_KEYS.ASSIGNEE_JOBS, userID],
    queryFn: () => JobsApi.listAssigneeJobs(context.active(), userID as string),
    enabled: !!userID,
  });
};
