import { FC, FormEvent, useRef, useState } from "react";

import base64 from "base-64";
import { useTranslation } from "next-i18next";
import { useFormContext } from "react-hook-form";

import { Spinner } from "@components";
import { loadTranslations } from "@lib";
import { Input } from "@styledcomponents";
import { EncodedFiles } from "@typings";

import { FileButton, FileLineContainer, IconContainer } from "./styles";

/**
 * castFileContentToBase64 reads a file as text, and transform it's content to base64.
 * It resolve the return promised with a `EncodedFiles`
 **/
function castFileContentToBase64(file: File) {
  return new Promise(function (resolve: (value: EncodedFiles) => void, reject: (message: string) => void) {
    const fileReader = new FileReader();

    fileReader.onload = function () {
      const encoded: EncodedFiles = {
        filename: file.name,
        content: base64.encode(fileReader.result as string),
        content_type: file.type,
      };
      resolve(encoded);
    };

    fileReader.onerror = function () {
      reject(fileReader.error?.message || "we couldn't read the file");
    };

    fileReader.readAsBinaryString(file);
  });
}

type Props = {
  buttonLabel: string;
  formFieldName: string;
  sizeLimit: number;
};

/**
 * FileInput is a generic file selector, with a bit of logic to
 *   - see which files got selected and remove some of them
 *   - update the form value
 *   - leverage the form to also handle navigation and make sure that we display the list of files as long as the
 *     form values as not been submitted.
 *
 * There is some extra logic to have a spinner and also override the default file input, which doesn't handle
 * file removals.
 *
 * The form values is a list of EncodedFiles (as base64)
 *
 * @param buttonLabel is the select file label
 * @param formFieldName is the form field to update
 * @param sizeLimit is the total size limit
 *
 * @returns
 */
export const FileInput: FC<Props> = ({ buttonLabel, formFieldName, sizeLimit }) => {
  const { t } = useTranslation(["generic-validation"]);
  loadTranslations("generic-validation");

  const { getValues, setValue, setError, clearErrors } = useFormContext();
  const formValues = getValues();
  const initialFormValue: Array<EncodedFiles> = formValues[formFieldName] || [];

  // load the form value to fill the state because we can't rely on the state only, as our form is separated
  // among multiple components.
  const [files, setFiles] = useState(initialFormValue);
  const [isLoading, setLoading] = useState(false);

  // Create a reference to the hidden file input element, so that we can click on it programmatically.
  // we have to use useRef and not just useRef because else we can't mock in tests
  const hiddenFileInput = useRef<HTMLInputElement>(null);

  // Propagate the click to the hidden file input element when the Button component is clicked so that
  // the browsers open the file selection tab.
  const propagateClickToFileSelector = (event) => {
    event.preventDefault(); // to prevent form submission

    if (!hiddenFileInput?.current) return;
    hiddenFileInput.current.click();
  };

  // Handle files selection.
  // It first makes sure that the selected files are not over the sizeLimit
  // then if converts them to base64 and set them in the form value.
  const onFilesSelection = (e: FormEvent<HTMLInputElement>) => {
    const files = e.currentTarget.files || [];

    let fileSize = 0;
    // first loop for the limit. We are looping twice over them, but it's ok since we are not reading them in this loop
    for (const file of files) {
      fileSize += file.size / 1024 / 1024; // MB
      if (fileSize > sizeLimit) {
        setError(formFieldName, { type: "manual", message: t("files_too_big") });
        return;
      }
    }

    clearErrors(formFieldName);
    setLoading(true);

    // create a list of promises to wait for, so that we can set the form value once we've read them all.
    const readers = Array<Promise<EncodedFiles>>();
    for (const file of files) {
      readers.push(castFileContentToBase64(file));
    }

    Promise.all(readers)
      .then((files: EncodedFiles[]) => {
        setValue(formFieldName, files);
        setFiles(files);
      })
      .catch((reason) => {
        setError(formFieldName, { type: "manual", message: reason });
      })
      .finally(() => setLoading(false));
  };

  // Handle file deletion by re-creating a new list, without the file as `idx`.
  // we have to create a new list and we can't use `splice` because it confuses react who thinks that no changes
  // has been made.
  const onFileDeleted = (idx: number) => {
    const newFilesList = files.filter((_, i) => i !== idx);
    setValue(formFieldName, newFilesList);
    setFiles(newFilesList);
  };

  return (
    <div>
      <>
        <FileButton data-testid={`file-input-${formFieldName}-button`} onClick={propagateClickToFileSelector}>
          {buttonLabel}
        </FileButton>
        <Input
          data-testid={`file-input-${formFieldName}`}
          ref={hiddenFileInput}
          style={{ display: "none" }} // not displayed as the click is delegated to the button above.
          multiple
          type="file"
          accept="image/*"
          onChange={onFilesSelection}
        />
      </>
      {isLoading ? (
        // We have a spinner because we are reading the files and converting them to base64,
        // which can take up to a few seconds depending on the size.
        <div style={{ display: "flex", justifyContent: "center" }}>
          <Spinner width="20px" height="20px" style={{ position: "relative", left: 0, top: 0 }} />
        </div>
      ) : (
        files.map((file: EncodedFiles, idx) => (
          <FileLineContainer key={idx}>
            {file.filename}
            <IconContainer
              className="fa-solid fa-xmark"
              data-testid={`file-input-${formFieldName}-delete-${idx}`}
              onClick={() => onFileDeleted(idx)}
            ></IconContainer>
          </FileLineContainer>
        ))
      )}
    </div>
  );
};
