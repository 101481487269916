import { AxiosError } from "axios";

import { PHONE_VALIDATION_STATUS } from "@constants";
import { Context } from "@opentelemetry/api";
import {
  CallHistory,
  InitiateNumberVerificationPayload,
  NumberVerificationResponse,
  VerifyNumberPayload,
} from "@typings";
import { withSpan, withTraceParent } from "@utils";

import { clickToCallAPI } from "./api";

const getToken = async (ctx: Context): Promise<string> =>
  withSpan(ctx, "GetToken", async () => {
    const { data } = await clickToCallAPI.post(`/token`, withTraceParent());
    return data.token;
  });

const getNumberVerificationStatus = async (ctx: Context, phone: string): Promise<NumberVerificationResponse> =>
  withSpan(ctx, "GetNumberVerificationStatus", async () => {
    const { data } = await clickToCallAPI
      .get(`/number_verification_status/${phone}`, withTraceParent())
      .catch((err: AxiosError) => {
        if (err.response?.status === 404) {
          return { data: { status: PHONE_VALIDATION_STATUS.UNVERIFIED } };
        }

        throw err;
      });

    return { status: data.status, phone };
  });

const initiateNumberVerification = async (ctx: Context, payload: InitiateNumberVerificationPayload): Promise<void> =>
  withSpan(ctx, "InitiateNumberVerification", async () => {
    const { data } = await clickToCallAPI.post(`/initiate_number_verification`, payload, withTraceParent());
    return data;
  });

const verifyNumber = async (ctx: Context, payload: VerifyNumberPayload): Promise<string> =>
  withSpan(ctx, "VerifyNumber", async () => {
    const { data } = await clickToCallAPI.post(`/verify_number`, payload, withTraceParent());
    return data.status;
  });

const listApplicationHistories = async (ctx: Context, applicationId: string): Promise<CallHistory[]> =>
  withSpan(ctx, "ListApplicationHistories", async () => {
    const { data } = await clickToCallAPI.get(`/histories/${applicationId}`, withTraceParent());
    return data;
  });

export const ClickToCallAPI = {
  getToken,
  getNumberVerificationStatus,
  initiateNumberVerification,
  verifyNumber,
  listApplicationHistories,
};
