import { captureException } from "@sentry/nextjs";

export const Logger = {
  log: (message: string) => {
    if (process.env.NEXT_PUBLIC_ENVIRONMENT === "production") return;
    // eslint-disable-next-line no-console
    else console.log(message);
  },
  warn: (message: string) => {
    if (process.env.NEXT_PUBLIC_ENVIRONMENT === "production") return;
    // eslint-disable-next-line no-console
    else console.warn(message);
  },
  error: (error: unknown) => {
    if (process.env.NEXT_PUBLIC_ENVIRONMENT === "production") captureException(error);
    // eslint-disable-next-line no-console
    else console.error(error);
  },
};
