import { FC } from "react";

import moment from "moment";

import { LocationOn as LocationIcon } from "@mui/icons-material";
import { Box, Card, Grid, Typography } from "@mui/material";

import { Interview } from "@typings";

type InterviewCardProps = {
  interview: Interview;
};

export const InterviewCard: FC<InterviewCardProps> = ({ interview }) => {
  return (
    <Card
      sx={{ width: "100%", padding: "1rem", border: "1px solid #b6b9db", borderRadius: "0.5rem", boxShadow: "none" }}
    >
      <Grid item xs={12} sx={{ margin: "auto" }}>
        <Box sx={{ float: "right" }}>
          <Typography variant="tagsStrong">
            {moment(interview.time_start, "HH:mm").format("LT")}
            {" - "}
            {moment(interview.time_end, "HH:mm").format("LT")}
          </Typography>
        </Box>
        <Box>
          <Typography variant="tagsStrong">
            {moment(interview.date).format(moment.locale().includes("en") ? "ddd D MMM YYYY" : "ddd DD MMM YYYY")}
          </Typography>
        </Box>
      </Grid>
      <Grid item xs={12} sx={{ margin: "auto", fontSize: "14px" }}>
        <LocationIcon sx={{ width: "24px", height: "24px", position: "relative", top: "3px", marginRight: "0.5rem" }} />
        {interview.location}
      </Grid>
    </Card>
  );
};
