import { Context } from "@opentelemetry/api";
import { withSpan, withTraceParent } from "@utils";

import { authProxyAPI, usersAPI } from "./api";

export interface UpdateUserInfoBody {
  first_name: string;
  last_name: string;
  phone: string;
}
const updateUserInfo = async (ctx: Context, body: Partial<UpdateUserInfoBody>): Promise<void> =>
  withSpan(ctx, "UpdateUserInfo", async () => {
    await usersAPI.patch("/me", body, withTraceParent());
  });

export interface UpdateUserPersonalInformationBody {
  first_name: string;
  last_name: string;
  email: string;
}
const updateUserPersonalInfo = async (ctx: Context, body: Partial<UpdateUserPersonalInformationBody>): Promise<void> =>
  withSpan(ctx, "UpdateUserPersonalInfo", async () => {
    await usersAPI.patch("/me", body, withTraceParent());
  });

export interface UpdateEmailBody {
  password: string;
  email: string;
}
const updateEmail = async (ctx: Context, body: UpdateEmailBody): Promise<void> =>
  withSpan(ctx, "UpdateUserEmail", async () => {
    await authProxyAPI.post("/profile/email", body, withTraceParent());
  });

export interface UpdatePasswordBody {
  old_password: string;
  new_password: string;
  repeat_password: string;
}
const updatePassword = async (ctx: Context, body: UpdatePasswordBody): Promise<void> =>
  withSpan(ctx, "UpdatePassword", async () => {
    await authProxyAPI.post("/profile/password", body, withTraceParent());
  });

export interface InviteUserBody {
  first_name: string;
  last_name: string;
  role: string;
  email: string;
  locale: string;
  group_id: string;
}
export interface InviteUserResult {
  id: string;
}
const sendInvite = async (ctx: Context, body: InviteUserBody): Promise<InviteUserResult> =>
  withSpan(ctx, "SendInvite", async () => {
    const { data } = await usersAPI.post("", body, withTraceParent());
    return data;
  });

export interface UpdateRoleBody {
  id: string;
  body: {
    enabled?: boolean;
    role?: string;
  };
}
const updateRole = async (ctx: Context, body: UpdateRoleBody): Promise<void> =>
  withSpan(ctx, "UpdateRole", async () => {
    const { data } = await usersAPI.patch(`/${body.id}`, body.body, withTraceParent());
    return data;
  });

export const userApi = {
  updateUserInfo,
  updateEmail,
  updatePassword,
  sendInvite,
  updateRole,
  updateUserPersonalInfo,
};
