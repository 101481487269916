import { ParseError, parsePhoneNumberWithError } from "libphonenumber-js";

/**
 * parse the phone number to the international format
 * and return the unparsed value if the parsing failed (no country code or wrong country code)
 * @param phoneNumber
 * @returns string
 */
//
export const parsePhoneNumber = (phoneNumber: string): string => {
  try {
    const phone = parsePhoneNumberWithError(phoneNumber);
    return phone.formatInternational();
  } catch (error) {
    if (error instanceof ParseError) {
      return phoneNumber;
    } else {
      throw "";
    }
  }
};
