import { Context } from "@opentelemetry/api";
import {
  CreateOrganizationRequestFormLinkParams,
  DeleteOrganizationRequestFormLinkParams,
  OrganizationRequestFormLink,
  ReadOrganizationRequestFormLinkParams,
  UpdateOrganizationRequestFormLinkParams,
} from "@typings";
import { withSpan, withTraceParent } from "@utils";

import { organizationsAPI } from "./api";

const create = async (
  ctx: Context,
  params: CreateOrganizationRequestFormLinkParams,
): Promise<OrganizationRequestFormLink> =>
  withSpan(ctx, "CreateOrganizationRequestFormLink", async () => {
    const { data } = await organizationsAPI.post(
      `/${params.organization_id}/request_form_link`,
      { link: params.request_form_link },
      withTraceParent(),
    );
    return data;
  });

const get = async (
  ctx: Context,
  params: ReadOrganizationRequestFormLinkParams,
): Promise<OrganizationRequestFormLink | undefined> =>
  withSpan(ctx, "GetOrganizationRequestFormLink", async () => {
    try {
      const { data } = await organizationsAPI.get(`/${params.organization_id}/request_form_link`, withTraceParent());
      return data;
    } catch (e) {
      if (e?.response?.status === 404) {
        return;
      }

      throw e;
    }
  });

const update = async (
  ctx: Context,
  params: UpdateOrganizationRequestFormLinkParams,
): Promise<OrganizationRequestFormLink> =>
  withSpan(ctx, "UpdateOrganizationRequestFormLink", async () => {
    const { data } = await organizationsAPI.put(
      `/${params.organization_id}/request_form_link`,
      { link: params.request_form_link },
      withTraceParent(),
    );
    return data;
  });

const destroy = async (ctx: Context, params: DeleteOrganizationRequestFormLinkParams): Promise<void> =>
  withSpan(ctx, "DestroyOrganizationRequestFormLink", async () => {
    await organizationsAPI.delete(`/${params.organization_id}/request_form_link`, withTraceParent());
    return;
  });

export const OrganizationRequestFormLinkAPI = {
  create,
  get,
  update,
  destroy,
};
