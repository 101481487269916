import { FC, ReactElement, useMemo, useState } from "react";

import { ApplicationInterviewModal, DeleteInterviewModal } from "components/application/application-interview-modals";
import moment from "moment";

import { Application, Interview } from "@typings";

import { useInterview } from "@hooks/queries";

interface Props {
  application: Application;
  renderCreate?: ({ onClick, disabled }: { onClick: () => void; disabled: boolean }) => ReactElement;
  renderDelete?: ({ onClick }: { onClick: () => void }) => ReactElement;
}

export const InterviewAction: FC<Props> = ({ application, renderCreate, renderDelete }) => {
  const [createInterviewModalOpen, setCreateInterviewModalOpen] = useState(false);
  const [deleteInterviewModalOpen, setDeleteInterviewModalOpen] = useState(false);

  const interviewQuery = useInterview(application.id);

  const applications = useMemo(() => [application], [application]);

  const interview = useMemo<Interview | undefined>(() => {
    // get the active interview
    return interviewQuery.data?.find((interview) => !interview.deleted && interview.getInterviewEndDate() > moment());
  }, [interviewQuery.data]);

  const handleCreate = () => {
    setCreateInterviewModalOpen(true);
  };

  const handleDelete = () => {
    setDeleteInterviewModalOpen(true);
  };

  return (
    <>
      {(!interview || interview.deleted || !renderDelete) && renderCreate && (
        <>
          {renderCreate({
            onClick: handleCreate,
            disabled: interview !== undefined,
          })}
          <ApplicationInterviewModal
            open={createInterviewModalOpen}
            onConfirm={() => setCreateInterviewModalOpen(false)}
            onCancel={() => setCreateInterviewModalOpen(false)}
            applications={applications}
          />
        </>
      )}

      {interview && !interview.deleted && renderDelete && (
        <>
          {renderDelete({
            onClick: handleDelete,
          })}
          <DeleteInterviewModal
            open={deleteInterviewModalOpen}
            setOpen={setDeleteInterviewModalOpen}
            interview={interview}
          />
        </>
      )}
    </>
  );
};
