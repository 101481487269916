import { ClickToCallAPI } from "@api";
import { PHONE_VALIDATION_STATUS, QUERY_KEYS } from "@constants";
import { context } from "@opentelemetry/api";
import { UseQueryResult, useQueries, useQuery } from "@tanstack/react-query";
import { NumberVerificationResponse } from "@typings";
import { combineQueries } from "@utils";

export const usePhoneNumberVerificationStatus = (phone: string) => {
  const numberStatusQuery = useQuery({
    queryKey: [QUERY_KEYS.CLICK_TO_CALL_NUMBER_VERIFICATION_STATUS, phone],
    queryFn: () => ClickToCallAPI.getNumberVerificationStatus(context.active(), phone),
    enabled: !!phone,
  });

  return {
    isLoading: numberStatusQuery.isLoading,
    error: numberStatusQuery.error,
    status: numberStatusQuery.data?.status || PHONE_VALIDATION_STATUS.UNVERIFIED,
    verified: numberStatusQuery.data?.status === PHONE_VALIDATION_STATUS.VERIFIED,
  };
};

export const usePhoneNumbersVerificationsStatuses = (phones: string[]) => {
  return useQueries({
    queries: phones.map((phone) => ({
      queryKey: [QUERY_KEYS.CLICK_TO_CALL_NUMBER_VERIFICATION_STATUS, phone],
      queryFn: () => ClickToCallAPI.getNumberVerificationStatus(context.active(), phone),
    })),
    combine: combineQueries<NumberVerificationResponse, Record<string, string>>({}, (acc, res) => {
      acc[res.phone] = res.status;
      return acc;
    }),
  });
};
