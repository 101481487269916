import { useRouter } from "next/router";

import { useCallback, useEffect, useMemo } from "react";

import { MRT_TableInstance } from "material-react-table";
import { useSession } from "next-auth/react";
import { useTranslation } from "next-i18next";

import { JobsApi } from "@api";
import { ManagedMaterialReactTableCategory } from "@components";
import { QUERY_KEYS } from "@constants";
import { loadTranslations } from "@lib";
import { context } from "@opentelemetry/api";
import { useQuery } from "@tanstack/react-query";

import { ListJobItemWithStats } from "../types";

export const useJobsCategories = (table: MRT_TableInstance<ListJobItemWithStats>) => {
  const { t } = useTranslation(["jobs-list"]);
  loadTranslations("jobs-list");

  const router = useRouter();

  const { data: session } = useSession();

  const { getColumn } = table;

  const column = getColumn("id");
  const jobsFacetedValues = column.getFacetedUniqueValues();
  const currentFilterValue = (column.getFilterValue() as string[]) ?? [];

  const { data: assignedJobs } = useQuery({
    queryKey: [QUERY_KEYS.ASSIGNEE_JOBS, session?.user.id],
    queryFn: () => JobsApi.listAssigneeJobs(context.active(), session?.user.id as string),
    enabled: !!session?.user.id,
  });

  const getCategoryCount = useCallback(
    (category: string) => {
      let count = 0;
      if (category === "all") {
        jobsFacetedValues.forEach((value) => {
          count += value;
        });
      } else if (category === "my_offers") {
        assignedJobs?.forEach((jobID) => {
          count += jobsFacetedValues.get(jobID) ?? 0;
        });
      }
      return count;
    },
    [assignedJobs, jobsFacetedValues],
  );

  const categories = useMemo<ManagedMaterialReactTableCategory[]>(
    () => [
      {
        value: "all",
        id: "job_offers_all",
        selected: currentFilterValue.length === 0,
        displayValue: t("categories.all"),
        count: getCategoryCount("all"),
      },
      {
        value: "my_offers",
        id: "job_offers_my_offers",
        selected: currentFilterValue.length > 0,
        displayValue: t("categories.my_offers"),
        count: getCategoryCount("my_offers"),
      },
    ],
    [currentFilterValue.length, t, getCategoryCount],
  );

  const onCategoryChange = useCallback(
    (category: string) => {
      column.setFilterValue(category === "my_offers" ? assignedJobs : []);
    },
    [assignedJobs, column],
  );

  useEffect(() => {
    const offersCategory = router.query.offers_category as string;
    if (["all", "my_offers"].includes(offersCategory)) {
      onCategoryChange(offersCategory);
    }
  }, [onCategoryChange, router.query.offers_category]);

  return { categories, onCategoryChange };
};
