import { ApplicationApi } from "@api";
import { QUERY_KEYS, STATUSES_WITH_REASON } from "@constants";
import { context } from "@opentelemetry/api";
import { UndefinedInitialDataOptions, useQuery } from "@tanstack/react-query";
import { Application, ApplicationListItem, ApplicationStatus, Status } from "@typings";

export const useApplicationStatusReason = (
  status?: ApplicationStatus,
  application?: Application | ApplicationListItem,
) => {
  return useQuery({
    queryKey: [QUERY_KEYS.APPLICATION_STATUS_REASON, application?.id, status?.id],
    enabled: status != null && application != null && STATUSES_WITH_REASON.includes(status.label),
    queryFn: () =>
      ApplicationApi.getApplicationStatusReason(context.active(), {
        application_id: application?.id ?? "",
        status_id: status?.id ?? "",
      }),
  });
};

export const useApplicationStatuses = (
  options?: Partial<UndefinedInitialDataOptions<Status[], Error, Status[], string[]>>,
) => {
  return useQuery({
    queryKey: [QUERY_KEYS.APPLICATIONS_STATUSES],
    queryFn: () => ApplicationApi.listStatuses(context.active()),
    refetchOnWindowFocus: true,
    gcTime: Infinity,
    ...options,
  });
};
