import { FC, ReactNode, useMemo, useState } from "react";

import { MRT_ColumnDef, MaterialReactTable, useMaterialReactTable } from "material-react-table";
import moment from "moment/moment";
import { useSession } from "next-auth/react";
import { useTranslation } from "next-i18next";
import { TFunction } from "react-i18next";

import { Chip, Stack, Typography, tablePaginationClasses } from "@mui/material";

import { Actions, AddButton, InviteNewUserModal, LoadingAnimation, SettingsPageForm, Spinner } from "@components";
import { PHONE_VALIDATION_STATUS, UserPermissions } from "@constants";
import { loadTranslations } from "@lib";
import { MRT_Localization_EN, MRT_Localization_FR } from "@locales";
import { Row } from "@tanstack/react-table";
import { ApplicationListItem, AuthProxyUsersListItem } from "@typings";
import { sortEnum } from "@utils";

import { useHasPermissions, useUserGroup } from "@hooks";
import { useOrganizationUsers, usePhoneNumbersVerificationsStatuses } from "@hooks/queries";

const getPhoneStatusLabelText = (t: TFunction, status: string): string => {
  switch (status) {
    case PHONE_VALIDATION_STATUS.VERIFIED:
      return t("organization-settings:users.columns.phoneNumberStatus.values.verified");
    case PHONE_VALIDATION_STATUS.UNVERIFIED:
      return t("organization-settings:users.columns.phoneNumberStatus.values.unverified");
    default:
      return t("organization-settings:users.columns.phoneNumberStatus.values.incomplete");
  }
};

const getPhoneStatusLabelBackgroundColor = (status: string): string => {
  switch (status) {
    case PHONE_VALIDATION_STATUS.VERIFIED:
      return "var(--color-palette-status-bg-green)";
    case PHONE_VALIDATION_STATUS.UNVERIFIED:
      return "var(--color-palette-status-bg-purple)";
    default:
      return "var(--bg-scoring-unanswered)";
  }
};

const BasicCell: FC<{ children: string }> = ({ children }) => (
  <Typography fontWeight={400} fontSize="var(--font-size-100)" lineHeight="150%" color="var(--color-palette-base-800)">
    {children}
  </Typography>
);

const ChipCell: FC<{ backgroundColor: string; children: ReactNode }> = ({ backgroundColor, children }) => (
  <Chip
    label={children}
    sx={{
      fontSize: "var(--font-size-85)",
      padding: "var(--space-4) var(--space-8)",
      height: "fit-content",
      backgroundColor,
      color: "var(--color-palette-base-800)",
      borderRadius: "var(--border-radius-1)",
      ".MuiChip-label": {
        padding: 0,
      },
    }}
  />
);

const useUsersColumns = () => {
  const { t } = useTranslation(["organization-settings"]);
  loadTranslations("organization-settings");

  const userGroup = useUserGroup();
  const users = useOrganizationUsers(userGroup);
  const userPhones = useMemo(() => (users.data ?? []).map((user) => user.phone), [users.data]);
  const phoneNumberStatuses = usePhoneNumbersVerificationsStatuses(userPhones);

  return useMemo<MRT_ColumnDef<AuthProxyUsersListItem>[]>(
    () => [
      {
        id: "name",
        header: t("organization-settings:users.columns.name.header"),
        flex: 1,
        enableGlobalFilter: true,
        accessorFn: (row) =>
          t("organization-settings:users.columns.name.display", { firstName: row.first_name, lastName: row.last_name }),
        Cell: ({ row }) => (
          <BasicCell>
            {t("organization-settings:users.columns.name.display", {
              firstName: row.original.first_name,
              lastName: row.original.last_name,
            })}
          </BasicCell>
        ),
        enableSorting: true,
        sortingFn: (rowA, rowB) => {
          const nameA = t("organization-settings:users.columns.name.display", {
            firstName: rowA.original.first_name,
            lastName: rowA.original.last_name,
          });
          const nameB = t("organization-settings:users.columns.name.display", {
            firstName: rowB.original.first_name,
            lastName: rowB.original.last_name,
          });
          return nameA.localeCompare(nameB);
        },
      },
      {
        id: "status",
        header: t("organization-settings:users.columns.status.header"),
        flex: 1,
        enableGlobalFilter: false,
        accessorFn: (row) => row.active,
        Cell: ({ row }) => (
          <ChipCell
            backgroundColor={
              row.original.active ? "var(--color-palette-status-bg-green)" : "var(--color-palette-pastel-purple)"
            }
          >
            {row.original.active
              ? t("organization-settings:users.columns.status.values.activated")
              : t("organization-settings:users.columns.status.values.pending")}
          </ChipCell>
        ),
        enableColumnFilter: true,
        filterFn: (row, columnID, filterValue) => {
          if (filterValue && Array.isArray(filterValue) && filterValue.length > 0) {
            const rowValue: boolean = row.getValue(columnID);
            return rowValue ? filterValue.includes("activated") : filterValue.includes("pending");
          }

          return true;
        },
        enableSorting: true,
        sortingFn: (rowA, rowB) => sortEnum(rowA.original.active, rowB.original.active, [true, false]),
      },
      {
        id: "phone_number_status",
        header: t("organization-settings:users.columns.phoneNumberStatus.header"),
        flex: 1,
        enableGlobalFilter: false,
        accessorFn: (row) => phoneNumberStatuses[row.phone],
        Cell: ({ row }) =>
          // If we have no data, it has not loaded yet.
          phoneNumberStatuses[row.original.phone] ? (
            <ChipCell backgroundColor={getPhoneStatusLabelBackgroundColor(phoneNumberStatuses[row.original.phone])}>
              {getPhoneStatusLabelText(t, phoneNumberStatuses[row.original.phone])}
            </ChipCell>
          ) : (
            <LoadingAnimation />
          ),
        filterFn: "arrayIncludes",
        enableSorting: true,
        sortingFn: (rowA, rowB) =>
          sortEnum(phoneNumberStatuses[rowA.original.phone], phoneNumberStatuses[rowB.original.phone], [
            PHONE_VALIDATION_STATUS.VERIFIED,
            PHONE_VALIDATION_STATUS.UNVERIFIED,
          ]),
      },
      {
        id: "role",
        header: t("organization-settings:users.columns.role.header"),
        flex: 1,
        enableGlobalFilter: false,
        accessorFn: (row) => row.role,
        Cell: ({ row }) => (
          <BasicCell>{t(`organization-settings:users.columns.role.values.${row.original.role}`)}</BasicCell>
        ),
        filterFn: "arrayIncludes",
        enableSorting: true,
        sortingFn: (rowA, rowB) =>
          sortEnum(rowA.original.role, rowB.original.role, ["super_admin", "admin", "recruiter"]),
      },
      {
        id: "email",
        header: t("organization-settings:users.columns.email.header"),
        flex: 1,
        enableGlobalFilter: true,
        accessorFn: (row) => row.email,
        Cell: ({ row }) => <BasicCell>{row.original.email}</BasicCell>,
        enableSorting: true,
        sortingFn: (rowA, rowB) => rowA.original.email.localeCompare(rowB.original.email),
      },
      {
        id: "phone_number",
        header: t("organization-settings:users.columns.phoneNumber.header"),
        flex: 1,
        enableGlobalFilter: true,
        accessorFn: (row) => row.phone,
        Cell: ({ row }) => <BasicCell>{row.original.phone}</BasicCell>,
        enableSorting: true,
        sortingFn: (rowA, rowB) => rowA.original.phone.localeCompare(rowB.original.phone),
      },
      {
        id: "last_activity",
        header: t("organization-settings:users.columns.lastActivity.header"),
        flex: 1,
        enableGlobalFilter: false,
        accessorFn: (row) => moment(row.last_activity).format("L LT"),
        Cell: ({ row }) => (
          <BasicCell>{row.original.last_activity ? moment(row.original.last_activity).format("L LT") : "-"}</BasicCell>
        ),
        enableSorting: true,
        sortingFn: (rowA, rowB) => {
          if (rowA.original.last_activity == null) return -1;
          if (rowB.original.last_activity == null) return 1;
          return moment(rowA.original.last_activity).diff(rowB.original.last_activity) > 0 ? 1 : -1;
        },
      },
    ],
    [phoneNumberStatuses, t],
  );
};

export const UsersTab = () => {
  const { t } = useTranslation(["organization-settings"]);
  loadTranslations("organization-settings");

  const { data: session } = useSession();

  const canEditUsers = useHasPermissions([UserPermissions.UsersPermissions.Write]);

  const [createUserModalOpened, setCreateUserModalOpened] = useState(false);

  const userGroup = useUserGroup();
  const users = useOrganizationUsers(userGroup);

  const columns = useUsersColumns();

  const table = useMaterialReactTable<AuthProxyUsersListItem>({
    localization: session?.user?.locale === "en" ? MRT_Localization_EN : MRT_Localization_FR,
    columns,
    data: users.data ?? [],
    state: {
      isLoading: users.isLoading,
    },
    initialState: {
      pagination: {
        pageSize: 50,
        pageIndex: 0,
      },
    },
    enableDensityToggle: false,
    enableFullScreenToggle: false,
    enableRowSelection: true,
    enableColumnFilters: false,
    enableGlobalFilter: true,
    enableHiding: false,
    enableTopToolbar: false,
    enableFacetedValues: true,
    selectAllMode: "all",
    paginationDisplayMode: "pages",
    positionActionsColumn: "last",
    globalFilterFn: "fuzzy",
    enableRowActions: canEditUsers,
    renderRowActions: ({ row }) => <Actions user={row.original} />,

    filterFns: {
      arrayIncludes: (row: Row<ApplicationListItem>, columnId: string, filterValue?: string[]) =>
        filterValue == null || filterValue.length === 0 || filterValue.includes(row.getValue(columnId)),
    },
    muiTableBodyCellProps: () => ({
      sx: (theme) => ({
        ...theme.typography.body,
        color: theme.palette.text.mainInfo,
        cursor: "pointer",
      }),
    }),
    muiTableHeadCellProps: {
      sx: (theme) => ({
        ...theme.typography.body,
        color: theme.palette.color.BASE[500],
      }),
    },
    muiBottomToolbarProps: {
      sx: (theme) => ({
        [`& .${tablePaginationClasses.root} label`]: {
          ...theme.typography.tags,
        },
      }),
    },
    muiPaginationProps: {
      color: "deepPurple",
      rowsPerPageOptions: [10, 25, 50],
    },
  });

  if (users.isLoading) {
    return <Spinner style={{ position: "relative" }} />;
  }

  return (
    <>
      <SettingsPageForm fullWidth title={t("organization-settings:users.title")}>
        <Stack
          flexDirection="row"
          alignItems="center"
          justifyContent="space-between"
          gap={(theme) => theme.spacings[12]}
        >
          <Stack></Stack>
          {canEditUsers ? (
            <AddButton onClick={() => setCreateUserModalOpened(true)}>
              {t("organization-settings:users.invite")}
            </AddButton>
          ) : null}
        </Stack>
        <MaterialReactTable table={table} />
      </SettingsPageForm>

      <InviteNewUserModal open={createUserModalOpened} onClose={() => setCreateUserModalOpened(false)} />
    </>
  );
};
