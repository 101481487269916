import Image from "next/image";

import { FC } from "react";

import { Button, Stack, Typography } from "@mui/material";

import filterEye from "../../public/assets/filter-eye.png";

interface Props {
  label: string;
  buttonLabel: string;
  onClick: () => void;
}

export const NoResults: FC<Props> = ({ label, buttonLabel, onClick }) => {
  return (
    <Stack
      id="no_results"
      direction="column"
      gap={(theme) => theme.spacings[24]}
      padding={(theme) => theme.spacings[16]}
      alignItems="center"
      height="100%"
      sx={{
        backgroundColor: "white",
      }}
    >
      <Image src={filterEye} alt="no-data" />
      <Typography variant="body" textAlign="center" whiteSpace="pre-line">
        {label}
      </Typography>
      <Button variant="contained" color="deepPurple" onClick={onClick}>
        {buttonLabel}
      </Button>
    </Stack>
  );
};
