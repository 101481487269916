import { useMemo } from "react";

import { ApplicationListItem } from "@typings";

import { useCampaignQuestionsQueries } from "@hooks/queries";

export const useCampaignQuestions = (campaignIDs: string[], applications: ApplicationListItem[]) => {
  const applicationsQuestions = useCampaignQuestionsQueries(...campaignIDs);

  // Filter out questions that do not have a match in any application.
  return useMemo(
    () =>
      applicationsQuestions.filter((campaignQuestion) =>
        (applications ?? []).some((application) =>
          (application.answers ?? []).some(
            (applicationAnswer) =>
              applicationAnswer.question_label === campaignQuestion.label && !!applicationAnswer.answer,
          ),
        ),
      ),
    [applicationsQuestions, applications],
  );
};
