import { styled } from "@mui/material/styles";

export const FileLineContainer = styled("div")`
  display: flex;
  align-items: center;
`;

export const IconContainer = styled("i")`
  cursor: pointer;
  font-size: 1.3em;
  padding-left: 1em;
`;

export const FileButton = styled("button")`
  width: 150px;
  background: white;

  font-weight: bold;
  color: #d15b1b;

  border: 1px solid #d15b1b;
  border-radius: 10px;
  cursor: pointer;

  padding: 10px 5px;
  margin: 10px 0px 10px 5px;
`;
