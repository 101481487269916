import { FC, useCallback, useMemo, useState } from "react";

import moment from "moment";
import { useTranslation } from "next-i18next";

import { Assignment as AssignmentIcon } from "@mui/icons-material";
import { Box, Button, Grid, Typography } from "@mui/material";

import { InterviewCard } from "@components";
import { TEXT_COLOR } from "@constants";
import { loadTranslations } from "@lib";
import { Application, Interview, InterviewsConfiguration } from "@typings";

import { useInterview } from "@hooks/queries";

import { ApplicationInterviewModal, DeleteInterviewModal, InterviewDetailsModal } from "./application-interview-modals";

type ApplicationInterviewProps = {
  application: Application;
  interviewConfiguration: InterviewsConfiguration | undefined;
};

interface CreateInterviewUIProps {
  setCreateInterviewModalOpen: (open: boolean) => void;
  createInterviewModalOpen: boolean;
  application: Application;
}

const CreateInterviewUI: FC<CreateInterviewUIProps> = ({
  setCreateInterviewModalOpen,
  createInterviewModalOpen,
  application,
}) => {
  const { t } = useTranslation(["application-interview"]);
  loadTranslations("application-interview");

  return (
    <>
      <Grid item xs={8} sx={{ margin: "auto" }}>
        <Box aria-label="compatibility" role="region">
          <AssignmentIcon sx={{ width: "24px", height: "24px", marginRight: "12px", float: "left" }} />
          <Typography variant="bodyCopyStrong" color={TEXT_COLOR.mainInfo}>
            {t("no_interview_scheduled")}
          </Typography>
        </Box>
      </Grid>
      <Grid item xs={4}>
        <Button
          sx={{ float: "right", marginLeft: "1px" }}
          variant="contained"
          color="deepPurple"
          onClick={() => {
            setCreateInterviewModalOpen(true);
          }}
          id="schedule_interview"
        >
          {t("create_interview")}
        </Button>
      </Grid>

      <ApplicationInterviewModal
        open={createInterviewModalOpen}
        onConfirm={useCallback(() => setCreateInterviewModalOpen(false), [setCreateInterviewModalOpen])}
        onCancel={useCallback(() => setCreateInterviewModalOpen(false), [setCreateInterviewModalOpen])}
        applications={useMemo(() => [application], [application])}
      />
    </>
  );
};

interface InterviewDetailUIProps {
  interview: Interview;
  deleteInterviewModalOpen: boolean;
  setDeleteInterviewModalOpen: (open: boolean) => void;
  detailsInterviewModalOpen: boolean;
  setDetailsInterviewModalOpen: (open: boolean) => void;
}

const InterviewDetailUI: FC<InterviewDetailUIProps> = ({
  interview,
  setDeleteInterviewModalOpen,
  detailsInterviewModalOpen,
  setDetailsInterviewModalOpen,
  deleteInterviewModalOpen,
}) => {
  const { t } = useTranslation(["application-interview"]);
  loadTranslations("application-interview");

  return (
    <>
      <Grid container>
        <Grid item xs={6} sx={{ margin: "auto" }}>
          <Box aria-label="compatibility" role="region">
            <AssignmentIcon sx={{ width: "24px", height: "24px", marginRight: "12px", float: "left" }} />
            <Typography variant="bodyCopyStrong" color={TEXT_COLOR.mainInfo}>
              {t("interview_scheduled")}
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={6}>
          <Button
            sx={{
              float: "right",
            }}
            variant="contained"
            color="deepPurple"
            onClick={() => {
              setDeleteInterviewModalOpen(true);
            }}
          >
            {t("cancel_interview")}
          </Button>
          <Button
            sx={{
              float: "right",
              marginRight: "0.5rem",
            }}
            variant="outlined"
            onClick={() => {
              setDetailsInterviewModalOpen(true);
            }}
            color="primary"
          >
            {t("details")}
          </Button>
        </Grid>
      </Grid>
      <Grid container sx={{ paddingTop: "1rem" }}>
        <InterviewCard interview={interview} />
      </Grid>

      <InterviewDetailsModal
        open={detailsInterviewModalOpen}
        setOpen={setDetailsInterviewModalOpen}
        interview={interview}
      />

      <DeleteInterviewModal
        open={deleteInterviewModalOpen}
        setOpen={setDeleteInterviewModalOpen}
        interview={interview}
      />
    </>
  );
};

export const ApplicationInterview: FC<ApplicationInterviewProps> = ({ application, interviewConfiguration }) => {
  const [createInterviewModalOpen, setCreateInterviewModalOpen] = useState(false);
  const [deleteInterviewModalOpen, setDeleteInterviewModalOpen] = useState(false);
  const [detailsInterviewModalOpen, setDetailsInterviewModalOpen] = useState(false);

  const interviewQuery = useInterview(application.id);

  const interview = useMemo<Interview | undefined>(() => {
    // get the active interview
    return interviewQuery.data?.find((interview) => !interview.deleted && interview.getInterviewEndDate() > moment());
  }, [interviewQuery.data]);

  return (
    <Grid container sx={{ padding: "24px 40px 24px 40px" }} id="application_tab_content_interviews">
      {interview && !interview.deleted ? (
        <InterviewDetailUI
          interview={interview}
          detailsInterviewModalOpen={detailsInterviewModalOpen}
          deleteInterviewModalOpen={deleteInterviewModalOpen}
          setDeleteInterviewModalOpen={setDeleteInterviewModalOpen}
          setDetailsInterviewModalOpen={setDetailsInterviewModalOpen}
        />
      ) : null}

      {(!interview || interview.deleted) && interviewConfiguration != null ? (
        <CreateInterviewUI
          application={application}
          createInterviewModalOpen={createInterviewModalOpen}
          setCreateInterviewModalOpen={setCreateInterviewModalOpen}
        />
      ) : null}
    </Grid>
  );
};
