import { Context } from "@opentelemetry/api";
import { ExportHistory } from "@typings";
import { withSpan, withTraceParent } from "@utils";

import { RJSFSchema, UiSchema } from "@rjsf/utils";

import { atsAPI } from "./api";

const getExportHistories = async (ctx: Context, application_id: string): Promise<ExportHistory[]> =>
  withSpan(ctx, "GetExportHistories", async () => {
    const { data } = await atsAPI.get(
      `/export-histories`,
      withTraceParent({
        params: { application_external_id: application_id },
      }),
    );
    return data;
  });

const getExporterJSONSchema = async (ctx: Context, workflowPath: string): Promise<RJSFSchema> =>
  withSpan(ctx, "GetExportJSONSchema", async () => {
    const { data } = await atsAPI.get(`/exporters/${workflowPath}/json-schema`, withTraceParent());
    return data;
  });

const getExporterUISchema = async (ctx: Context, workflowPath: string): Promise<UiSchema> =>
  withSpan(ctx, "GetExportUISchema", async () => {
    const { data } = await atsAPI.get(`/exporters/${workflowPath}/ui-schema`, withTraceParent());
    return data;
  });

export const AtsAPI = {
  getExportHistories,
  getExporterJSONSchema,
  getExporterUISchema,
};
