import { FC } from "react";

import { Button, ButtonProps } from "@mui/material";

import { WithMergeableTheme, withThemes } from "@work4Labs/design-system";

const ButtonSX = {
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  padding: "var(--space-12)",
  borderRadius: "var(--size-8)",
  backgroundColor: "var(--color-palette-deep-purple)",
  color: "var(--color-palette-base-000)",
  gap: "var(--size-4)",
  fontSize: "var(--font-size-85)",
  fontWeight: 700,
  lineHeight: "150%",
  border: "none",
  outline: "none",
  height: "auto",
  "&:hover": {
    backgroundColor: "var(--color-palette-deep-purple)",
    border: "none",
    outline: "none",
  },
};

export const BaseButton: FC<WithMergeableTheme<ButtonProps>> = ({ children, sx, ...props }) => (
  <Button sx={withThemes(ButtonSX, sx)} variant="contained" {...props}>
    {children}
  </Button>
);
