import { FC, ReactNode } from "react";

import { useTranslation } from "next-i18next";
import { Controller, useFormContext } from "react-hook-form";

import {
  FormControl,
  FormControlLabel,
  FormHelperText,
  InputLabel,
  Radio,
  RadioGroup,
  RadioGroupProps,
} from "@mui/material";

import { loadTranslations } from "@lib";
import { IOptionItem } from "@typings";

type FormRadioGroupProps = RadioGroupProps & {
  name: string;
  label?: ReactNode;
  additionalInformation?: ReactNode;
  helperText?: ReactNode;
  options: Array<IOptionItem>;
  required?: boolean;
  size?: "small" | "medium";
};

export const FormRadioGroup: FC<FormRadioGroupProps> = ({
  name,
  label,
  additionalInformation,
  helperText,
  required,
  options,
  size,
  ...rest
}) => {
  const {
    control,
    formState: { errors },
  } = useFormContext();

  const { t } = useTranslation("common");
  loadTranslations("common");

  const errorMessage = errors[`${name}`]?.message;
  const isError = !!errorMessage;

  return (
    <Controller
      control={control}
      name={name}
      render={({ field }) => {
        return (
          <FormControl component="fieldset" error={isError}>
            <InputLabel shrink error={isError} htmlFor={name} sx={{ display: "list-item" }}>
              {label || ""}
              {label && !required && (
                <FormHelperText sx={{ display: "inline-block", marginLeft: "5px" }}>{t("optional")}</FormHelperText>
              )}
              {additionalInformation && (
                <FormHelperText
                  sx={(theme) => ({
                    fontSize: "14px",
                    color: theme.palette.text.secondary,
                  })}
                >
                  {additionalInformation}
                </FormHelperText>
              )}
              {helperText && <FormHelperText>{helperText}</FormHelperText>}
            </InputLabel>

            <RadioGroup
              // value="" // value should be coming from tge
              {...rest}
              {...field}
              row
              onChange={(e, value) => {
                field.onChange(e);
                rest.onChange?.(e, value);
              }}
            >
              {options.map((item) => (
                <FormControlLabel
                  className={item.value === field.value ? "checked" : ""}
                  checked={item.value === field.value}
                  key={item.value}
                  value={item.value}
                  control={<Radio size={size} />}
                  label={item.label}
                />
              ))}
            </RadioGroup>
            {isError && <FormHelperText error={isError}>{errorMessage as string}</FormHelperText>}
          </FormControl>
        );
      }}
    />
  );
};
