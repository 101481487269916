import { FC, useMemo } from "react";

import moment from "moment";
import { useTranslation } from "next-i18next";

import { PersonAddAlt as PersonAddIcon } from "@mui/icons-material";
import Timeline from "@mui/lab/Timeline";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineDot from "@mui/lab/TimelineDot";
import TimelineItem, { timelineItemClasses } from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import { Box, Typography } from "@mui/material";

import { ApplicationApi, AtsAPI, ClickToCallAPI, InterviewApi, MessagingApi } from "@api";
import { QUERY_KEYS, TEXT_COLOR } from "@constants";
import { loadTranslations } from "@lib";
import { context } from "@opentelemetry/api";
import { useQuery } from "@tanstack/react-query";
import {
  Application,
  ApplicationExportHistory,
  ApplicationHistory,
  ApplicationShare,
  ApplicationStatus,
  CallHistory,
  CallHistoryStatusEnum,
  Comment,
  DocumentsLogs,
  ExportHistoryStatus,
  Interview,
  InterviewWasDone,
  MessagingHistoryReminder,
  RecruiterReminder,
} from "@typings";
import { ensureArray } from "@utils";

import { AddCommentTextArea } from "./add-comment-textarea";
import { ApplicationShareDetail } from "./application-share-detail";
import { CallHistoryDetail } from "./call-history-detail";
import { CommentDetail } from "./comment-detail";
import { DocumentLogsDetail } from "./documents-logs-detail";
import { ExportHistoryDetail } from "./export-history-detail";
import { InterviewDetail } from "./interview-detail";
import { InterviewDoneDetail } from "./interview-done-detail";
import { MessagesHistoryDetail } from "./messages-history-detail";
import { RecruiterReminderDetail } from "./recruiter-reminder-detail";
import { StatusDetail } from "./status-detail";
import { CommentListSubInfo } from "./style";

type CommentListProps = {
  application: Application;
};

export const CommentList: FC<CommentListProps> = ({ application }) => {
  const { t } = useTranslation(["comment-list", "dates"]);
  loadTranslations("comment-list");
  loadTranslations("dates");

  const { data: exportHistories } = useQuery({
    queryKey: [QUERY_KEYS.EXPORT_HISTORIES, application.id],
    queryFn: () => AtsAPI.getExportHistories(context.active(), application.id),
    refetchInterval: process.env.NEXT_PUBLIC_ENVIRONMENT !== "dev" ? 5000 : 0, // refetch every 5 seconds
    select: (data): ApplicationExportHistory[] | undefined =>
      data?.reduce((res, history) => {
        // if export is still running or in error state we only show
        // that the export was started but not the status
        if (
          [ExportHistoryStatus.Running, ExportHistoryStatus.Error, ExportHistoryStatus.Done].includes(history.status)
        ) {
          res.push({
            id: history.id,
            user_id: history.user_id,
            status: ExportHistoryStatus.Started,
            created_at: history.duration.duration_start,
          } as ApplicationExportHistory);
        }

        // if the export is successful we need two entries
        // one to show that the export started
        // and for other to show that the export ended
        if (history.status === ExportHistoryStatus.Done) {
          res.push({
            id: history.id,
            user_id: history.user_id,
            status: history.status,
            created_at: history.duration.duration_end,
          } as ApplicationExportHistory);
        }

        return res;
      }, [] as ApplicationExportHistory[]),
  });

  const historiesQuery = useQuery({
    queryKey: [QUERY_KEYS.MESSAGING_HISTORIES, application.id],
    queryFn: () =>
      MessagingApi.getHistories(context.active(), {
        application_id: application.id,
      }),
    refetchInterval: process.env.NEXT_PUBLIC_ENVIRONMENT !== "dev" ? 5000 : 0, // refetch every 5 seconds
  });

  const documentsLogsQuery = useQuery({
    queryKey: [QUERY_KEYS.APPLICATION_DOCUMENTS_LOGS, application.id],
    queryFn: () => ApplicationApi.listDocumentsLogs(context.active(), application.id),
    refetchInterval: process.env.NEXT_PUBLIC_ENVIRONMENT !== "dev" ? 5000 : 0, // refetch every 5 seconds
  });

  const interviewQuery = useQuery({
    queryKey: [QUERY_KEYS.INTERVIEW, application.id],
    queryFn: () => InterviewApi.getInterview(context.active(), application.id),
    refetchInterval: process.env.ENVIRONMENT !== "dev" ? 5000 : 0, // refetch every 5 seconds
  });

  const remindersQuery = useQuery({
    queryKey: [QUERY_KEYS.MESSAGING_REMINDERS, application.id],
    queryFn: () =>
      MessagingApi.getReminders(context.active(), {
        application_id: application.id,
      }),
    refetchInterval: process.env.NEXT_PUBLIC_ENVIRONMENT !== "dev" ? 5000 : 0, // refetch every 5 seconds
  });

  const recruiterReminderQuery = useQuery({
    queryKey: [QUERY_KEYS.MESSAGING_RECRUITER_REMINDERS, application.id],
    queryFn: () =>
      MessagingApi.listRecruiterReminder(context.active(), {
        applicationID: application.id,
      }),
    refetchInterval: process.env.NEXT_PUBLIC_ENVIRONMENT !== "dev" ? 5000 : 0, // refetch every 5 seconds
  });

  const callHistoriesQuery = useQuery({
    queryKey: [QUERY_KEYS.CLICK_TO_CALL_APPLICATION_HISTORIES, application.id],
    queryFn: () => ClickToCallAPI.listApplicationHistories(context.active(), application.id),
    select: (data): CallHistory[] | undefined => {
      return data?.filter((history) => history.status !== CallHistoryStatusEnum.INITIATED);
    },
    refetchInterval: process.env.NEXT_PUBLIC_ENVIRONMENT !== "dev" ? 10000 : 0, // refetch every 10 seconds
  });

  const applicationSharesQuery = useQuery({
    queryKey: [QUERY_KEYS.APPLICATION_SHARES, application.id],
    queryFn: () => ApplicationApi.listApplicationShares(context.active(), application.id),
    refetchInterval: process.env.ENVIRONMENT !== "dev" ? 5000 : 0, // refetch every 5 seconds
  });

  const messagingHistoryReminderList = useMemo(() => {
    if (historiesQuery.data && remindersQuery.data) {
      const arr: MessagingHistoryReminder[] = remindersQuery.data
        ?.map((i) => ({
          pipelineId: i.pipeline_id,
          plannedList: remindersQuery.data.filter((j) => j.pipeline_id === i.pipeline_id).map((k) => k.planned_at),
          created_at: i.created_at,
        }))
        .filter((i, p, a) => a.findIndex((t) => t.pipelineId === i.pipelineId) === p);
      historiesQuery.data?.forEach((i, idx) => {
        const index = arr.findIndex((j) => j.pipelineId === i.pipeline_id);
        if (index != -1) {
          arr[index].created_at = historiesQuery.data[idx].created_at;
          arr[index].userId = historiesQuery.data[idx].user_id;
        } else {
          arr?.push({
            pipelineId: historiesQuery.data[idx].pipeline_id,
            created_at: historiesQuery.data[idx].created_at,
            userId: historiesQuery.data[idx].user_id,
          });
        }
      });
      return arr;
    } else {
      return [];
    }
  }, [historiesQuery.data, remindersQuery.data]);

  const applicationDocumentLogsList = useMemo(() => {
    return documentsLogsQuery.data ?? [];
  }, [documentsLogsQuery.data]);

  const recruiterReminderList = useMemo(() => {
    return recruiterReminderQuery.data ?? [];
  }, [recruiterReminderQuery.data]);

  const applicationHistoriesList = useMemo(() => {
    const interviewInList: (Interview | InterviewWasDone)[] = [];
    if (interviewQuery.data) {
      interviewQuery.data.forEach((interview) => {
        interviewInList.push(interview);

        // if the interview is passed, we add another object to show that the interview has been done.
        // it's different from the interview object, which is used to show that the interview has been scheduled.
        if (interview.getInterviewEndDate() < moment()) {
          interviewInList.push({
            interview: interview,
            created_at: interview.getInterviewEndDate(),
          } as InterviewWasDone);
        }
      });
    }

    return application
      ? ([] as ApplicationHistory[])
          .concat(
            ensureArray(exportHistories),
            ensureArray(application.statuses),
            ensureArray(application.comments),
            ensureArray(messagingHistoryReminderList),
            ensureArray(interviewInList),
            ensureArray(callHistoriesQuery.data),
            ensureArray(applicationDocumentLogsList),
            ensureArray(applicationSharesQuery.data),
            ensureArray(recruiterReminderList),
          )
          .sort(
            // sorts the histories by date DESC
            (a: ApplicationHistory, b: ApplicationHistory) =>
              moment(a.created_at).isAfter(moment(b.created_at)) ? -1 : 1,
          )
      : [];
  }, [
    interviewQuery.data,
    application,
    exportHistories,
    messagingHistoryReminderList,
    callHistoriesQuery.data,
    applicationDocumentLogsList,
    applicationSharesQuery.data,
    recruiterReminderList,
  ]);

  function isInterview(object: ApplicationHistory): object is Interview {
    return "time_start" in object;
  }
  function isInterviewWasDone(object: ApplicationHistory): object is InterviewWasDone {
    return "interview" in object;
  }
  function isComment(object: ApplicationHistory): object is Comment {
    return "content" in object;
  }
  function isRecruiterReminder(object: ApplicationHistory): object is RecruiterReminder {
    return "comment" in object && "date" in object && "time" in object;
  }
  function isStatus(object: ApplicationHistory): object is ApplicationStatus {
    return "label" in object;
  }
  function isApplicationExportHistory(object: ApplicationHistory): object is ApplicationExportHistory {
    return "status" in object;
  }
  function isMessagingHistoryReminder(object: ApplicationHistory): object is MessagingHistoryReminder {
    return "pipelineId" in object;
  }
  function isCallHistory(object: ApplicationHistory): object is CallHistory {
    return "status" in object && "caller_id" in object;
  }
  function isDocumentsLogs(object: ApplicationHistory): object is DocumentsLogs {
    return "log_type" in object && "document_type" in object;
  }
  function isApplicationShare(object: ApplicationHistory): object is ApplicationShare {
    return "recipients" in object;
  }

  return (
    <Box id="application_history" height="100%" display="flex" flexDirection="column">
      <Box paddingX="3.5rem" paddingY="1rem">
        <Typography variant="bodyCopyStrong" color={TEXT_COLOR.mainInfo}>
          {t("comments")}
        </Typography>
      </Box>

      <Box
        bgcolor={(theme) => theme.palette.color.BASE[100]}
        padding="1rem 3.5rem 0 3.5rem"
        overflow="auto"
        flexGrow={1}
      >
        <AddCommentTextArea application={application} />
        <Box
          sx={{ marginTop: "1rem" }}
          role="list"
          aria-label={t("comment-list:aria_comments_and_status")}
          height="100%"
        >
          <Timeline
            sx={(theme) => ({
              [`& .${timelineItemClasses.root}:before`]: {
                flex: 0,
                padding: 0,
              },
              "& .MuiTimelineDot-root": {
                margin: 0,
                boxShadow: "none",
                color: theme.palette.grey[600],
              },
              "& .MuiTimelineConnector-root": {
                color: theme.palette.grey[600],
                margin: "0.5rem 0px",
              },
              "& .MuiTimelineContent-root": {
                padding: "0px 0px",
                margin: "1rem 1.5rem",
                position: "relative",
                top: "-1.5rem",
              },
              padding: "0px",
              marginBottom: "0",
            })}
          >
            {applicationHistoriesList.map((item, index) => {
              if (isInterview(item)) {
                return <InterviewDetail key={index} interview={item} role="listitem" />;
              }
              if (isInterviewWasDone(item)) {
                return <InterviewDoneDetail key={index} interviewDone={item} role="listitem" />;
              }
              if (isCallHistory(item)) {
                return (
                  <CallHistoryDetail
                    key={index}
                    callHistory={item}
                    candidateId={application.candidate_id}
                    role="listitem"
                  />
                );
              }
              if (isComment(item)) {
                return <CommentDetail key={index} comment={item} role="listitem" />;
              }
              if (isRecruiterReminder(item)) {
                return <RecruiterReminderDetail key={index} reminder={item} role="listitem" />;
              }
              if (isStatus(item)) {
                return <StatusDetail key={index} application={application} status={item} role="listitem" />;
              }
              if (isMessagingHistoryReminder(item)) {
                return <MessagesHistoryDetail key={index} messageHistory={item} role="listitem" />;
              }
              if (isDocumentsLogs(item)) {
                return <DocumentLogsDetail key={index} documentsLogs={item} role="listitem" />;
              }
              if (isApplicationExportHistory(item)) {
                return <ExportHistoryDetail key={index} exportHistory={item} role="listitem" />;
              }
              if (isApplicationShare(item)) {
                return <ApplicationShareDetail key={index} applicationShare={item} role="listitem" />;
              }
              return <></>;
            })}

            <TimelineItem role="listitem">
              <TimelineSeparator>
                <TimelineDot
                  sx={(theme) => ({
                    width: "36px",
                    height: "36px",
                    backgroundColor: theme.palette.grey[300],
                  })}
                >
                  <PersonAddIcon
                    sx={{
                      width: "1.5rem",
                      height: "1.5rem",
                    }}
                  />
                </TimelineDot>
              </TimelineSeparator>
              <TimelineContent sx={{ position: "relative", top: "-18px !important" }}>
                <Box
                  sx={{
                    color: TEXT_COLOR.mainInfo,
                    fontWeight: "bold",
                    fontSize: "14px",
                  }}
                >
                  {t("created_at")}
                </Box>
                <CommentListSubInfo created_at={application.created_at} />
              </TimelineContent>
            </TimelineItem>
          </Timeline>
        </Box>
      </Box>
    </Box>
  );
};
