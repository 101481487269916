import Image from "next/image";

import { FC } from "react";

import { useTranslation } from "next-i18next";

import { Box, Typography } from "@mui/material";

import { Modal } from "@work4Labs/design-system";

import { loadTranslations } from "@lib";

type Props = {
  open: boolean;
  setOpen: (open: boolean) => void;
};

export const NewCampaignModal: FC<Props> = ({ open, setOpen }) => {
  const { t } = useTranslation(["campaign-list"]);
  loadTranslations("campaign-list");

  return (
    <Modal
      isOpen={open}
      onClose={() => setOpen(false)}
      aria-labelledby="modal-new-campaign"
      aria-describedby="modal-new-campaign-description"
    >
      <Box className="content" sx={{ maxWidth: "620px" }}>
        <Box display="flex" justifyContent="center" paddingBottom="2rem">
          <Image alt="" width="192" height="141" src="/assets/images/campaign-creation-modal-illustration.svg" />
        </Box>
        <Box sx={(theme) => ({ color: theme.palette.primary[900] })}>
          <Typography id="modal-new-campaign-title" variant="h6" component="h2" fontWeight="bold" marginBottom="0.5rem">
            {t("creation_modal.title")}
          </Typography>
          <Typography id="modal-new-campaign-description">{t("creation_modal.content")}</Typography>
          <br />
          <Typography id="modal-new-campaign-helper" fontWeight="bold">
            {t("creation_modal.helper")}
          </Typography>
        </Box>
      </Box>
    </Modal>
  );
};
