import { useRouter } from "next/router";

import { useTranslation } from "next-i18next";

import { Box, Button, Typography } from "@mui/material";

import { loadTranslations } from "@lib";
import { Job } from "@typings";

interface Props {
  job: Job;
}

export const MyJobsCard = ({ job }: Props) => {
  const { t } = useTranslation(["my-jobs"]);
  loadTranslations("my-jobs");

  const router = useRouter();

  return (
    <Box
      display="flex"
      flexDirection="row"
      gap={(theme) => theme.spacings[16]}
      alignItems="center"
      justifyContent="space-between"
      sx={(theme) => ({
        border: `1px solid ${theme.palette.color.pastelPurple}`,
        borderRadius: theme.radius[2],
        padding: theme.spacings[16],
      })}
    >
      <Box display="flex" flexDirection="column">
        <Typography variant="bodyStrong">{job.title}</Typography>
        <Typography variant="body">{job.city}</Typography>
      </Box>
      <Box>
        <Button variant="contained" color="deepPurple" onClick={() => router.push(`/jobs/${job.id}/applications`)}>
          {t("view_applications")}
        </Button>
      </Box>
    </Box>
  );
};
