import { ILabelValueItem } from "@typings";

export enum Locale {
  // in keycloak the local is en (without -US) for now, because the support is weird.
  // we might want to change it later.
  EN = "en",
  FR = "fr",
}

export const LOCALE_OPTIONS: ILabelValueItem<string, Locale>[] = [
  {
    label: `common:locale.${Locale.FR}`,
    value: Locale.FR,
  },
  {
    label: `common:locale.${Locale.EN}`,
    value: Locale.EN,
  },
];
