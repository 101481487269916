import Link from "next/link";

import { FC, ReactElement, useCallback, useContext, useEffect, useState } from "react";

import { useSession } from "next-auth/react";
import { Trans, useTranslation } from "next-i18next";

import { Link as MuiLink, Tooltip, TooltipProps } from "@mui/material";

import { loadTranslations } from "@lib";
import { TelnyxRTCContext, useNotification } from "@telnyx/react-client";
import { Call as ICall } from "@telnyx/webrtc/lib/src/Modules/Verto/webrtc/Call";
import { ORGANIZATIONS_FEATURES } from "@typings";
import { Logger } from "@utils";

import { useCurrentUserOrganization, useOrganizationFeatures, usePhoneNumberVerificationStatus } from "@hooks/queries";

interface Props {
  applicationId: string;
  applicantName: string;
  applicantPhone: string;
  render: ({ onClick, disabled }: { onClick: (e: any) => void; disabled: boolean }) => ReactElement;
  tooltipPlacement?: TooltipProps["placement"];
}

export const ClickToCallAction: FC<Props> = ({
  applicationId,
  applicantName,
  applicantPhone,
  render,
  tooltipPlacement = "top",
}) => {
  const { t } = useTranslation(["click-to-call"]);
  loadTranslations("click-to-call");

  const { data: session } = useSession();

  const currentOrganizationQuery = useCurrentUserOrganization(session?.user?.id);

  const { hasFeature } = useOrganizationFeatures(currentOrganizationQuery.data?.group_id ?? "", {
    enabled: currentOrganizationQuery.data?.group_id !== undefined,
  });

  const client = useContext(TelnyxRTCContext);
  const notification = useNotification();

  const [hasMicPermission, setHasMicPermission] = useState(false);

  const phoneNumberVerificationStatus = usePhoneNumberVerificationStatus(session?.user.phone || "");

  const doCall = useCallback(() => {
    try {
      client?.newCall({
        destinationNumber: applicantPhone.replaceAll(" ", ""),
        callerName: (session?.user.given_name || "") + " " + (session?.user.family_name || ""),
        callerNumber: session?.user.phone.replaceAll(" ", ""),
        audio: true,
        video: false,
        customHeaders: [
          {
            name: "X-Destionation-Name",
            value: applicantName,
          },
          {
            name: "X-Application-External-ID",
            value: applicationId,
          },
          {
            name: "X-Caller-ID",
            value: session?.user.id || "",
          },
        ],
      });
    } catch (e) {
      Logger.error(e);
    }
  }, [
    applicantName,
    applicantPhone,
    applicationId,
    client,
    session?.user.family_name,
    session?.user.given_name,
    session?.user.id,
    session?.user.phone,
  ]);

  useEffect(() => {
    async function getMicPermission() {
      if (navigator?.permissions != null) {
        return await navigator.permissions.query({ name: "microphone" as PermissionName });
      }
      return undefined;
    }

    // check if the user has mic permission
    // the button will be disabled if the user DENIED the mic permission
    // otherwise the browser will ask for the permission
    getMicPermission().then((micPermission) => {
      setHasMicPermission(micPermission?.state !== "denied");
    });
  }, []);

  const call: ICall | undefined = notification?.call;
  const callState = call?.state;

  return (
    <>
      {hasFeature(ORGANIZATIONS_FEATURES.CLICK_TO_CALL) && (
        <Tooltip
          title={
            <Trans
              t={t}
              i18nKey={
                !phoneNumberVerificationStatus.verified
                  ? "click-to-call:unverified_number"
                  : !hasMicPermission
                    ? "mic_permission"
                    : ""
              }
              components={[
                <MuiLink
                  key="my-profile-link"
                  href="/personal-settings"
                  component={Link}
                  sx={{ color: "white !important", textDecoration: "underline" }}
                />,
              ]}
            />
          }
          disableHoverListener={
            phoneNumberVerificationStatus.isLoading || (phoneNumberVerificationStatus.verified && hasMicPermission)
          }
          placement={tooltipPlacement}
          componentsProps={{
            tooltip: {
              sx: (theme) => ({
                color: "white",
                bgcolor: theme.palette.background.darker,
                textAlign: "center",
                fontSize: "0.875rem",
              }),
            },
          }}
          arrow
        >
          {/* the span is needed because the tooltip dosen't work on disabled elements */}
          <span>
            {render({
              onClick: (e) => {
                e.preventDefault();
                e.stopPropagation();
                doCall();
              },
              disabled:
                (call && callState !== "destroy" && callState !== "hangup") ||
                session?.user.phone === undefined ||
                phoneNumberVerificationStatus.isLoading ||
                phoneNumberVerificationStatus.error != null ||
                !phoneNumberVerificationStatus.verified ||
                !hasMicPermission,
            })}
          </span>
        </Tooltip>
      )}
    </>
  );
};
