export const MRT_Localization_EN = {
  actions: "Actions",
  and: "and",
  cancel: "Cancel",
  changeFilterMode: "Change filter mode",
  changeSearchMode: "Change search mode",
  clearFilter: "Clear filter",
  clearSearch: "Clear search",
  clearSort: "Clear sort",
  clickToCopy: "Click to copy",
  columnActions: "Column Actions",
  copiedToClipboard: "Copied to clipboard",
  dropToGroupBy: "Drop to group by {column}",
  edit: "Edit",
  expand: "Expand",
  expandAll: "Expand all",
  filterArrIncludes: "Includes",
  filterArrIncludesAll: "Includes all",
  filterArrIncludesSome: "Includes",
  filterBetween: "Between",
  filterBetweenInclusive: "Between Inclusive",
  filterByColumn: "Filter by {column}",
  filterContains: "Contains",
  filterEmpty: "Empty",
  filterEndsWith: "Ends With",
  filterEquals: "Equals",
  filterEqualsString: "Equals",
  filterFuzzy: "Fuzzy",
  filterGreaterThan: "Greater Than",
  filterGreaterThanOrEqualTo: "Greater Than Or Equal To",
  filterInNumberRange: "Between",
  filterIncludesString: "Contains",
  filterIncludesStringSensitive: "Contains",
  filterLessThan: "Less Than",
  filterLessThanOrEqualTo: "Less Than Or Equal To",
  filterMode: "Filter Mode: {filterType}",
  filterNotEmpty: "Not Empty",
  filterNotEquals: "Not Equals",
  filterStartsWith: "Starts With",
  filterWeakEquals: "Equals",
  filteringByColumn: "Filtering by {column} - {filterType} {filterValue}",
  goToFirstPage: "Go to first page",
  goToLastPage: "Go to last page",
  goToNextPage: "Go to next page",
  goToPreviousPage: "Go to previous page",
  grab: "Grab",
  groupByColumn: "Group by {column}",
  groupedBy: "Grouped by ",
  hideAll: "Hide all",
  hideColumn: "Hide {column} column",
  max: "Max",
  min: "Min",
  move: "Move",
  noRecordsToDisplay: "No records to display",
  noResultsFound: "No results found",
  of: "of",
  or: "or",
  pinToLeft: "Pin to left",
  pinToRight: "Pin to right",
  resetColumnSize: "Reset column size",
  resetOrder: "Reset order",
  rowActions: "Row Actions",
  rowNumber: "#",
  rowNumbers: "Row Numbers",
  rowsPerPage: "Rows per page",
  save: "Save",
  search: "Search",
  selectedCountOfRowCountRowsSelected: "{selectedCount} of {rowCount} row(s) selected",
  select: "Select",
  showAll: "Show all",
  showAllColumns: "Show all columns",
  showHideColumns: "Show/Hide columns",
  showHideFilters: "Show/Hide filters",
  showHideSearch: "Show/Hide search",
  sortByColumnAsc: "Sort by {column} ascending",
  sortByColumnDesc: "Sort by {column} descending",
  sortedByColumnAsc: "Sorted by {column} ascending",
  sortedByColumnDesc: "Sorted by {column} descending",
  thenBy: ", then by ",
  toggleDensity: "Toggle density",
  toggleFullScreen: "Toggle full screen",
  toggleSelectAll: "Toggle select all",
  toggleSelectRow: "Toggle select row",
  toggleVisibility: "Toggle visibility",
  ungroupByColumn: "Ungroup by {column}",
  unpin: "Unpin",
  unpinAll: "Unpin all",
  unsorted: "Unsorted",
};
