import { FC, useMemo } from "react";

import { MRT_Row } from "material-react-table";
import { useTranslation } from "next-i18next";

import { Chip, Stack, Typography } from "@mui/material";

import { APPLICATION_STATUSES } from "@constants";
import { loadTranslations } from "@lib";

import { useApplicationsCountPerJob } from "@hooks/queries";

import { ListJobItemWithStats } from "../types";

export const ApplicationsCell: FC<{ row: MRT_Row<ListJobItemWithStats> }> = ({ row }) => {
  const { t } = useTranslation(["jobs-list"]);
  loadTranslations("jobs-list");

  const applicationsCountQuery = useApplicationsCountPerJob(row.original.id);
  const newApplicationsCount = useMemo(
    () => applicationsCountQuery.data?.reports.find((report) => report.label === APPLICATION_STATUSES.NEW)?.value ?? 0,
    [applicationsCountQuery.data],
  );

  return (
    <Stack
      flexDirection="row"
      flexWrap="nowrap"
      alignItems="center"
      justifyContent="space-between"
      gap={(theme) => theme.spacings[8]}
    >
      <Typography fontSize="0.8rem" fontWeight={700} lineHeight="150%" color={(theme) => theme.palette.color.BASE[600]}>
        {applicationsCountQuery.data?.applications_count ?? 0}
      </Typography>
      {newApplicationsCount ? (
        <Chip
          label={t("columns.applications.new", { count: newApplicationsCount })}
          sx={(theme) => ({
            borderRadius: "16px",
            backgroundColor: theme.palette.color.acidGreen,
            color: theme.palette.color.BASE[600],
            fontWeight: 700,
            fontSize: "0.8rem",
            lineHeight: "150%",
          })}
        />
      ) : null}
    </Stack>
  );
};
