import { FC, ReactNode } from "react";

import { useTranslation } from "next-i18next";
import { Controller, useFormContext } from "react-hook-form";

import { FormControl, FormHelperText, InputLabel, InputProps, OutlinedInput } from "@mui/material";

import { loadTranslations } from "@lib";
import { NumberFormatter } from "@utils";

type Props = Omit<InputProps, "type"> & {
  name: string;
  label?: ReactNode;
  helperText?: ReactNode;
};

export const FormNumericInput: FC<Props> = ({ name, label, helperText, defaultValue, ...rest }) => {
  const {
    control,
    formState: { errors },
  } = useFormContext();

  const { t, i18n } = useTranslation("common");
  loadTranslations("common");

  const errorMessage = errors[`${name}`]?.message;
  const isError = !!errorMessage;

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      render={({ field }) => (
        <FormControl fullWidth={rest.fullWidth} error={isError}>
          <InputLabel shrink error={isError} htmlFor={name} sx={{ display: "list-item" }}>
            {label || " "}
            {label && !rest.required && (
              <FormHelperText sx={{ display: "inline-block", marginLeft: "5px" }}>{`(${t(
                "optional",
              )})`}</FormHelperText>
            )}
            {helperText && <FormHelperText>{helperText}</FormHelperText>}
          </InputLabel>

          <OutlinedInput
            id={name}
            error={isError}
            {...rest}
            {...field}
            onChange={(e) => {
              if (e.target.value.length > 0 && !/^\d+$/.test(e.target.value[e.target.value.length - 1])) {
                e.target.value = String(field.value);
              } else {
                e.target.value = e.target.value.replace(/[\s,.]/g, "");
              }

              field.onChange(e);
              rest.onChange?.(e);
            }}
            value={NumberFormatter(field.value as string, i18n.language)}
          />

          {isError && <FormHelperText error={isError}>{errorMessage as string}</FormHelperText>}
        </FormControl>
      )}
    />
  );
};
