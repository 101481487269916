import { FC } from "react";

import { TFunction, Trans, useTranslation } from "next-i18next";

import { CheckOutlined as CheckIcon, ExitToAppOutlined as ExportIcon } from "@mui/icons-material";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineItem from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import { Box } from "@mui/material";

import { TEXT_COLOR } from "@constants";
import { loadTranslations } from "@lib";
import { ApplicationExportHistory, AriaProps, ExportHistoryStatus } from "@typings";

import { CommentListSubInfo } from "./style";

interface Props extends AriaProps {
  exportHistory: ApplicationExportHistory;
}

export const ExportHistoryDetail: FC<Props> = ({ exportHistory, role }) => {
  const { t } = useTranslation(["export-history-details"]);
  loadTranslations("export-history-details");

  return (
    <TimelineItem role={role}>
      <TimelineSeparator>
        {renderIcon(exportHistory)}
        <TimelineConnector />
      </TimelineSeparator>
      <TimelineContent sx={{ position: "relative", top: "-18px" }}>
        <Box
          sx={{
            color: TEXT_COLOR.mainInfo,
            fontSize: "14px",
          }}
        >
          {renderMessage(exportHistory, t)}
        </Box>
        <Box>
          <CommentListSubInfo created_at={exportHistory.created_at} />
        </Box>
      </TimelineContent>
    </TimelineItem>
  );
};

const renderIcon = (exportHistory: ApplicationExportHistory) => {
  if (exportHistory.status === ExportHistoryStatus.Started) {
    return (
      <ExportIcon
        sx={{
          width: "2rem",
          height: "2rem",
        }}
      />
    );
  } else if (exportHistory.status === ExportHistoryStatus.Done) {
    return (
      <CheckIcon
        sx={{
          width: "2rem",
          height: "2rem",
        }}
      />
    );
  }
  // we can't reach this for the moment because we're filtering the histories
  return "";
};

const renderMessage = (exportHistory: ApplicationExportHistory, t: TFunction) => {
  if (exportHistory.status === ExportHistoryStatus.Started) {
    return <Trans t={t} i18nKey="export_started" components={[<strong key={0} />]} />;
  } else if (exportHistory.status === ExportHistoryStatus.Done) {
    return <Trans t={t} i18nKey="export_successful" components={[<strong key={0} />]} />;
  }
  // we can't reach this for the moment because we're filtering the histories
  return <></>;
};
