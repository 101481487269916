import { FC, useMemo } from "react";

import { useApplicationsStore } from "stores";

import { Box, Typography } from "@mui/material";

import { StatusChipIcon } from "@components";
import { ApplicationListItem } from "@typings";

interface Props {
  application: ApplicationListItem;
}
export const ListItem: FC<Props> = ({ application }) => {
  const currentApplicationID = useApplicationsStore((state) => state.currentApplicationID);
  const setCurrentApplicationID = useApplicationsStore((state) => state.setCurrentApplicationID);

  const isActive = useMemo(() => application.id === currentApplicationID, [application.id, currentApplicationID]);

  return (
    <Box
      id={`application_list_item_${application.id}`}
      display="flex"
      flexDirection="column"
      padding="1rem"
      sx={(theme) => ({
        cursor: "pointer",
        borderRadius: theme.radius[2],
        border: `1px solid ${theme.palette.color.pastelPurple}`,
        backgroundColor: isActive ? theme.palette.color.deepPurple : "white",
        color: isActive ? "white" : theme.palette.color.BASE[800],
        "&:hover": { backgroundColor: theme.palette.color.deepPurple, color: "white" },
      })}
      onClick={() => setCurrentApplicationID(application.id)}
    >
      <Box display="flex" flexDirection="row" gap="0.5rem" alignItems="center" marginBottom="0.5rem">
        <StatusChipIcon status={application.status} />
        <Typography variant="tagsStrong">
          {application.candidate?.first_name} {application.candidate?.last_name}
        </Typography>
      </Box>
      <Typography variant="tags">{application.candidate?.phone}</Typography>
      <Typography variant="tags">{application.candidate?.email}</Typography>
      <br />
      <Typography variant="tags">{application.job?.title}</Typography>
      <Typography variant="tags">{application.job?.city}</Typography>
    </Box>
  );
};
