import { styled } from "@mui/material/styles";

export const Header = styled("div")`
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 8px 0px 8px 40px;
  gap: 508px;
  border-radius: 16px 0px 0px 0px;
`;

export const HeaderContent = styled("div")`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 8px 37px 16px 40px;
  gap: 111px;
  background: #ffffff;
  flex: none;
  order: 1;
  flex-grow: 0;
`;
