import { FC, ReactNode, forwardRef } from "react";

import { Controller, useFormContext } from "react-hook-form";

import { FormControlLabel, FormControlLabelProps, FormHelperText, Switch, SwitchProps, styled } from "@mui/material";

const ForwardableSwitch = forwardRef<HTMLButtonElement, SwitchProps>(function ForwardableSwitch(props, ref) {
  return <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} ref={ref} />;
});

// IOS style from mui docs https://mui.com/material-ui/react-switch/#customization
export const IOSSwitch = styled(ForwardableSwitch)(({ theme }) => ({
  width: 28,
  height: 16,
  padding: 0,
  marginRight: "1rem",
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 3,
    marginTop: 1.5,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(10px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor: theme.palette.primary[900],
        opacity: 1,
        border: 0,
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: theme.palette.primary[900],
      border: "6px solid #fff",
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      color: theme.palette.mode === "light" ? theme.palette.grey[600] : theme.palette.grey[100],
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 12,
    height: 12,
  },
  "& .MuiSwitch-track": {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === "light" ? "#E9E9EA" : "#39393D",
    opacity: 1,
    transition: theme.transitions.create(["background-color"], {
      duration: 500,
    }),
  },
}));

type Props = SwitchProps & {
  name: string;
  label: ReactNode;
  labelPlacement?: "end" | "start" | "top" | "bottom";
  formControlLabelProps?: Omit<FormControlLabelProps, "control" | "label" | "labelPlacement">;
};

export const FormSwitch: FC<Props> = ({ name, label, labelPlacement, formControlLabelProps, ...rest }) => {
  const {
    control,
    formState: { errors },
  } = useFormContext();

  const errorMessage = errors[`${name}`]?.message;
  const isError = !!errorMessage;

  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => (
        <>
          <FormControlLabel
            label={label}
            sx={(theme) => ({
              color: theme.palette.primary[900],
              fontSize: "14px",
              margin: 0,
            })}
            {...formControlLabelProps}
            labelPlacement={labelPlacement}
            control={
              <IOSSwitch
                {...rest}
                {...field}
                value={field.value}
                checked={!!field.value}
                onChange={(e) => {
                  field.onChange(e.target.checked);
                  rest.onChange?.(e, e.target.checked);
                }}
              />
            }
          />
          {isError && <FormHelperText>{errorMessage as string}</FormHelperText>}
        </>
      )}
    />
  );
};
