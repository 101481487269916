import { Dispatch, FC, SetStateAction, useCallback, useMemo } from "react";

import { AxiosError } from "axios";
import { Trans, useTranslation } from "next-i18next";
import { toast } from "react-toastify";

import { Grid, ThemeProvider, Typography, useTheme } from "@mui/material";

import { Modal } from "@work4Labs/design-system";

import { ApplicationApi } from "@api";
import { loadTranslations } from "@lib";
import { context } from "@opentelemetry/api";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { ApplicationListItem } from "@typings";
import { Logger } from "@utils";

import { updateApplicationsCache } from "@hooks/queries";

type DeleteApplicationUpdateModalProps = {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  applications: ApplicationListItem[];
  onSuccess: () => void;
};

export const DeleteApplicationUpdateModal: FC<DeleteApplicationUpdateModalProps> = ({
  open,
  setOpen,
  applications,
  onSuccess,
}) => {
  const { t } = useTranslation(["delete-application"]);
  loadTranslations("delete-application");

  const theme = useTheme();

  const { mutateAsync: deleteApplicationMutation } = useMutation<void, AxiosError, string>({
    mutationFn: (applicationID: string) => ApplicationApi.deleteApplication(context.active(), applicationID),
  });

  const queryClient = useQueryClient();
  const applicationIDs = useMemo(() => applications.map((application) => application.id), [applications]);

  const updateCache = useCallback(() => {
    updateApplicationsCache(queryClient, applicationIDs);
  }, [applicationIDs, queryClient]);

  // On form submit, we create a list of promises to update the statuses and create the comments.
  // We then run all queries in // and show a toast when we're done.
  const doOnSubmit = useCallback(async () => {
    const promises: Promise<void>[] = [];

    applications.forEach((application) => {
      promises.push(deleteApplicationMutation(application.id));
    });

    const results = await Promise.allSettled(promises);

    const errCount = results.filter((r) => r.status == "rejected").length;

    updateCache();

    if (errCount == 0) {
      toast.success(t("delete_application_done"));
      onSuccess();
    } else {
      toast.error(t("delete_application_error", { count: errCount }));
    }

    setOpen(false);
  }, [applications, deleteApplicationMutation, onSuccess, setOpen, t, updateCache]);

  const onSubmit = useCallback(() => {
    doOnSubmit().catch(Logger.error);
  }, [doOnSubmit]);

  const onCancel = useCallback(() => {
    setOpen(false);
  }, [setOpen]);

  return (
    <Modal
      isOpen={open}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      title={t("title")}
      confirmText={t("submit")}
      cancelText={t("cancel")}
      onConfirm={onSubmit}
      onClose={onCancel}
      options={{
        centerTitle: true,
        confirmProps: { variant: "danger" },
      }}
    >
      <ThemeProvider theme={theme}>
        <Grid item xs={12}>
          <Grid item xs={12} sx={{ padding: "24px 0px 24px 0px", display: "flex", justifyContent: "flex-end" }}>
            <Typography sx={{ textAlign: "center" }}>
              <Trans
                t={t}
                i18nKey="warning_delete_application"
                components={[<strong key={0} />]}
                values={{ count: applicationIDs.length }}
              />
            </Typography>
          </Grid>
        </Grid>
      </ThemeProvider>
    </Modal>
  );
};
