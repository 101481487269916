import { useCallback, useEffect, useMemo } from "react";

import { useSession } from "next-auth/react";
import { useTranslation } from "next-i18next";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import * as yup from "yup";

import { Typography } from "@mui/material";

import { ControlledTextField, Form, InfoBox } from "@work4Labs/design-system";

import { OrganizationRequestFormLinkAPI } from "@api";
import { SettingsPageForm, SettingsSaveButton } from "@components";
import { loadTranslations } from "@lib";
import { context } from "@opentelemetry/api";
import { useMutation } from "@tanstack/react-query";
import { MutateOrganizationRequestFormLinkParams, OrganizationRequestFormLink } from "@typings";
import { Logger } from "@utils";

import { yupResolver } from "@hookform/resolvers/yup";
import { useUserOrganization } from "@hooks";
import { useOrganizationRequestFormLink } from "@hooks/queries";

const updateRequestFormLinkRequest = async (
  params: MutateOrganizationRequestFormLinkParams,
): Promise<OrganizationRequestFormLink | void> => {
  // A link was already set.
  if (params.current_request_form_link) {
    // We delete this link.
    if (!params.new_request_form_link) {
      return await OrganizationRequestFormLinkAPI.destroy(context.active(), {
        organization_id: params.organization_id,
      });
    }

    // We update this link.
    return await OrganizationRequestFormLinkAPI.update(context.active(), {
      organization_id: params.organization_id,
      request_form_link: params.new_request_form_link,
    });
  }

  if (!params.new_request_form_link) return;

  // No link was set, we create a new one.
  return await OrganizationRequestFormLinkAPI.create(context.active(), {
    organization_id: params.organization_id,
    request_form_link: params.new_request_form_link,
  });
};

const useOrganizationValidationSchema = () => {
  const { t } = useTranslation(["organization-validation"]);
  loadTranslations("organization-validation");

  return useMemo(
    () =>
      yup.object().shape({
        request_url: yup.string().url(t("organization-validation:requestURL.invalid")),
      }),
    [t],
  );
};

interface OrganizationFormData {
  request_url: string;
  organization_name: string;
}

export const OrganizationTab = () => {
  const { t } = useTranslation(["organization-settings"]);
  loadTranslations("organization-settings");

  const { data: session } = useSession();

  const currentOrganization = useUserOrganization();
  const requestFormLink = useOrganizationRequestFormLink(currentOrganization?.data?.group_id);

  const validationSchema = useOrganizationValidationSchema();

  const organizationForm = useForm<OrganizationFormData>({
    resolver: yupResolver(validationSchema),
    shouldUnregister: false,
    mode: "onChange",
    defaultValues: {
      request_url: "",
      organization_name: "",
    },
  });

  const organizationName = useMemo(() => session?.user?.groups?.[0] ?? "", [session]);

  useEffect(() => {
    if (organizationName) {
      organizationForm.setValue("organization_name", organizationName);
    }
  }, [organizationForm, organizationName]);

  useEffect(() => {
    if (requestFormLink.data?.link) {
      organizationForm.setValue("request_url", requestFormLink.data?.link);
    }
  }, [organizationForm, requestFormLink.data?.link]);

  const updateOrganizationRequestFormLinkMutation = useMutation({
    mutationFn: updateRequestFormLinkRequest,
    onSuccess: () => {
      toast.success(t("organization-settings:update.success"));
      requestFormLink.refetch().catch(Logger.error);
    },
    onError: (err) => {
      Logger.error(err);
      toast.error(t("organization-settings:update.error"));
    },
  });

  const updateOrganization = useCallback(
    (formData: OrganizationFormData) => {
      // Happens if this method is called before data is loaded.
      if (currentOrganization.data == null) return;

      if (formData.request_url !== requestFormLink.data?.link) {
        updateOrganizationRequestFormLinkMutation.mutate({
          organization_id: currentOrganization.data.group_id,
          current_request_form_link: requestFormLink.data?.link,
          new_request_form_link: formData.request_url,
        });
      }
    },
    [currentOrganization.data, requestFormLink.data?.link, updateOrganizationRequestFormLinkMutation],
  );

  const watchRequestFormLink = organizationForm.watch("request_url");

  const canSave =
    session &&
    organizationForm.formState.isValid &&
    !updateOrganizationRequestFormLinkMutation.isPending &&
    !currentOrganization.isLoading &&
    !requestFormLink.isLoading &&
    watchRequestFormLink !== (requestFormLink.data?.link ?? "");

  return (
    <SettingsPageForm title={t("organization-settings:organization.title")}>
      <Form
        style={{ display: "flex", flexDirection: "column", gap: "var(--size-24)", alignItems: "stretch" }}
        submitHandler={updateOrganization}
        methods={organizationForm}
      >
        <ControlledTextField
          label={t("organization-settings:organization.organizationName")}
          name="organization_name"
          disabled
          fullWidth
        />
        <ControlledTextField
          error={organizationForm.formState.errors.request_url?.message}
          label={t("organization-settings:organization.requestURL.label")}
          labelHelper={<InfoBox level="info">{t("organization-settings:organization.requestURL.description")}</InfoBox>}
          name="request_url"
          disabled={session == null}
          fullWidth
        />
        {organizationForm.formState.errors.root ? (
          <Typography color="var(--color-palette-alert-red-400)">
            {organizationForm.formState.errors.root.message}
          </Typography>
        ) : null}
        <SettingsSaveButton disabled={!canSave}>{t("organization-settings:organization.save")}</SettingsSaveButton>
      </Form>
    </SettingsPageForm>
  );
};
