import { AxiosError } from "axios";

import { AuthProxyAPI } from "@api";
import { QUERY_KEYS } from "@constants";
import { context } from "@opentelemetry/api";
import { useQuery } from "@tanstack/react-query";
import { AuthProxyUsersList, AuthProxyUsersListItem } from "@typings";

export const useOrganizationUsers = (userGroup: string) => {
  return useQuery({
    queryKey: [QUERY_KEYS.USERS, userGroup],
    queryFn: () => AuthProxyAPI.listUsers(context.active(), userGroup),
  });
};

export const useOrganizationUsersObject = (userGroup: string) => {
  return useQuery<AuthProxyUsersList, AxiosError, Record<string, AuthProxyUsersListItem>>({
    queryKey: [QUERY_KEYS.USERS, userGroup, "object"],
    queryFn: () => AuthProxyAPI.listUsers(context.active(), userGroup),
    select: (users) => users.reduce((acc, user) => ({ ...acc, [user.id]: user }), {}),
  });
};
