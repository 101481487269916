export const FONT_SIZE = {
  75: "0.75rem",
  85: "0.875rem",
  100: "1rem",
  125: "1.25rem",
  200: "2rem",
  300: "3rem",
  400: "4rem",
};

export const FONT_WEIGHT = {
  300: 300,
  400: 400,
  500: 500,
  600: 600,
  700: 700,
  800: 800,
  900: 900,
};

export const SIZE = {
  0: "0rem",
  4: "0.25rem",
  8: "0.5rem",
  12: "0.75rem",
  14: "0.875rem",
  16: "1rem",
  24: "1.5rem",
  32: "2rem",
  40: "2.5rem",
  48: "3rem",
  64: "4rem",
  72: "4.5rem",
  128: "8rem",
};

export const SPACING = {
  0: SIZE[0],
  4: SIZE[4],
  8: SIZE[8],
  12: SIZE[12],
  14: SIZE[14],
  16: SIZE[16],
  24: SIZE[24],
  32: SIZE[32],
  48: SIZE[48],
  64: SIZE[64],
  72: SIZE[72],
  128: SIZE[128],
};

export const BORDER_RADIUS = {
  1: SIZE[4],
  2: SIZE[8],
  3: SIZE[16],
  4: SIZE[128],
};

export const COLOR_PALETTE = {
  BASE: {
    800: "#020626",
    700: "#30335a",
    600: "#494c72",
    500: "#616489",
    400: "#b6b9db",
    300: "#d4d8f8",
    200: "#eaecff",
    100: "#f8f8fb",
    0: "#ffffff",
  },
  TOP_GREEN: {
    400: "#017b2b",
    300: "#419c60",
    200: "#80bd95",
    100: "#bfdeca",
  },
  HIGH_GREEN: {
    400: "#4fae4f",
    300: "#7bc27b",
    200: "#a7d7a7",
    100: "#d3ebd3",
  },
  MEDIUM_YELLOW: {
    400: "#f7d147",
    300: "#f9dd75",
    200: "#fbe8a3",
    100: "#fdf3d1",
  },
  LOW_ORANGE: {
    400: "#fa6e32",
    300: "#fb9265",
    200: "#fcb698",
    100: "#fedbcc",
  },
  ALERT_RED: {
    400: "#d12d4a",
    300: "#dd6277",
    200: "#e896a4",
    100: "#f3cad2",
  },
  DARK_RED: {
    400: "#a6243b",
    300: "#bc5b6c",
    200: "#d2919d",
    100: "#e9c8ce",
  },
  grandBlue: "#020626",
  electricBlue: "#1a5cdd",
  tangerineOrange: "#fe8d5b",
  pollenYellow: "#fbee78",
  lilac: "#ced2ff",
  pastelLilac: "#ebedff",
  seagreen: "#bbebe0",
  pastelSeagreen: "#ebfffb",
  smoothOrange: "#ffd7c6",
  pastelOrange: "#fdf3eb",
  pastelYellow: "#fffded",
  pastelRed: "#faeaed",
  darkBlue: "#081058",
  deepPurple: "#7380F2",
  acidGreen: "#CBF63C",
  orange: "#FE783E",
  lightOrange: "#FFEDE6",
  peach: "#FEB89A",
  beige: "#FCFAEE",
  pastelPurple: "#E8EAFD",
};

export const BORDER_COLOR = {
  inputBase: COLOR_PALETTE.BASE[400],
  inputFocus: COLOR_PALETTE.electricBlue,
  inputError: COLOR_PALETTE.ALERT_RED[400],
};

export const BACKGROUND_COLOR = {
  default: COLOR_PALETTE.BASE[800],
  paper: COLOR_PALETTE.BASE[0],
  alertError: COLOR_PALETTE.pastelRed,
  error: COLOR_PALETTE.ALERT_RED[400],
  success: COLOR_PALETTE.TOP_GREEN[400],
  alertSuccess: COLOR_PALETTE.pastelSeagreen,
  info: COLOR_PALETTE.electricBlue,
  alertInfo: COLOR_PALETTE.pastelLilac,
  buttonBase: COLOR_PALETTE.BASE[700],
  alertWarning: COLOR_PALETTE.pastelOrange,
  inputDefault: COLOR_PALETTE.BASE[100],
  inputFocus: COLOR_PALETTE.BASE[0],
};

export const TEXT_COLOR = {
  mainTitle: COLOR_PALETTE.grandBlue,
  mainInfo: COLOR_PALETTE.BASE[800],
  mediumInfo: COLOR_PALETTE.BASE[600],
  lowInfo: COLOR_PALETTE.BASE[500],
  lowestInfo: COLOR_PALETTE.BASE[400],
  lightOnDark: COLOR_PALETTE.BASE[0],
};
