import { ApplicationStatusReasonSource, ApplicationStatusReasons } from "@typings";

export enum APPLICATION_STATUSES {
  NEW = "new",
  DID_NOT_ANSWER = "did not answer",
  VALIDATION_PENDING = "validation pending",
  INTERVIEW = "interview",
  OFFER = "offer",
  ACCEPTED = "accepted",
  REFUSED = "refused",
  ADDED_TO_TALENT_POOL = "added to talent pool",
}

export const STATUSES_WITH_AUTOMATION: string[] = [
  APPLICATION_STATUSES.NEW,
  APPLICATION_STATUSES.DID_NOT_ANSWER,
  APPLICATION_STATUSES.REFUSED,
];

export const STATUSES_WITH_OPTIONAL_SMS: string[] = [APPLICATION_STATUSES.REFUSED];

export const STATUSES_WITH_REASON: string[] = [APPLICATION_STATUSES.REFUSED, APPLICATION_STATUSES.VALIDATION_PENDING];

export const STATUSES_REASONS_WITH_MANDATORY_COMMENT = ["other", "candidate_answer"];

export const STATUSES_REASONS_WITH_SOURCE: string[] = [APPLICATION_STATUSES.REFUSED];

export const STATUS_REASON_SOURCES: ApplicationStatusReasonSource[] = ["recruiter", "candidate"];

export const STATUS_REFUSAL_REASONS: ApplicationStatusReasons["refused"][] = [
  "salary",
  "schedule",
  "unavailable",
  "skills",
  "behavior",
  "location",
  "other",
];

export const STATUS_VALIDATION_PENDING_REASONS: ApplicationStatusReasons["validationPending"][] = [
  "documents",
  "interview_slot",
  "manager_feedback",
  "candidate_answer",
  "other",
];

export const STATUS_VALIDATION_REASONS_PER_LABEL: Record<string, string[]> = {
  refused: STATUS_REFUSAL_REASONS,
  [APPLICATION_STATUSES.VALIDATION_PENDING]: STATUS_VALIDATION_PENDING_REASONS,
};
